/*
 * @Author: pengyu
 * @Date: 2020-07-13 09:04:43
 * @LastEditors: pengyu
 * @LastEditTime: 2020-08-02 09:38:07
 * @Description: 进度控件
 */

import BaseColumn from "../base/BaseColumn";
import { AssignmentOptions } from "../../BusinessCommonHeader.js";

export default class ProgressColumn extends BaseColumn {
  constructor(column) {
    super(column);
    this.showValue = 0;
    this.columnValue = { value: 0, time: -1 };
  }

  /**
   * @description: 赋值
   * @param {type}
   * @return:
   */
  updateValueWithOptions(value, opts) {
    const { mdData } = this.rootNode.originData;
    const { field } = this;
    // eslint-disable-next-line no-bitwise
    if (opts & AssignmentOptions.server) { // 服务器渲染
      // 服务器渲染数据
      this.columnValue = {
        value: value || 0,
        time: mdData[`${field}_operationTime`],
        state: mdData[`${field}_progressStatus`],
        plan: mdData[`${field}_planProgress`]
      };
    }
    this.showValue = value;

    super.updateValueWithOptions(value, opts);
  }

  // 是否能提交数据,校验
  get checkCouldSubmit() {
    // 没有填写不校验其它条件,但是要校验必填
    if (this.required && this.showValue === undefined) {
      return new Error(`${this.name}不能为空`);
    }
    return null;
  }

  // 提交参数
  get paramsForSubmit() {
    if (this.setData && this.setData.value) {
      return {
        [this.field]: this.setData.value,
        [`${this.field}_operationTime`]: this.setData.time,
        [`${this.field}_progressStatus`]: this.setData.state,
        [`${this.field}_planProgress`]: this.setData.plan
      };
    }
    return {
      [this.field]: 0
    };
  }
}
