/*
 * @Description: 区域控件
 * @Author: 梁平贤
 * @LastEditors: Please set LastEditors
 * @Date: 2021-01-20 18:18:50
 * @LastEditTime: 2024-02-19 18:28:34
 */
import BaseColumn from "../base/BaseColumn";
import { BusinessFieldType } from "../../BusinessCommonHeader";

export default class AreaColumn extends BaseColumn {
  static creatAreaColumnWithConfig(fieldConfig, section) {
    const area = new AreaColumn({ fieldType: BusinessFieldType.area });
    area.parentNode = section;
    area.name = fieldConfig.name;
    area.uuid = fieldConfig.uuid;
    area.align = fieldConfig.align;
    area.areaNumber = fieldConfig.areaNumber;
    area.areas = fieldConfig.areas; //
    area.fieldType = BusinessFieldType.area;
    area.extAttr = fieldConfig.extAttr;
    area.areas.forEach((col) => {
      col.fields = col.sub.map((column) => {
       const areaColumn = area.findMainAreaField(column.field);
       areaColumn.isBelongToAreaColumn = true;
       return areaColumn;
      }) || [];
    });
    return area;
  }
}
