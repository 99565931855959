var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.rightAdditionButtons.length > 0,
          expression: "rightAdditionButtons.length>0",
        },
      ],
      staticClass: "right-addition",
    },
    [
      _vm.rightAdditionButtons.length > 1
        ? _c(
            "el-dropdown",
            [
              _c(
                "span",
                { staticClass: "el-dropdown-link" },
                [
                  _c("en-icon", {
                    staticClass: "icon-placeholder icon-more",
                    attrs: {
                      name: "icongengduo-guanlian",
                      color: "#4895DF",
                      size: "18px",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                _vm._l(_vm.rightAdditionButtons, function (data, index) {
                  return _c(
                    "el-dropdown-item",
                    {
                      key: index,
                      nativeOn: {
                        click: function ($event) {
                          return _vm.handleClick(data)
                        },
                      },
                    },
                    [
                      _vm.isOcrType(data)
                        ? _c("input", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: false,
                                expression: "false",
                              },
                            ],
                            ref: "fileInput",
                            refInFor: true,
                            attrs: {
                              type: "file",
                              name: "avatar",
                              accept: "image/jpeg,image/jpg,image/png",
                            },
                            on: { change: _vm.handleOcrClick },
                          })
                        : _vm._e(),
                      _c("en-icon", {
                        staticClass: "drop-icon",
                        attrs: {
                          name: _vm.buttonData[data].icon,
                          title: _vm.buttonData[data].name,
                          color: _vm.buttonData[data].color,
                          size: "18px",
                        },
                      }),
                      _c("span", { staticClass: "drop-name" }, [
                        _vm._v(_vm._s(_vm.buttonData[data].name)),
                      ]),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          )
        : _vm.rightAdditionButtons.length === 1
        ? _c("en-icon", {
            staticClass: "icon-placeholder",
            attrs: {
              name: _vm.buttonData[_vm.rightAdditionButtons[0]].icon,
              title: _vm.buttonData[_vm.rightAdditionButtons[0]].name,
              color: _vm.buttonData[_vm.rightAdditionButtons[0]].color,
              size: "18px",
            },
            nativeOn: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleClick(_vm.rightAdditionButtons[0])
              },
            },
          })
        : _vm._e(),
      _vm.rightAdditionButtons.length === 1 &&
      _vm.isOcrType(_vm.rightAdditionButtons[0])
        ? _c("input", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: false,
                expression: "false",
              },
            ],
            ref: "fileInput",
            attrs: {
              type: "file",
              name: "avatar",
              accept: "image/jpeg,image/jpg,image/png",
            },
            on: { change: _vm.handleOcrClick },
          })
        : _vm._e(),
      _c("AssociateShowDialog", {
        attrs: {
          "dialog-visible": _vm.associateDialog,
          column: _vm.column,
          "row-index": _vm.rowIndex,
          "business-data": _vm.businessData,
        },
        on: {
          "update:dialogVisible": function ($event) {
            _vm.associateDialog = $event
          },
          "update:dialog-visible": function ($event) {
            _vm.associateDialog = $event
          },
        },
      }),
      _vm.summaryVisible
        ? _c("summaryResolveDetail", {
            attrs: {
              column: _vm.column,
              "business-data": _vm.businessData,
              visible: _vm.summaryVisible,
            },
            on: {
              close: function ($event) {
                _vm.summaryVisible = false
              },
            },
          })
        : _vm._e(),
      _vm.linkColumn.editable && _vm.column.originRelatedField
        ? _c("RelevancyAdd", {
            ref: "linkDialog",
            attrs: {
              title: "选择数据",
              detailType: true,
              column: _vm.linkColumn,
              "business-data": _vm.businessData,
            },
          })
        : _vm._e(),
      _vm.isManualAddRelation
        ? _c("RelevancyAdd", {
            ref: "linkDialog",
            attrs: {
              "is-manual-add-relation": true,
              "row-index": _vm.rowIndex,
              title: "选择数据",
              column: _vm.column,
              "business-data": _vm.businessData,
            },
          })
        : _vm._e(),
      _c("assist-select", {
        ref: "assistDialog",
        attrs: { column: _vm.column, "business-data": _vm.businessData },
      }),
      _c("geo-map", {
        attrs: { visible: _vm.addressGeo },
        on: {
          "update:visible": function ($event) {
            _vm.addressGeo = $event
          },
          success: _vm.geoSuccess,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }