/*
 * @Author: 梁平贤
 * @Date: 2020-12-25 18:38:07
 * @LastEditors: 梁平贤
 * @LastEditTime: 2020-12-25 18:38:07
 * @Description: 页签控件
 */

import BaseColumn from "../base/BaseColumn";

export default class MultiTabColumn extends BaseColumn {
  constructor(field) {
    super(field);
    // 当前选中的tab id
    this.currentSelectTabId = this.showContent[0].id;
  }
}
