/*
 * @Description: 标记完成控件实体
 * @Author: 梁平贤
 * @LastEditors: 梁平贤
 * @Date: 2019-09-11 16:23:21
 * @LastEditTime: 2019-09-11 16:27:40
 */
import BaseColumn from "../base/BaseColumn";

export default class CheckBoxColumn extends BaseColumn {
}
