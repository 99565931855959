/*
 * @Author: Zhouql
 * @Date: 2020-06-22 14:59:49
 * @LastEditTime: 2021-05-26 11:57:26
 * @LastEditors: wuqi
 * @Description: In User Settings Edit
 * @FilePath: \user\src\api\folders\FoldersService.js
 */
import BaseService from "../BaseService";

/**
 * login相关
 */
class FoldersService extends BaseService {
  pk = ""

  constructor(http) {
    super("", http);
  }

  /**
   * @description  查询我的文件夹
   * @param params 登录参数
   * @returns {*}
   */
  queryMyFolderFiles(params) {
    const { http } = this;
    return http.post("fiQueryMyFolderFiles", params);
  }

  /**
   * @description  查询我的文件夹树
   * @param params 登录参数
   * @returns {*}
   */
  queryMyFolderFilesTree(params) {
    const { http } = this;
    return http.post("queryFolderList", params);
  }

  /**
   * @description: 新增文件夹
   * @param {*} params
   * @return {*}
   */
  myFolderAddFolder(params) {
    const { http } = this;
    return http.post("myfolderAddFolder", params);
  }

  /**
   * @description: 更新文件夹信息
   * @param {*} params
   * @return {*}
   */
  myFolderUpdateFolder(params) {
    const { http } = this;
    return http.post("myfolderUpdateFolder", params);
  }

  /**
   * @description: 删除文件夹信息
   * @param {*} params
   * @return {*}
   */
  myFolderDeleteFolder(params) {
    const { http } = this;
    return http.post("myfolderDeleteFolder", params);
  }

  /**
   * @description: 创建文件
   * @param {*} params
   * @return {*}
   */
  myFolderCreateFile(params) {
    const { http } = this;
    return http.post("myfolderCreateFile", params);
  }

  /**
    * @description  查询文件存储空间
    * @param params 登录参数
    * @returns {*}
    */
  queryMyStorage(params) {
    const { http } = this;
    return http.post("fiQueryMyStorage", params);
  }

  /**
    * @description  删除文件
    * @param params 登录参数
    * @returns {*}
    */
  deleteFiles(params) {
    const { http } = this;
    return http.post("fiDeleteFileRecord", params);
  }

  /**
    * @description  编辑文件名字
    * @param params 登录参数
    * @returns {*}
    */
  editFileName(params) {
    const { http } = this;
    return http.post("fiUpdateFileRecord", params);
  }

  /**
    * @description  手动同步规则树
    * @param params 登录参数
    * @returns {*}
   */
  getSyncRule(params) {
    const { http } = this;
    return http.post("queryRulesClassifyTree", params);
  }

  /**
    * @description  手动同步规则传值
    * @param params 登录参数
    * @returns {*}
   */
  postSyncRule(params) {
    const { http } = this;
    return http.post("myFileHandSynRule", params);
  }

  /**
    * @description  同步规则列表数据
    * @param params 登录参数
    * @returns {*}
   */
  getSyncList(params) {
    const { http } = this;
    return http.post("fiGetSynMyFile", params);
  }

  /**
    * @description  查询业务模板树
    * @param params 登录参数
    * @returns {*}
   */
  getTemplateClassify(params) {
    const { http } = this;
    return http.post("queryMdTemplateClassify", params);
  }

  /**
    * @description  保存上传文件
    * @param params 登录参数
    * @returns {*}
   */
  saveUploadRecords(params) {
    const { http } = this;
    return http.post("fiSaveUploadRecords", params);
  }

  /**
    * @description  设置数据范围
    * @param params 登录参数
    * @returns {*}
   */
  setDataRange(params) {
    const { http } = this;
    return http.post("fiSetSynMyFile", params);
  }

  /**
   * @description: 获取业务模板字段
   * @param {*} params
   * @return {*}
   */
  mdQueryTemplateFieldList(params) {
    const { http } = this;
    return http.post("mdQueryTemplateFieldList", params);
  }
  // /**
  //   * @description  获取数据范围
  //   * @param params 登录参数
  //   * @returns {*}
  //  */
  // getDataRange(params) {
  //   const { http } = this;
  //   return http.post("fiGetSynMyFile", params);
  // }
}
export default FoldersService;
