/*
 * @Descripttion: select 请求数据处理
 * @Author: 彭博
 * @Date: 2020-07-20 17:31:40
 * @LastEditors: wuqi
 * @LastEditTime: 2021-07-27 17:05:35
 */
import Store from "@/store";
import { base } from "@/assets/js/bank";

// singleRelationCondition 数据范围查询
function getCommonSet(list = [], areaList = []) {
  const result = {};
  // 获取来源字段名
  for (let i = 0; i < list.length; i++) {
    const el = list[i];
    // 查找来源字段数据
    const column = this.findColumn(areaList, el.targetField, "field");
    console.log("find column", column);
    result[el.targetField] = column.showValue;
  }
  // 返回数据范围
  return result;
}

/**
 * 根据依赖数据 匹配当前select 请求config
 * @param {Object} dependData 匹配依赖数据 必传
 * @return: {requestFnStr:}
 */
export const matchConfig = (com = {}) => {
  const { column: _dependData } = com;
  let requestFnStr = "";
  let requestData = {};
  let responseData = [];
  const userInfo = Store.getters.userInfo || {};
  const dataArea = _dependData.dataArea || [];
  const baseDataArea = dataArea.filter((item) => item.exceptFlag === 1);
  const result = getCommonSet.call(_dependData, _dependData.singleRelationCondition, com.getCurrentRegion()); // 获取数据范围
  const relationSet = { relationSet: result };
  const { field, showStyle } = _dependData;
  const dataSource = Number(_dependData.dataSource);

  // console.log("match config", _dependData.baseDataArea, userInfo);
  // 如果是级联数据
  if (_dependData.columnIsCascade) {
    // queryCascade
    requestFnStr = "queryCascade";
    requestData = {
      schemeId: _dependData.cascadeGroupId,
      id: com.prefixId
    };
    // 加班套件 v2.16
  } else if (field === "overtimeType") {
    requestFnStr = "queryRuleAttrData";
    requestData = {
      formId: _dependData.templateId,
      fieldNameVariable: "overtimeType",
      fieldkey: "ruleName",
      busType: 2
    };
    // 请假套件
  } else if (field === "leaveType") {
    requestFnStr = "queryVacationTypeData";
    requestData = {
      userId: userInfo.userId
    };
    // 银行 静态数据
  } else if (field === "bank") {
    responseData = base.bank || [];
    responseData.forEach((itm) => {
      itm.leaf = true;
    });
  } else if (field === "isDefault") {
    // 银行是否为默认默认
    responseData = base.defaultOption || [];
    responseData.forEach((itm) => {
      itm.leaf = true;
    });
    // 城市 静态数据
  } else if (field === "city") {
    responseData = base.city || [];
    responseData.forEach((itm) => {
      itm.leaf = true;
    });
    // com.defaultProps.value = "code";
    // 开户行 需银行 城市数据支撑
  } else if (field === "bankCode") {
    // 查询银行  城市已选数据   任意未选 不查询
    const area = com.getCurrentRegion();
    const bankColumn = _dependData.findColumn(area, "bank", "field");
    const cityColumn = _dependData.findColumn(area, "city", "field");
    const bankCode = bankColumn.columnValue?.id;
    const cityCode = cityColumn.columnValue?.code || cityColumn.columnValue?.id; // 明细子集 复制时取id-name 改变props
    if (bankCode && cityCode) {
      // queryBankInfoList bankTypeCode=001&cityCode=1100
      requestFnStr = "queryBankInfoList";
      requestData = {
        bankTypeCode: bankCode,
        cityCode
      };
      // com.defaultProps.label = "bankName";
      // com.defaultProps.value = "bankCode";
    }
  } else if (field === "requestOrg") {
    // 申请人机构不能选择
  } else if (field === "requestDept") {
    // 暂时保留;
    requestFnStr = "queryUserInfoByIds";
    requestData = { id: "" };
  } else if (field === "createDepId" || field === "createOrgId") {
    requestFnStr = "queryObjectTreeExt";
    requestData = { id: 0, objectType: "001" };
  } else if (dataSource === 1 || !dataSource) {
    _dependData.remote = true;
    // 判断 数据范围 如果数组长度 大于1 才证明配置了 数据范围
    if ((_dependData.singleRangeFilter?.length || _dependData.singleRelationCondition?.length) && _dependData.field !== "refResource") {
      requestFnStr = "bdQuerySingleRangeFiterList";
      requestData = {
        templateId: _dependData.templateId || _dependData.objectTypeId || _dependData.objTypeId,
        templateType: _dependData.templateId ? "1" : "2",
        field: _dependData.field,
        version: _dependData.version,
        commonSet: JSON.stringify(relationSet),
        allFields: _dependData.assistSelect?.length ? 0 : 1,
        assistSelect: JSON.stringify(_dependData.assistSelect)
      };
      if (Number(showStyle) !== 5) {
        // 非平铺
        requestData.id = "0";
      }
    } else if (field === "createId") {
      // 创建人
      requestFnStr = "queryDeptUserTree";
      requestData = {
        id: ""
      };
    } else if (_dependData.field === "applyGoods") {
      // 魔钥物品
      requestFnStr = "bdQueryMyGoodsTree";
      requestData = {};
    } else if (_dependData.field === "refResource") {
      // 查询资源
      requestFnStr = "mdQueryResourceBaseData";
      requestData = {
        templateId: _dependData.templateId
      };
      // 基础数据 _dependData.dataSource == "1"
      // 人员 非级联的人员分支 只有type == 003 才做辅助选择
    } else if (_dependData.areaObjType === "002") {
      requestFnStr = "getPersonDept";
      requestData = {
        isNeedLogo: 1,
        ids: (() => {
          const ary = [];
          dataArea.forEach((itm) => {
            ary.push(itm.id);
          });
          return ary.join(",");
        })(),
        allFields: _dependData.openAssistSelect ? 0 : 1,
        assistSelect: JSON.stringify(_dependData.assistSelect)
      };
      if (Number(showStyle) !== 5) {
        // 非平铺
        requestData.id = "0";
      }
    } else {
      // 其他基础数据
      requestFnStr = "queryBaseTree";
      requestData = {
        objectTypeId: _dependData.areaObjTypeId,
        ids: "",
        allFields: _dependData.openAssistSelect ? 0 : 1,
        assistSelect: JSON.stringify(_dependData.assistSelect)
      };
      if (Number(showStyle) !== 5) {
        // 非平铺
        requestData.id = "0";
      }
    }
  } else if (dataSource === 2) {
    // 自定义数据 _dependData.dataSource == "2"
    // 自定义
    requestFnStr = "queryCustom";
    requestData = {
      field: _dependData.field,
      uuid: _dependData.uuid,
      businessType: 2, // 业务建模 2 基础数据 1
      templateId: _dependData.templateId
    };
    // 固定取 dataArea
  } else if (dataSource === 3) {
    // 不请求数据
  } else if (dataSource === 4) {
    // dataSource 4  未知
    // 不请求数据
  } else if (dataSource === 5) {
    // 银行账户 里面 的 会计年度
  } else if (dataSource === 6) {
    // 业务建模批量编辑时_dependData的version字段没有
    if (_dependData.areaObjType === "002" && !_dependData.version) {
      requestFnStr = "getPersonDept";
      requestData = {
        isNeedLogo: 1,
        ids: (() => {
          const ary = [];
          dataArea.forEach((itm) => {
            ary.push(itm.id);
          });
          return ary.join(",");
        })(),
        allFields: _dependData.openAssistSelect ? 0 : 1,
        assistSelect: JSON.stringify(_dependData.assistSelect)
      };
      if (Number(showStyle) !== 5) {
        // 非平铺
        requestData.id = "0";
      }
    } else {
      // 业务建模 v3.7 chenjm 万江业务建模单选优化
      // 指定业务建模模板类型，指定单选字段
      requestFnStr = "bdQuerySingleRangeFiterList";
      requestData = {
        templateId: _dependData.templateId || _dependData.objectTypeId || _dependData.objTypeId,
        templateType: 1,
        field: _dependData.field,
        version: _dependData.version,
        commonSet: JSON.stringify(relationSet),
        allFields: _dependData.assistSelect?.length ? 0 : 1,
        assistSelect: JSON.stringify(_dependData.assistSelect)
      };
    }
  }

  // 指定具体数据数据 (来源基础数据)
  if (baseDataArea.length && (dataSource === 1 || !dataSource)) {
    // 列表批量编辑时取 dataId
    const ids = baseDataArea.map((itm) => itm.dataId || itm.id);
    requestData.ids = ids.join(",");
  }
  return {
    requestFnStr,
    requestData,
    responseData
  };
};
