/*
 * @Author: pengyu
 * @Date: 2020-07-16 16:35:13
 * @LastEditors: pengyu
 * @LastEditTime: 2021-07-01 14:57:39
 * @Description: file content
 */
// import dayjs from "dayjs";
import BaseColumn from "../base/BaseColumn";
// import { AssignmentOptions } from "../../BusinessCommonHeader";

export default class TimeAxisColumn extends BaseColumn {
  constructor(column) {
    super(column);
    this.columnValue = "";
    this.dateValue = "";
    this.showValue = [0, 0];
  }

  // 提交
  get paramsForSubmit() {
    return {
      [this.field]: this.columnValue
    };
  }

  // 是否能提交数据,校验
  // eslint-disable-next-line consistent-return
  get checkCouldSubmit() {
    // 没有填写不校验其它条件,但是要校验必填
    if (!this.dateValue && this.required) {
      return new Error(`${this.name}不能为空`);
    }

    if ((!this.showValue.length || isNaN(this.showValue[0]) || this.showValue[0] === this.showValue[1]) && this.required && this.choiceRule !== 0) {
      return new Error(`${this.name}请输入使用时间`);
    }
    return true;
  }

  /**
   * @description 处理时间控件的默认值设置
   * @param value default属性
   * @param opts  值更新类型 2020-09-18
  */
  updateValueWithOptions(value, opts) {
    // eslint-disable-next-line no-bitwise
    // if (opts & AssignmentOptions.server) {
    //   if (value && value.includes(",")) {
    //     const darr = value.split(",");
    //     if (this.choiceRule === 0) {
    //       this.dateValue = darr;
    //     } else {
    //       this.dateValue = dayjs(darr[0]).format("YYYY-MM-DD");
    //       this.showValue = [new Date(darr[0]).getTime(), new Date(darr[1]).getTime()];
    //     }
    //   }
    // }

    this.columnValue = value;
    // this.showValue = [new Date(vals[0]).getTime(), new Date(vals[1]).getTime()];
    // this.dateValue = dayjs(vals[0]).format("YYYY-MM-DD");
    super.updateValueWithOptions(value, opts);
  }
}
