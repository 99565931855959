/*
 * @Description: 地址控件
 * @Author: 梁平贤
 * @LastEditors: wuqi
 * @Date: 2019-07-09 18:18:50
 * @LastEditTime: 2021-07-16 09:52:06
 */
import BaseColumn from "../base/BaseColumn";

export default class AddressColumn extends BaseColumn {
  constructor(column) {
    super(column);
    this.column = column;
    this.columnValue = {};
  }

  // 是否能提交数据,校验
  get checkCouldSubmit() {
    // 没有填写不校验其它条件,但是要校验必填
    if (this.required) {
      if (!this.columnValue.provinceId) {
        return new Error(`${this.name}不能为空`);
      }
      if (["1", "2", "3"].includes(this.addrRange) && !this.columnValue.cityId) {
        return new Error(`${this.name}市不能为空`);
      }
      if (["1", "2"].includes(this.addrRange) && !this.columnValue.districtId) {
        return new Error(`${this.name}区不能为空`);
      }
      if (this.addrRange === "1" && !this.columnValue.address) {
        return new Error(`${this.name}详细地址不能为空`);
      }
    }
    return true;
  }

  // 根据字段是否编辑过,来返回信息 明细,子集,地址需要特殊处理 by:lpx
  get fieldEditInfo() {
    if (this.hasBeenEdit) {
      // const keys = [
      //   "provinceId",
      //   "provinceName",
      //   "cityId",
      //   "cityName",
      //   "districtId",
      //   "districtName",
      //   "address"
      // ];
      // // 原始的省市区数据
      // const originData = this.rootNode.mdData[this.field] || {};
      // let editMap = {};
      // keys.forEach((val) => {
      //   // 如果原始的省市区数据和当前的不一样,则表述修改了
      //   const currentValue = this.columnValue[val];
      //   const originValue = originData[val];
      //   if (currentValue !== originValue) {
      //     editMap = Object.assign(editMap, {
      //       [`${this.field}.${val}`]: this.name
      //     });
      //   }
      // });
      return { [`${this.field}`]: this.name };// TODO 只监听到field级别  否在接口会报错
    }
    return {};
  }

  // 提交参数
  get paramsForSubmit() {
    if (Object.keys(this.columnValue).some((key) => this.columnValue[key])) {
      return {
        [this.field]: this.columnValue
      };
    }
      return undefined;
  }

  /**
   * @description: 赋值
   * @param {type}
   * @return:
   */
  updateValueWithOptions(value, opts) {
    const addrRange = Number(this.column.addrRange);
    let formValue = {};
    if (value) {
      if ([4, 3, 2].includes(addrRange)) {
        formValue.provinceId = value.provinceId;
        formValue.provinceName = value.provinceName;
      }
      if ([3, 2].includes(addrRange)) {
        formValue.cityId = value.cityId;
        formValue.cityName = value.cityName;
      }
      if ([2].includes(addrRange)) {
        formValue.districtId = value.districtId;
        formValue.districtName = value.districtName;
      }
      if (addrRange === 1) {
        formValue = value;
      }
      if (value.lat) {
        formValue.lat = value.lat;
      }
      if (value.lng) {
        formValue.lat = value.lng;
      }
    }
    this.columnValue = formValue || {};
    super.updateValueWithOptions(this.columnValue, opts);
  }
}
