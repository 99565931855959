<!--
 * @Author: xujun
 * @Date: 2020-08-31 09:01:57
 * @LastEditors: pengyu
 * @LastEditTime: 2021-06-24 15:02:30
 * @Description: 模板文件
-->
<template>
  <el-dialog
    title="模板文件"
    :visible.sync="visible"
    destroy-on-close
    width="900px">
    <file-list :file-list="fileList" @change="handleCheckChanged" :loading="loading" :hasDelete="false" show-no-data class="my-folder">
      <slot>
        <en-button @click="handleOKButton" class="ok-btn" :disabled="!selectFiles || !selectFiles.length">确定</en-button>
      </slot>
    </file-list>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
import { request } from "en-js";
import { businessService } from "@/api/businessModel";
import http from "@/api/http";
import FileList from "../views/fileList";
import Index from "../data/utils";

export default {
  name: "MyTemplate",
  components: { FileList },
  inject: ["$attachment"],
  props: {
    params: Object, // 请求参数
    defaultSelects: Array// 默认选中
  },
  data() {
    return {
      loading: false,
      visible: false,
      fileList: undefined,
      selectFiles: undefined
    };
  },
  computed: {
    ...mapState(["urlParam"])
  },
  watch: {
    params(val) {
      console.log(val);
      if (this.fileList?.length) {
        this.requestData();
      }
    }
  },
  created() {
  },
  methods: {
    open() {
      if (!this.fileList) {
        this.requestData();
      }
      this.visible = true;
    },
    @request(true, "loading")
    async requestData() {
      const res = await businessService.mdGetFileModel(this.params);
      this.fileList = res || [];
      this.fileList.forEach((item) => {
        item.fileUrl = item.code;
      });
      Index.processInvalidData(this.fileList);
      this.fileList.splice(this.fileList.length);
      this.replaceFileCode();
    },
    async replaceFileCode() {
      const { urlParam } = this;
      const res = await http.post(`${urlParam.baseUrl}/replaceFileCode.api?appKey=${urlParam.appKey}&token=${urlParam.token}&ts=${urlParam.ts}`, {
        fileCodeList: JSON.stringify(this.fileList.map((item) => item.code))
      });
      if (!res.data) {
        this.$message.warning(res.msg === "文件已删除或者地址已经改变" ? "模板文件已删除或者地址已经改变，请联系管理员" : res.msg);
        return;
      }
      // 替换fileCode
      this.fileList.forEach((item) => {
        item.fileCode = res.data[item.fileUrl];
        item.code = item.fileCode;
      });
      this.replaceTags();
    },
    async replaceTags() {
      const { urlParam } = this;
      const fileAttrList = this.$attachment.column?.fileAttrList || [];
      const mdData = JSON.parse(this.params.mdDataStr);
      // 替换标签
      const promises = [];
      this.fileList.forEach((item, index) => {
        const tagList = fileAttrList[index].dataLabelRef.map((val) => ({
          tag: val.labelContent,
          value: Array.isArray(mdData[val.refField]) ? mdData[val.refField].map((data) => data.name).join(",") : mdData[val.refField]
        }));
        promises.push(http.post(`${urlParam.baseUrl}/replaceTags.api?appKey=${urlParam.appKey}&token=${urlParam.token}&ts=${urlParam.ts}`, {
          fileCode: item.code || item.fileCode,
          tagList: JSON.stringify(tagList)
        }));
      });
      const res = await Promise.all(promises);
      console.log(res);
      this.fileList.forEach((item, index) => {
        item.fileCode = res[index].data.newFileCode;
      });
    },
    handleCheckChanged(selectFiles) {
      this.selectFiles = selectFiles;
    },
    handleOKButton() {
      this.$emit("change", this.selectFiles);
      this.visible = false;
    }
  }
};
</script>

<style scoped lang="scss">
  .my-folder {
    height: 500px;
    .ok-btn {
      width: 70px;
    }
  }
</style>
