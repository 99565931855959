/*
 * @Author: pengyu
 * @Date: 2020-07-31 17:35:51
 * @LastEditors: pengyu
 * @LastEditTime: 2020-08-02 16:29:36
 * @Description: 图文合成控件
 */
import BaseColumn from "../base/BaseColumn";
// eslint-disable-next-line import/no-cycle
import { parsingColumns } from "../../parse/BusinessColumnProcess";

export default class TextComposeColumn extends BaseColumn {
  constructor(column) {
    super(column);
    this.sub = parsingColumns(column.sub, this);
    // 图文合成控件,打个标记
    this.sub?.forEach((ele) => { ele.isTextComposeColumn = true; });
  }
}
