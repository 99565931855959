/*
 * @Description: 区域模型, 基础数据包含区域, 业务建模也包装一层,主表区的
 * @Author: 梁平贤
 * @LastEditors: 梁平贤
 * @Date: 2019-07-09 18:18:50
 * @LastEditTime: 2020-06-18 14:39:34
 */
// import * as BusinessColumnProcess from "./BusinessColumnProcess";

import { uuid } from "en-js";
import { BusinessType, SectionType } from "../BusinessCommonHeader.js";
import BaseSection from "./BaseSection";

export default class BusinessSection extends BaseSection {
  constructor(datas, businessType = BusinessType.basic) {
    super(datas || {});
    this.sectionType = SectionType.main;
    // 字段信息
    if (businessType === BusinessType.basic || businessType === BusinessType.pureBasic) {
      this.name = datas.name; // 区域名称
      this.createTime = datas.createTime;
      this.enable = datas.enable; // 是否启用?
      this.id = datas.id;
      this.isDefault = datas.isDefault;
      this.modifyTime = datas.modifyTime;
      this.objTypeId = datas.objTypeId;
      this.sort = datas.sort;
      this.sts = datas.sts;
      this.tenantId = datas.tenantId;
      this.uuid = datas.uuid;
    } else {
      this.uuid = datas.uuid ? datas.uuid : uuid();
    }
  }

  // 创建一个固化的section ...需要把通知人/审批人/流程/日志等等东西放进去
  static creatFixSection(columns) {
    const section = new BusinessSection({});
    // 就几个column 这样几次循环不影响性能
    columns.forEach((column) => { column.parentNode = section; });
    section.fields = [
      ...columns
    ];
    section.areas = columns.map((column) => [column]);
    return section;
  }
}
