/*
 * @Description: 收付信息
 * @Author: 梁平贤
 * @LastEditors: 彭博
 * @Date: 2019-09-11 14:09:18
 * @LastEditTime: 2020-08-13 11:54:29
 */
import DetailColumn from "../detail/DetailColumn";
/* eslint import/no-cycle: [2, { maxDepth: 1 }] */
import * as BusinessColumnProcess from "../../parse/BusinessColumnProcess";

export default class CashInfoColumn extends DetailColumn {
  constructor(fields) {
    super(fields);
    this.sub = BusinessColumnProcess.parsingColumns(fields.sub, this);
    console.log("收付信息 ~~~ constructor", this.createDetails);
  }
}
