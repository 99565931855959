/*
 * @Author: pengyu
 * @Date: 2020-07-24 11:58:17
 * @LastEditors: pengyu
 * @LastEditTime: 2021-04-29 16:56:46
 * @Description: 子项
 */
import { cloneDeep } from "lodash";
import BaseColumn from "../base/BaseColumn";
import { AssignmentOptions } from "../../BusinessCommonHeader.js";

export default class SubItemColumn extends BaseColumn {
  tableData = [];

  constructor(column) {
    super(column);
    this.columnValue = [];
  }

  // 提交
  get paramsForSubmit() {
    if (this.tableData && this.tableData.length) {
      const arr = [];
      this.tableData.forEach((itm) => {
        const obj = {};
        itm.sections[0].fields.forEach((citm) => {
          if (this.checkInShowContent(citm.field)) {
            Object.assign(obj, citm.paramsForSubmit);
          }
        });
        arr.push(obj);
      });
      return {
        [this.field]: arr
      };
    }
    return {
      [this.field]: []
    };
  }

  checkInShowContent(field) {
    return !!this.showContent.filter((itm) => itm.field === field)[0];
  }

  /**
   * @description: 赋值
   * @param {type}
   * @return:
   */
  updateValueWithOptions(value, opts) {
    // eslint-disable-next-line no-bitwise
    if (opts & AssignmentOptions.server) {
      const { rootNode } = this;
      value?.forEach((itm) => {
        const busData = cloneDeep(rootNode);
        const fields = busData.sections[0].fields;
        const arr = fields.filter((fd) => this.checkInShowContent(fd.field));
        this.initTheServeValue(arr, itm, opts);
        busData.id = itm.id;
        busData.dataLevel = 1;
        this.tableData.push(busData);
      });
    }
    this.columnValue = value;
    super.updateValueWithOptions(value, opts);
  }

  initTheServeValue(fields, data, opts) {
    fields.forEach((fd) => {
      if (data[fd.field] !== "" && data[fd.field] !== undefined) {
        fd.updateValueWithOptions(data[fd.field], opts);
      }
    });
  }
}
