var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title || "附件",
        visible: _vm.visible,
        "destroy-on-close": "",
        "append-to-body": "",
        width: "900px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "preview-box" }, [
        _c("div", { staticClass: "content" }, [
          _c(
            "div",
            { staticClass: "image-box" },
            _vm._l(_vm.allImageList, function (file, index) {
              return _c("image-file", {
                key: file.id || file.uid,
                attrs: {
                  type: "image",
                  size: "small",
                  direction: "h",
                  "delete-method": _vm.handleDelete,
                  hasDownload: true,
                  data: file,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.handleClickImage(index)
                  },
                },
              })
            }),
            1
          ),
          _c(
            "div",
            { staticClass: "file-box" },
            _vm._l(_vm.allFileList, function (file, index) {
              return _c("image-file", {
                key: file.id || file.uid,
                attrs: {
                  direction: "v",
                  height: "35",
                  "delete-method": _vm.handleDelete,
                  hasDownload: true,
                  data: file,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.handleClickFile(index)
                  },
                },
              })
            }),
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }