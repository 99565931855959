/*
 * @Description: 控制是否需要展示右侧的按钮或按钮集合
 * @Author: 梁平贤
 * @Date: 2020/7/28 15:07
 */

import { BusinessFieldType, InterfaceType } from "../../../../data/BusinessCommonHeader";

export const RightAdditionButtonType = {
  assistSelect: 1, // 辅助选择
  assistView: 2, // 辅助查看
  interfaceOCR: 3, // 关联ocr
  interfaceSkyEye: 4, // 企业征信
  interfaceOther: 5, // 关联其他
  associateShow: 6, // 关联显示
  rollUp: 7, // 向上汇总
  breakDown: 8, // 向下分解,
  relationAdd: 9, // 关联添加
  addressMap: 10, // 地图定位
  singleSign: 11 // 单点登录
};

const MxRightAddition = {
  computed: {
    // 是否为手动关联添加
    isManualAddRelation() {
      return !!(this.column.produceType === 2 && this.column.mappingRule?.length);
    },
    // 是否是关联添加后详情需要展示关联数据
    isRelationAddAfter() {
      const relationData = this.businessData?.originData?.mdData[`${this.column.field}_relationData`];
      return Array.isArray(relationData) ? relationData.length : relationData;
    },
    // 是否需要展示右侧是按钮或按钮集合,目前只有单选,文本,数值,金额用到,不排除会扩展
    rightAdditionButtons() {
      const additionButtons = [];
      // console.log(this.column, "additionButtons");
      // 单点登录
      if (!this.column.editable && [BusinessFieldType.select, BusinessFieldType.inputCommon].includes(this.column.fieldType) && this.column.autoAccount === 0) {
        additionButtons.push(RightAdditionButtonType.singleSign);
      }
      // 单选 或 多选
      if ([BusinessFieldType.select, BusinessFieldType.multiSelect].includes(this.column.fieldType)) {
        //  单选可能有,辅助选择,辅助查看,关联显示
        if (this.column.editable) {
          //  可以编辑的状态可能有辅助选择
          if (this.column.openAssistSelect) {
            additionButtons.push(RightAdditionButtonType.assistSelect);
          }
          if ((this.column.isPenetrateField && this.column.parentNode.refData?.id) || this.isManualAddRelation) {
            additionButtons.push(RightAdditionButtonType.relationAdd);
          }
        } else {
          //  不能编辑的状态可能有辅助查看和关联显示
          if (this.column.openAssistView) {
            additionButtons.push(RightAdditionButtonType.assistView);
          }
          // this.column.relationData?.length ||
          if (this.column.relationData?.length || this.isRelationAddAfter) {
            additionButtons.push(RightAdditionButtonType.associateShow);
          }
        }
      } else if (this.column.editable) {
        // 数值,金额,文本
        // 能编辑的时候可能有:关联其他接口,OCR,企业征信
        if (this.column.interfaceType === InterfaceType.ocr) {
          additionButtons.push(RightAdditionButtonType.interfaceOCR);
        } else if (this.column.interfaceType === InterfaceType.skyEye || this.column.isConnectBySkyEyeBtn) {
          additionButtons.push(RightAdditionButtonType.interfaceSkyEye);
        } else if (this.column.interfaceType === InterfaceType.other) {
          additionButtons.push(RightAdditionButtonType.interfaceOther);
        } else if ((this.column.isPenetrateField && this.column.parentNode.refData?.id) || this.isManualAddRelation) {
          additionButtons.push(RightAdditionButtonType.relationAdd);
        } else if (BusinessFieldType.address === this.column.fieldType) {
          if (this.column.field === "outsideAddress") {
            additionButtons.push(RightAdditionButtonType.addressMap);
          }
        }
      } else {
        // 不能编辑的时候可能有关联显示, 企业征信
        if (this.column.relationData?.length || this.isRelationAddAfter) {
          additionButtons.push(RightAdditionButtonType.associateShow);
        }
        if (this.column.interfaceType === InterfaceType.skyEye || this.column.isConnectBySkyEyeBtn) {
          additionButtons.push(RightAdditionButtonType.interfaceSkyEye);
        }
        // 不能编辑,可能有向上汇总和分解
        if (this.column.isConnectByRollup) {
          additionButtons.push(RightAdditionButtonType.rollUp);
        }
        if (this.column.isConnectByBreakDown) {
          additionButtons.push(RightAdditionButtonType.breakDown);
        }
      }
      return additionButtons;
    }
  }
};

export default MxRightAddition;
