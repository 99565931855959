/*
 * @Description:
 * @Author: 梁平贤
 * @Date: 2020/6/30 17:10
 */

import BaseClass from "@/tools/classes/base/BaseClass";
import { enEqual } from "@/tools/compare";

export default class MdPermission extends BaseClass {
  isShow;// 显示权限

  isEdit;// 编辑权限

  isShowStandard;// 标准控制查看

  isAdd;// 新增

  requrein;// 必填

  field;// 字段Field

  constructor(data) {
    super();
    this.parsingData(data);
  }

  /**
   * 根据字段field查找权限
   * @param field 字段field
   * @param permissionList 权限数组
   * @returns {*} 返回对应字段的权限
   */
  static searchColumn(field, permissionList) {
    return permissionList?.find((val) => val.field === field);
  }

  // 是否能编辑
  get canEdit() {
    return enEqual(this.isEdit, 0);
  }

  // 是否展示
  get canDisplay() {
    return enEqual(this.isShow, 0);
  }

  // 是否新增
  get canAdd() {
    return enEqual(this.isAdd, 0);
  }

  // 是否必填
  get isRequire() {
    return enEqual(this.requrein, 0);
  }

  // 是否展示超标
  get canShowStandard() {
    return enEqual(this.isShowStandard, 0);
  }

  // 是否是流程中的权限
  get isFlowPermission() {
    return this.requrein?.length;
  }
}
