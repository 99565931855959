/*
 * @Author: pengyu
 * @Date: 2020-07-22 16:01:15
 * @LastEditors: pengyu
 * @LastEditTime: 2020-08-02 09:33:19
 * @Description: 标记完成控件
 */

import BaseColumn from "../base/BaseColumn";

export default class MarkCompleteColumn extends BaseColumn {
  constructor(column) {
    super(column);
    this.columnValue = 1;
  }

  isComplete = false;

  // 提交参数
  get paramsForSubmit() {
    return {
      [this.field]: this.columnValue
    };
  }

  /**
   * @description: 赋值
   * @param value}
   * @param opts
   */
  updateValueWithOptions(value, opts) {
    this.columnValue = value === undefined ? 1 : value;
    super.updateValueWithOptions(value, opts);
  }
}
