/*
 * @Description: 分割线
 * @Date: 2020-04-27 11:14:25
 * @LastEditors: 梁平贤
 * @LastEditTime: 2020-04-27 13:40:45
 */
import BaseColumn from "../base/BaseColumn";

// 分割线
export default class SeperatorLineColumn extends BaseColumn {

}
