var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "en-dialog",
    {
      staticClass: "summary-resolve-detail",
      staticStyle: { "text-align": "left" },
      attrs: {
        width: "65%",
        visible: _vm.visible,
        "append-to-body": "",
        title: _vm.title,
        "close-on-click-modal": false,
      },
      on: { close: _vm.close },
    },
    [
      _vm.datas
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "en-loading",
                  rawName: "v-en-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { height: "60vh" },
            },
            [
              _vm.isSummaryUp
                ? _c("div", { staticClass: "header" }, [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          color: "#636C78",
                          "font-size": "12px",
                          "margin-left": "13px",
                        },
                      },
                      [_vm._v("总" + _vm._s(_vm.name) + "：")]
                    ),
                    _c(
                      "span",
                      {
                        staticStyle: { color: "#3e90fe", "font-size": "14px" },
                      },
                      [_vm._v(_vm._s(_vm.value))]
                    ),
                  ])
                : _c("div", { staticClass: "header" }, [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          color: "#636C78",
                          "font-size": "12px",
                          "margin-left": "13px",
                        },
                      },
                      [_vm._v(_vm._s("剩余" + _vm.name) + "：")]
                    ),
                    _c(
                      "span",
                      {
                        staticStyle: { color: "#3e90fe", "font-size": "14px" },
                      },
                      [_vm._v(_vm._s(_vm.surplusValue))]
                    ),
                    _c(
                      "span",
                      {
                        staticStyle: { color: "#01AA73", "font-size": "12px" },
                      },
                      [_vm._v("（已分解：" + _vm._s(_vm.showUseValue) + "）")]
                    ),
                  ]),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.datas, border: "" },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "index", label: "序号", width: "80" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "业务名称" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "value", label: _vm.labelValueTitle },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }