<!--
 * @Author: wuqi
 * @Date: 2021-07-28 17:39:30
 * @LastEditors: wuqi
 * @LastEditTime: 2021-07-28 18:37:42
 * @Description:
-->
<template>
  <en-dialog
      :visible.sync="dialogVisible"
      title="查看地图"
      :close-on-click-modal="false"
      @close="dialogVisible = false"
    >
    <div class="amap-page-container">
      <el-amap-search-box
        class="search-box"
        :search-option="searchOption"
        :on-search-result="onSearchResult"
      ></el-amap-search-box>
      <el-amap
        ref="map"
        vid="amapDemo"
        :amap-manager="amapManager"
        :center="center"
        :zoom="zoom"
        :plugin="plugin"
        :events="events"
        class="amap-demo"
      >
        <el-amap-marker :position="center"></el-amap-marker>
      </el-amap>
    </div>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <en-button
        type="primary"
        @click="confirm"
      >保存</en-button>
    </span>
  </en-dialog>
</template>
<script>
// 引入 获取实例
import { AMapManager } from "vue-amap";

const amapManager = new AMapManager();
let Geocoder; // 先声明变量，
export default {
  data() {
    const self = this;
    return {
      amapManager,
      address: {},
      zoom: 12,
      input: "",
      markers: [],
      searchOption: {
        city: "全国",
        citylimit: false
      },
      center: [112.982279, 28.19409],
      events1: {
        init: (o) => {
          const { lng, lat } = o.getCenter();
          this.center = [lng, lat];
        },
        click: () => {
          this.hotelList.forEach((label) => {
            label.active = false;
            label.select = false;
          });
        },
        zoomend: () => {
          // 获取当前缩放zoom值
          const currentZoom = this.$refs.map.$$getInstance().getZoom();
          this.zoom = currentZoom;
        }
      },
      events: {
        init: (o) => {
         const { lng, lat } = o.getCenter();
         this.center = [lng, lat];
        },
        moveend: () => {},
        zoomchange: () => {},
        click: (e) => {
          self.center = [e.lnglat.lng, e.lnglat.lat];
          Geocoder.getAddress(self.center, (status, result) => { // 根据坐标获取位置
            if (status === "complete" && result.info === "OK") {
              self.input = result.regeocode.formattedAddress;
              self.address = result.regeocode;
              document.querySelector(".search-box-wrapper input").value = self.input;
            }
          });
        }
      },
      plugin: [
        {
          pName: "Geocoder", // 使用AMap.Geocoder插件
          events: {
            init(o) {
               Geocoder = o; // o 则是AMap.Geocoder的实例 对外部的Geocoder变量进行赋值，在任何地方就都可以使用了
               // self.center 具体坐标 （数组格式） ，function 回调函数
               o.getAddress(self.center, (status, result) => {
                 console.log(status);
                      if (status === "complete" && result.info === "OK") {
                        // result.regeocode.formattedAddress就是具体位置
                        self.input = result.regeocode.formattedAddress;
                        // // 对搜索组件的input进行赋值
                        // document.querySelector(".search-box-wrapper input").value = self.input;
                      }
              });
            }
          }
        },
        {
          showMarker: true, // 定位成功后在定位到的位置显示点标记，默认：true
          pName: "Geolocation", // AMap-Geolocation 定位插件
          events: {
            init(o) {
              // getCurrentPosition 获取当前位置的方法
              // 注意 虽然进页面就会调用这个方法，但是data()中center要有默认值，不然会报错
              o.getCurrentPosition((status, result) => {
                if (result && result.position) {
                  const lng = result.position.lng;
                  const lat = result.position.lat;
                  self.center = [lng, lat];
                  self.loaded = true;
                  self.zoom = 14;
                  self.address = result;
                  self.$nextTick();
                }
              });
            }
          }
        }
      ]
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(v) {
        this.$emit("update:visible", v);
      }
    }
  },
  methods: {
    confirm() {
      console.log(this.address);
      this.$emit("success", this.address);
      this.dialogVisible = false;
    },
    // 点击搜索后触发的事件
    onSearchResult(pois) {
      this.input = document.querySelector(".search-box-wrapper input").value;
      // 这边类似模糊查询 会返回一个数组，我就直接取第一个值了。
      this.center = [pois[0].lng, pois[0].lat];
    }
  }
};
</script>
<style scoped>
.amap-page-container {
  width: 100%;
  height: 400px;
  margin-bottom: 50px;
}
</style>
<style>
.el-vue-search-box-container {
  width: 100% !important;
  margin-bottom: 10px;
}
</style>
