var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "image-file",
        "is-" + _vm.type,
        {
          horizontal: _vm.direction === "h",
          vertical: _vm.direction === "v",
        },
      ],
    },
    [
      _c("div", {
        class: ["progress", { progress100: _vm.progress > 98 }],
        style: _vm.progressStyle,
      }),
      _c("div", { class: ["content-box", _vm.size], style: _vm.heightStyle }, [
        _vm.type === "image"
          ? _c(
              "div",
              { staticClass: "image-box" },
              [
                _c(
                  "el-image",
                  {
                    class: ["image", _vm.size],
                    attrs: { src: _vm.fileUrl, fit: "fill" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "error",
                        attrs: { slot: "error" },
                        slot: "error",
                      },
                      [_c("i", { staticClass: "el-icon-picture-outline" })]
                    ),
                  ]
                ),
                _vm.deletable
                  ? _c("en-button", {
                      staticClass: "delete",
                      attrs: {
                        icon: "shanchu-liebiao",
                        text: "",
                        "icon-color": "red",
                        "stop-propagation": "",
                      },
                      on: { click: _vm.handleDelete },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm.type === "more"
          ? _c(
              "div",
              { staticClass: "more-box" },
              [
                _c(
                  "el-popover",
                  { attrs: { width: "400", trigger: "hover" } },
                  [
                    _c(
                      "div",
                      {
                        class: ["more", _vm.size],
                        attrs: { slot: "reference" },
                        slot: "reference",
                      },
                      [_vm._v(" +" + _vm._s(_vm.moreNum) + " ")]
                    ),
                    _vm._t("more"),
                  ],
                  2
                ),
              ],
              1
            )
          : _c(
              "div",
              { staticClass: "file-box" },
              [
                _c(
                  "div",
                  {
                    staticClass: "file-pre",
                    on: { click: _vm.handleClickFile },
                  },
                  [
                    _c("en-button", {
                      attrs: {
                        text: "",
                        icon: _vm.fileIconColor.icon,
                        "icon-color": _vm.fileIconColor.color,
                        "icon-size": "18",
                      },
                    }),
                    _c(
                      "span",
                      {
                        class: ["file", _vm.size],
                        attrs: { title: _vm.file.name },
                      },
                      [_vm._v(_vm._s(_vm.file.name))]
                    ),
                  ],
                  1
                ),
                _vm.downloadable
                  ? _c("en-button", {
                      staticClass: "download",
                      attrs: {
                        icon: "weibiaoti-1",
                        text: "",
                        "icon-color": "gray",
                        "stop-propagation": "",
                        title: "下载",
                      },
                      on: { click: _vm.handleDownload },
                    })
                  : _vm._e(),
                _vm.deletable
                  ? _c("en-button", {
                      staticClass: "delete",
                      attrs: {
                        icon: "guanbi-danchuang",
                        text: "",
                        "icon-color": "gray",
                        "stop-propagation": "",
                        title: "删除",
                      },
                      on: { click: _vm.handleDelete },
                    })
                  : _vm._e(),
              ],
              1
            ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }