<!--
 * @Description: 文本,金额,数值,多行文本渲染
 * @Author: 梁平贤
 * @Date: 2020/6/30 09:24
 -->

 <template>
  <!-- 关联查看dialog -->
  <div>
    <relation-dialog :relationData="validatorData" :fieldList="relationFieldList"
                     ref="relationDialog"></relation-dialog>
    <el-tooltip :disabled="isValid" placement="bottom">
      <div v-html="formatControlMessage" @click="openDialog" slot="content"></div>
      <!-- 进度 -->
      <el-progress class="bs-progress" v-if="isLinePercent || isCirclePercent" :percentage="column.showValue ? Number(column.showValue) : 0"
       :type="isLinePercent ? 'line' : 'circle'" :stroke-width="isLinePercent ? 12 : 5" :width="40" :style="{top: isLinePercent ? '7px' : ''}"
       :color="column.extAttr.percentageStyleColor"></el-progress>
      <!--天眼查,搜索-->
      <el-autocomplete
        v-else-if="column.isConnectBySkyEye"
        :fetch-suggestions="querySkyEyeSearch"
        :trigger-on-focus="false"
        :maxlength="column.maxLength"
        :value="column.textValue"
        v-model="column.showValue"
        :title="column.showFlag && column.showFlag.length > 0 ? '' : column.showValue"
        :disabled="!column.editable"
        :placeholder="column.showPlaceholder"
        :style="bindStyle"
        :class="{'complete': column.isComplete && column.textValue, 'bm-complete': true}"
        @input="handleInput"
        @change="handleValueChange"
        @select="handleValueChange"
      >
        <right-addition v-if="rightAdditionButtons.length > 0"
                        :column="column"
                        :row-index="rowIndex"
                        :business-data="businessData"
                        slot="append">

        </right-addition>
      </el-autocomplete>

      <!--文本以及多行文本渲染-->
      <el-input v-else-if="isRenderingText || isRenderingMultiText"
                :ref="column.field"
                :id="column.field"
                :type="isRenderingMultiText ? 'textarea':''"
                :autosize="true"
                :maxlength="column.maxLength"
                :value="column.showValue"
                :title="column.showFlag && column.showFlag.length > 0 ? '' : column.showValue"
                :disabled="!column.editable"
                :placeholder="column.showPlaceholder"
                :style="{...bindStyle,...inputStyle(column.field)}"
                :class="{'complete': column.isComplete && column.textValue,
                        'invalid-input': !isValid,
                        'click-jump':canJumpToUrl}"
                @click.native="handleInuptClick"
                @input="handleInput"
                @change="handleValueChange"
      >
        <right-addition v-if="rightAdditionButtons.length > 0"
                        :column="column"
                        :row-index="rowIndex"
                        :business-data="businessData"
                        slot="append">

        </right-addition>
      </el-input>

      <!--数值加密模式-->
      <el-input v-else-if="(isRenderingNumber || isRenderingAmount) && column.isHiddenValueState"
                :autosize="true"
                :ref="column.field"
                :id="column.field"
                :maxlength="column.maxLength"
                :value="column.showValue"
                :title="column.showFlag && column.showFlag.length > 0 ? '' : column.showValue"
                :disabled="!column.editable"
                :placeholder="column.showPlaceholder"
                :style="{...bindStyle,...inputStyle(column.field)}"
                :class="{'complete': column.isComplete && column.textValue}"
                @input="handleInput"
      >
        <right-addition v-if="rightAdditionButtons.length > 0"
                        :column="column"
                        :row-index="rowIndex"
                        :business-data="businessData"
                        slot="append">

        </right-addition>
      </el-input>
      <!--数值渲染 金额-->

      <en-input-number
        v-else-if="(isRenderingNumber || isRenderingAmount) && !column.isHiddenValueState"
        ref="inputNumber"
        v-model="column.showValue"
        :id="column.field"
        :style="{...bindStyle,...inputStyle(column.field)}"
        :class="{'invalid-input': !isValid, 'has-suffix': column.suffix.length>0, 'suffix-2': showSuffix.length===2}"
        :placeholder="column.showPlaceholder"
        :disabled="!column.editable"
        :precision="showReserverDecimal"
        :thousandFormat="column.showThousands == '0' ? true : false"
        :max="column.numberAndMoneyMaxInput"
        :min="-column.numberAndMoneyMaxInput"
        @focus="handleFocus"
        :isTabEdit="isTabEdit"
        @blur="editing = false"
        @input="numberMoneyInput"
        @change="handleValueChange">
        <template
          v-if="column.suffix.length > 0"
          slot="suffix"
        >
          {{ showSuffix }}
        </template>
        <right-addition v-if="rightAdditionButtons.length > 0"
                        :column="column"
                        :row-index="rowIndex"
                        :business-data="businessData"
                        slot="append">
        </right-addition>
      </en-input-number>

    </el-tooltip>
  </div>
</template>
<script>

import _, { debounce } from "lodash";

import { getUrlParamByKey } from "@/tools/util";
import { businessService } from "@/api/businessModel";
import { AssignmentOptions, BusinessFieldType, BusinessDataOpenType } from "../../../data/BusinessCommonHeader.js";
import MxColumnData from "../MxColumnData.js";
import mixinRelation from "../components/relevancyAdd/MixinRelation";
import RelationDialog from "../../relationDialog";
import RightAddition from "../components/rightAddition";
import MxRightAddition from "../components/rightAddition/MxRightAddition";
import { newdebounce } from "./util";

export default {
  name: "BMTextColumn",
  inject: ["$businessDetail"],
  mixins: [MxColumnData, MxRightAddition, mixinRelation],
  components: {
    RightAddition,
    RelationDialog
  },
  props: {
    isValid: {
      type: Boolean,
      default: true
    },
     // 明细第几行
    rowIndex: {
      type: Number,
      default: 0
    },
    column: {
      type: Object,
      require: true,
      default() {
        return {};
      }
    },
    // 可能需要的整体数据
    businessData: {
      type: Object,
      default() {
        return {};
      }
    },
    // 可能需要的整体数据
    isTabEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visible: false,
      // 编辑中
      editing: false
    };
  },
  computed: {
    // percentageStyle 1线型 2环形 0数值
    isLinePercent() {
      return this.column?.extAttr?.percentageStyle === "1" && [BusinessDataOpenType.view, BusinessDataOpenType.readOnly].includes(this.businessData.openType);
    },
    isCirclePercent() {
      return this.column?.extAttr?.percentageStyle === "2" && [BusinessDataOpenType.view, BusinessDataOpenType.readOnly].includes(this.businessData.openType);
    },
    relationFieldList() {
      if (!this.businessData.powerList) return [];
      const showContent = this.businessData.powerList.find((item) => item.field === this.column.field)?.showContent || [];
      const matchContent = showContent.find((item) => item.modelId === this.validatorData.modelId) || {};
      const fieldList = matchContent.modelFieldList?.filter((item) => item.listIsShow !== "1") || [];
      return fieldList;
    },
    formatControlMessage() {
      const { controlMessage } = this.validatorData;
      if (!controlMessage) return "";
      const frontArr = controlMessage.split("“");
      const endArr = frontArr[1].split("”");
      return `${frontArr[0]} "<span style="color: #50add8;">${endArr[0]}</span>" ${endArr[1]}`;
    },
    validatorData() {
      return this.$businessDetail?.validatorData || {};
    },
    // 是否需要显示成url并点击跳转 文本 && url限制 && 可编辑
    canJumpToUrl() {
      return this.isRenderingText && parseInt(this.column.formatCheck, 10) === 9 && !this.column.editable && this.column.textValue;
    },
    // 文本
    isRenderingText() {
      return this.column.fieldType === BusinessFieldType.inputCommon;
    },
    // 数值
    isRenderingNumber() {
      return this.column.fieldType === BusinessFieldType.inputNumber;
    },
    // 数值或者金额的后缀
    showSuffix() {
      if (this.column.suffix === "1") {
        return "元";
      }
      if (this.column.suffix === "2") {
        return "万元";
      }
      return this.column.suffix;
    },
    // 金额
    isRenderingAmount() {
      return this.column.fieldType === BusinessFieldType.inputAmount;
    },
    // 多行文本
    isRenderingMultiText() {
      return this.column.fieldType === BusinessFieldType.multiText;
    },
    // 小数位数, 金额万元比较特殊, 编辑6位, 不编辑2位
    showReserverDecimal() {
      // if (this.column.suffix === "2" && this.column.fieldType === BusinessFieldType.inputAmount && this.editing) {
      //   return 6;
      // }
      return this.column.showReserverDecimal;
    }
  },
  methods: {
    openDialog() {
      if (!this.relationFieldList.length) {
        this.$message.warning("未配置显示字段，请联系管理员");
        return;
      }
      this.$refs.relationDialog.show();
    },
    // 聚焦
    handleFocus() {
      this.editing = true;
      if (this.column.suffix === "2" && this.column.fieldType === BusinessFieldType.inputAmount) {
        this.column.showValue = _.toString(_.divide(this.column.columnValue || 0, 10000));
      }
    },
    async numberMoneyInput(val) {
      if (_.toString(_.divide(this.column.columnValue || 0, 10000)) === _.toString(_.divide(val || 0, 10000))) {
        return;
      }
      // 关联添加 produceType 2手动 1自动
      const {
              updateValueWithOptions, showValue, mappingRule, multipleFlag, produceType
            } = this.column;
            // 手动触发修改
            if (updateValueWithOptions) {
              this.column.updateValueWithOptions(showValue, AssignmentOptions.manual, this.rowIndex);
              // 测试计算公式
              this.businessData?.templateHandle?.calculateExecute(this.column, null, this.rowIndex);
            }
            // 关联添加(自动)
            if (mappingRule && mappingRule.length && produceType === 1) {
              if (!showValue) {
                this.fillRelationMultiField([], true);
                return;
              }
              const { records } = await this.querySubDataList(multipleFlag ? 1 : 9999);
              // multipleFlag 1 单选 0多选
              if (multipleFlag === 1) {
                this.fillRelationMultiField([records[0]]);
              } else {
                this.fillRelationMultiField(records);
              }
            }
            // 模板上的关联属性 zjh
            this.businessData.templateHandle?.associatedDataHandle?.handle(this.column, this.rowIndex);
            newdebounce(() => {
              this.$emit("trChange");
            }, 600);
    },
    // 输入框值改变
    async handleValueChange(val) {
      // 关联添加 produceType 2手动 1自动
      const {
        updateValueWithOptions, showValue, mappingRule, multipleFlag, produceType
      } = this.column;
      // 手动触发修改
      if (updateValueWithOptions) {
        this.column.updateValueWithOptions(showValue, AssignmentOptions.manual, this.rowIndex);
        // 测试计算公式
        this.businessData?.templateHandle?.calculateExecute(this.column, null, this.rowIndex);
      }
      // 关联添加(自动)
      if (mappingRule && mappingRule.length && produceType === 1) {
        if (!showValue) {
          this.fillRelationMultiField([], true);
          return;
        }
        const { records } = await this.querySubDataList(multipleFlag ? 1 : 9999);
        // multipleFlag 1 单选 0多选
        if (multipleFlag === 1) {
          this.fillRelationMultiField([records[0]]);
        } else {
          this.fillRelationMultiField(records);
        }
      }
      // 模板上的关联属性 zjh
      this.businessData.templateHandle?.associatedDataHandle?.handle(this.column, this.rowIndex);
      newdebounce(() => {
        this.$emit("trChange");
      }, 300);
    },
    // 点击
    handleInuptClick() {
      if (this.canJumpToUrl) {
        if (this.column.textValue.includes("zjPayCashier")) { // 中金支付
          const route = {
            path: "/zjPayCashier",
            query: {
              orderId: getUrlParamByKey(this.column.textValue, "orderId")
            }
          };
          const zjRouter = this.$router.resolve(route);
          window.open(zjRouter.href, "_black");
        } else {
          window.open(this.column.textValue, "_blank");
        }
      }
    },
    // 编辑
    handleInput(event) {
      if (this.column.isHiddenValueState) {
        this.column.showFlag = "";
        this.column.showValue = event;
        this.$nextTick(() => {
          if (this.$refs.inputNumber) {
            this.$refs.inputNumber.focus();
            // 骚操作.直接赋值当前的输入
            this.$refs.inputNumber.currentValue = event.replace(/\*/g, "");
          }
        });
        return;
      }
      this.column.showValue = event;
      newdebounce(() => {
        this.$emit("trChange");
      }, 500);
    },
    // 查询天眼查
    querySkyEyeSearch: debounce(async(queryString, cb) => {
      let data = [];
      try {
        const resp = await businessService.queryTYC(queryString);
        data = resp.tycResult.items;
      } catch (e) {
        console.log(e);
      } finally {
        data = data.map((val) => ({
          value: val.name.replace(/<em>/g, "")
            .replace(/<\/em>/g, "")
        }));
      }
      cb(data);
    }, 500)

  },
  mounted() {
    // 因为后缀前缀的css是通过js计算的,我们外部又重置了一次样式,force一下,就会重新计算
    this.$forceUpdate();
  }
};
</script>
<style scoped lang="scss">
  .bs-progress {
    /deep/ .el-progress-bar {
      width: 92%;
    }
  }
  .bm-complete {
    /deep/ .el-input {
      color: inherit;
    }
  }
  span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
  }

  .el-input,
  .en-input-number,
  .el-autocomplete {
    width: 100%;
    /*& /deep/ .el-input__inner {*/
    /*color: inherit;*/
    /*}*/
    .interface {
      cursor: pointer;
      margin-top: 8px;
    }
    & /deep/ .el-input-group__append {
      padding-left: 10px;
      padding-right: 10px;
    }

    & /deep/ .el-input__suffix-inner{
      font-size: 12px;
      color: #a9b5c6;
      margin-right: 6px;
    }
  }

  .en-input-number {
    & /deep/ .el-input {
      text-align: inherit;
      color: inherit;
    }
    &.has-suffix /deep/ .el-input__inner {
      padding-right: 26px;
    }
    &.suffix-2 /deep/ .el-input__inner {
      padding-right: 36px;
    }
  }

  /* .el-textarea{
    & /deep/ .el-textarea__inner{
      border-color: $field-border-color;
    }
    & /deep/ .el-textarea__inner:hover{
      border-color: $field-border-color-hover;
    }
    & /deep/ .el-textarea__inner:focus{
      border-color: $field-border-color-focus;
    }
     &.is-disabled /deep/ .el-textarea__inner{
      color: #636c78;
    }
  }

  /deep/ .el-input__inner:disabled,
  /deep/ .el-textarea__inner:disabled {
    //查看 状态 统一样式
    background: #f5f7fa;
    border: none;
  }
  */
  /*被标记完成*/
  .complete {
    & /deep/ .el-input__inner {
      text-decoration: line-through;
    }
  }

  .invalid-input {
    /deep/ input {
      background: #fbe8e8 !important;
    }
  }

  /*链接可跳转*/
  .el-input.click-jump /deep/ .el-input__inner {
    color: #3e90fe;
    cursor: pointer;
  }

</style>
