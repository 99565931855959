/*
 * @Author: 梁平贤
 * @Date: 2020-08-03 18:38:07
 * @LastEditors: 梁平贤
 * @LastEditTime: 2020-08-02 18:38:07
 * @Description: 其实是关联接口控件
 */

import BaseColumn from "../base/BaseColumn";
import { InterfaceType } from "../../BusinessCommonHeader";

export default class OCRColumn extends BaseColumn {
  // 关联的天眼查字段,需要获取它的值去调用接口
  skyEyeColumn;

  get displayable() {
    if (this.interfaceType !== InterfaceType.skyEye) {
      // 其它接口和ocr都是可编辑才展示
      return super.displayable && super.editable;
    }
    return super.displayable;
  }
}
