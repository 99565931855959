/*
 * @Descripttion:
 * @Author: 彭博
 * @Date: 2020-07-15 09:35:23
 * @LastEditors: pengyu
 * @LastEditTime: 2020-12-29 14:15:06
 */
// 关联数据
import BaseColumn from "../base/BaseColumn";
import { AssignmentOptions, BusinessDataOpenType } from "../../BusinessCommonHeader.js";

export default class AssociatedDataColumn extends BaseColumn {
  columnValue = [];

  filterIds = ""; // 筛选剔除字段

  // 关联显示只有在页面不能编辑的时候才可能会展示(也需要根据权限来)
  get displayable() {
    const superDisplay = super.displayable;
    const { showModel } = this;
    if (showModel === 1) {
      // 关联显示在新增之外的都展示,只有新增不展示
      return superDisplay && this.rootNode.businessParams.openType !== BusinessDataOpenType.add;
    }

    return superDisplay;
  }

  // 提交字段
  get paramsForSubmit() {
    const { showModel, columnValue, field } = this;
    // 关联添加 有数据时才提交
    if (showModel === 1 || !columnValue.length) return null;
    return {
      [field]: columnValue
    };
  }

  get checkCouldSubmit() {
    // 必填 且为空 返回err
    if (this.required && this.columnValue?.length === 0) {
      /* eslint-disable */
      return new Error("请选择" + this.name);
    }
    // 否则 true
    return true;
  }

  // 更新值
  updateValueWithOptions(value, opts) {
    // default 目前无需赋值 无法编辑状态关联数据为自动查询
    if (opts === AssignmentOptions.default && !this.editable) {
      // 保留
    }
    this.updateColumnValueWithOptions(value);
    super.updateValueWithOptions(value, opts);
  }

  // 更新保留值
  updateColumnValueWithOptions(value) {
    this.columnValue = value;
    this.filterIds = this.columnValue.map((line) => line.id)
      .join(",");
  }
}
