var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("en-attachment", {
    staticClass: "display",
    attrs: {
      data: _vm.data,
      mode: "show",
      mdConfig: _vm.mdConfig,
      isTableCell: _vm.isTableCell,
      displayGrid: true,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }