<template>
  <en-table-column
    ref="enTableColumn"
    class="business-table-column"
    :align="align"
    :data="data"
    :field="data.field"
    :filter-sort-config="filterConfig"
    :key="data.id"
    :width="columnWidth"
    min-width="150"
    :fixed="data.fixed">
    <template #filter v-if="isSelect">
      <en-transfer-data v-if="!searchHide" :height="300" :config="transferConfig" v-model="filterData"></en-transfer-data>
    </template>
    <template #filter v-else-if="isRange">
      <en-input-range v-model="filterData" thousandFormat startPlaceholder="最小值" endPlaceholder="最大值"></en-input-range>
    </template>
    <template v-slot="{row}">
      <slot v-if="isCustomContent" name="content" :data="row"></slot>
      <table-content :key="count" v-else :row="row"
      :all-edit="allEdit"
      :is-detail="isDetail" :is-home-page="isHomePage" :col="data" @openTable="handleOpenTable"
      @changeEditStatu="changeEditStatu"
      @openTextComposition="openTextComposition"></table-content>
    </template>
    <template v-slot:header>
      {{columnTitle}}
    </template>
  </en-table-column>
</template>

<script>
import { mapGetters } from "vuex";
import enTransferData from "@/components/en-transfer-data";
import { BusinessFieldType } from "@/components/businessDetail/data/BusinessCommonHeader";
import tableContent from "./views/tableContent";
import Utils from "./data/utils";

export default {
  name: "TableColumn",
  componentName: "TableColumn",
  components: {
    enTransferData,
    tableContent
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    // 是否是在首页展示的
    isHomePage: {
      type: Boolean,
      default: false
    },
    // 是否是明细
    isDetail: {
      type: Boolean,
      default: false
    },
    filterSortConfig: {
      type: Object,
      default: () => ({ sortable: true, filterable: true })
    },
    filterSortMethod: {
      type: Function,
      default: (opt) => {
        console.log("filter ---- change", opt);
      }
    },
    allEdit: {
      type: Array,
      default: () => []
    },
    count: {
      type: Number,
      default: 0
    },
    isKeySearch: { // 是否开启关键字搜索
      type: Boolean,
      default: false
    },
    isCustomContent: { // 自定义内容项
      type: Boolean,
      default: false
    },
    // 附件是否可以排序
    attachmentSortable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      transferConfig: {
        hideRight: true,
        modeType: "tree",
        keywordSearch: this.isKeySearch,
        // isMulti: false,
        dependData: {}
      },
      filterData: undefined,
      searchHide: false
    };
  },
  computed: {
    ...mapGetters("businessModel", [
      "dateVariables"
    ]),
    columnTitle() {
      const { data } = this;
      if (data.suffix && data.fieldType === BusinessFieldType.inputNumber && data.name.indexOf("%") === -1) {
        return `${data.name} (${ data.suffix })`;
      }
      return data.name;
    },
    columnWidth() {
      const { fieldLengthType } = this.data;
      let width = "";
      if (fieldLengthType && fieldLengthType.length) {
        const flt = fieldLengthType.split(",");
        if (flt[1] && flt[0] === "3" && !isNaN(Number(flt[1]))) {
          width = flt[1];// 只支持指定像素宽度，不支持百分比
        }
      }
      return width;
    },
    // 对齐方式 金额默认右对齐
    align() {
      const { inputAmount } = BusinessFieldType;
      let align = this.data.align;
      if (this.fieldType === inputAmount) {
        align = "right";
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.data.align = align;
      }
      return align;
    },
    // 字段类型
    fieldType() {
      return Number(this.data.fieldType);
    },
    isCustomFilter() {
      return this.isSelect || this.isRange;
    },
    isSelect() {
      return [BusinessFieldType.select, BusinessFieldType.multiSelect].includes(this.fieldType);
    },
    isRange() {
      return [BusinessFieldType.inputNumber, BusinessFieldType.inputAmount].includes(this.fieldType);
    },
    filterConfig() {
      // 业务关联 附件 基础数据 不支持搜索排序 收付信息
      const {
        associatedData, attachment, detailParent, shouFu, basicDataControl, cashObject, date, seeFlow, address, textComposition
      } = BusinessFieldType;
      if ([associatedData, detailParent, shouFu, basicDataControl, cashObject, seeFlow, textComposition].includes(this.fieldType) || !this.filterSortConfig) {
        return null;
      }
      // 地址只支持查询 (后面改成也可排序了)
      if (this.fieldType === address) {
        // return { filterable: true };
      }
      if (this.fieldType === attachment) {
        return this.attachmentSortable ? { sortable: true } : {};
      }
      if (this.fieldType === date) {
        const shortcuts = this.dateVariables.filter((item) => item.id !== "0").map((item) => ({
          text: item.name,
          onClick(picker) {
            const variableTime = Utils.getvariableTime(item);
            const end = new Date();
            const start = new Date();
            start.setTime(variableTime[0].getTime());
            end.setTime(variableTime[1].getTime());
            picker.$emit("pick", [start, end]);
          }
        }));
        return {
          ...this.filterSortConfig,
          pickerOptions: {
            shortcuts
          }
        };
      }
      if (!this.isCustomFilter) {
        return this.filterSortConfig;
      }
      const _this = this;
      const config = { ...this.filterSortConfig };
      config.filterValueMethod = function filterValueMethod({ field }) {
        return _this.getFilterValueText(field);
      };
      return config;
    }
  },
  methods: {
    changeEditStatu(value) {
      this.$emit("changeEditStatu", value);
    },
    // 筛选处理
    initData() {
      // 如果是单选 多选类型 BusinessFieldType
      const colData = this.data;
      if (this.isSelect) {
        this.transferConfig.dependData = Utils.dependDataForSelect(colData, this.transferConfig, this.$store.getters.userInfo);
      }
    },
    getFilterValueText(field, data) {
      let filterData = data;
      if (!filterData) {
        filterData = this.filterData;
      }
      if (this.isRange) {
        if (!filterData) {
          filterData = [0, 0];
        }
        return {
          value: filterData.join(","),
          valueText: filterData.join(","),
          notFormat: true
        };
      }
      if (filterData instanceof Array) {
        return filterData.map((item) => this.getFilterValueText(field, item));
      }
      const valueText = filterData.name || filterData.dataName || filterData.text;
      const value = filterData.id || valueText;
      return { value, valueText };// 只接受这两个参数(value 后台需要,valueText 显示需要)
    },
    handleOpenTable(params) {
      this.$emit("openTable", params);
    },
    openTextComposition(params) {
      this.$emit("openTextComposition", params);
    }
  },
  mounted() {
    if (this.filterSortConfig?.filterable) {
      this.initData();// 筛选为真则匹配筛选内容
    }
    if (["overtimeType", "leaveType"].includes(this.data.field)) {
      this.$set(this.transferConfig, "defaultProps", { label: "text" });
    }
    // 回填字段
    this.$nextTick(() => {
      if (this.isRange) {
        this.filterData = this.$refs.enTableColumn.filterSortStore?.value?.split(",").map((i) => Number(i));
      }
      // 绑定了没有回填上去
      if (this.isSelect) {
        this.filterData = this.$refs.enTableColumn.filterSortStore?.value?.map((item) => ({ ...item, id: item.value, name: item.valueText }));
      }
    });
  }
};
</script>
<style lang="scss" scoped>
/deep/ .el-date-editor.el-input{
  width: 100%;
}
.business-table-column  /deep/ .el-input.el-input--prefix .el-input__inner{
  padding: 0 0 0 25px !important;
}
/deep/ .el-input__prefix{
  margin-left: 0;
  left: 0;
}
</style>
