/*
 * @Description: 提交OCR的数据,逻辑比较复杂,独立出来
 * @Author: 梁平贤
 * @Date: 2020/8/14 11:40
 */
/*
关联接口回调写入的逻辑:
1. 主表区直接覆盖值
如果关联接口是主表字段：
1. 明细区或子集区如果第一行是空白行，则填入第一行，否则新增一行。
如果关联接口是明细或子集字段
2. 关联接口是明细字段，该行明细区直接覆盖，主表也是覆盖，可能会生成一行新的子集或者修改第一个空白子集。
3. 关联接口是子集字段，那么明细区和子集区都是相关行直接覆盖，主表区也是覆盖。
*/
import _ from "lodash";
import { enEqual } from "@/tools/compare";
import { AssignmentOptions, BusinessFieldType, SectionType } from "../../../BusinessCommonHeader";

export default function handleOCRData(target) {
  // 提交OCR的数据, 给模板字段赋值
  target.prototype.writeOCRDataToColumns = function writeOCRDataToColumns(data) {
    // 先将数据打包处理....
    const pakageData = this.pakageOCRData(data);
    // 循环主表字段,赋值, 产品要求明细区也是覆盖, 有问题找张总,2020-08-15,希望不要再次被坑,写下这段话时,瑟瑟发抖
    this.rootNode.mainInfos.forEach((column) => {
      // pakageData?.hasOwnProperty(column.field)
      if (pakageData && Object.prototype.hasOwnProperty.call(pakageData, column.field)) {
        // if (column.fieldType !== BusinessFieldType.detailParent) {
        column.updateValueWithOptions(pakageData[column.field], AssignmentOptions.ocr);
        // }
      }
    });
  };

  //  找出数据中包含属于主表区字段的, 都是统一覆盖
  //  找到一个明细区的字段 打包
  //  找到明细字段,打包

  // 打包数据 ... 打包数据的过程中 还需要处理数据格式.....
  target.prototype.pakageOCRData = function pakageOCRData(data) {
    if ((data instanceof Array) && data[0]) {
      data = data[0];
    }
    if (Object.prototype.toString.call(data) !== "[object Object]" || JSON.stringify(data) === "{}") {
      return undefined;
    }

    // 先将数据打包处理....
    const originData = data;
    const pakageData = {};
    // 如果来源字段是主表区
    this.rootNode.allInfos.forEach((column) => {
      // originData?.hasOwnProperty(column.field)
      if (Object.prototype.hasOwnProperty.call(originData, column.field)) {
        if (column.belongSectionType === SectionType.main) {
          const columnData = this.formatOCRDataToColumnData(originData[column.field], column);
          // 主表区字段直接赋值
          if (columnData) {
            // 如果是明细区的话,如果此时有值,需要在数组后面追加,如果无值,则设置key,赋值
            if (column.fieldType === BusinessFieldType.detailParent) {
              // pakageData?.hasOwnProperty(column.field)
              if (pakageData && Object.prototype.hasOwnProperty.call(pakageData, column.field)) {
                const detailDatas = pakageData[column.field];
                if (_.isArray(detailDatas) && _.isArray(columnData)) {
                  detailDatas.push(...columnData);
                }
                // 明细区数据都不是数组... 那么啥都不干吧
              } else {
                // 不存在明细数据 直接赋值
                pakageData[column.field] = columnData;
              }
            } else {
              // 主表区的字段,不是明细直接赋值
              pakageData[column.field] = columnData;
            }
          }
          delete originData[column.field];
        } else if (column.belongSectionType === SectionType.detail) {
          // 明细区字段,先找到所有数据,然后按数组的大小排序,用数量最多的那个组成那么多行数据
          let allLineData = {};
          const detail = column.parentNode;
          let maxLength = 0;
          detail?.sub?.forEach((detailColumn) => {
            // originData?.hasOwnProperty(detailColumn.field)
            if (originData && Object.prototype.hasOwnProperty.call(originData, detailColumn.field)) {
              // 明细都是数组包含多条
              const columnData = this.formatOCRDetailDataToColumnData(originData[detailColumn.field], detailColumn);
              allLineData = Object.assign(allLineData, {
                [detailColumn.field]: columnData
              });
              if (columnData.length > maxLength) {
                maxLength = columnData.length;
              }
              delete originData[detailColumn.field];
            }
          });
          // 组装的多行明细数据
          const parsingDetailData = [];
          for (let i = 0; i < maxLength; i++) {
            let onLineData = {};
            const weakAllLineData = allLineData;
            /* eslint-disable-next-line */
            Object.keys(weakAllLineData)
              .forEach((key) => {
                const valueAtIndex = weakAllLineData[key][i];
                if (valueAtIndex) {
                  onLineData = Object.assign(onLineData, {
                    [key]: valueAtIndex
                  });
                }
              });
            if (JSON.stringify(onLineData) !== "{}") {
              parsingDetailData.push(onLineData);
            }
          }

          // 与上方是同样的逻辑,如果存在则追加,不存在则赋值
          // pakageData?.hasOwnProperty(detail.field)
          if (pakageData && Object.prototype.hasOwnProperty.call(pakageData, detail.field)) {
            const detailDatas = pakageData[detail.field];
            if (_.isArray(detailDatas)) {
              detailDatas.push(...parsingDetailData);
            }
          } else {
            // 不存在明细数据 直接赋值
            pakageData[detail.field] = parsingDetailData;
          }
        }
      }
    });
    return pakageData;
  };
  // 打包明细区数据, 需要处理多条数据, 比如文本对应数组,其实是要生成多条明细数据
  target.prototype.formatOCRDetailDataToColumnData = function formatOCRDetailDataToColumnData(data, column) {
    if (column.belongSectionType !== SectionType.detail) {
      return undefined;
    }
    let details = [];
    switch (column.fieldType) {
      case BusinessFieldType.date:
      case BusinessFieldType.inputAmount:
      case BusinessFieldType.inputCommon:
      case BusinessFieldType.inputNumber:
      case BusinessFieldType.multiText:
        // 数组就是要生成多条
        if (_.isArray(data)) {
          data.forEach((line) => {
            const lineColumnData = this.formatOCRDataToColumnData(line, column);
            if (lineColumnData) {
              details.push(lineColumnData);
            }
          });
        } else {
          details = [this.formatOCRDataToColumnData(data, column)];
        }
        break;
      case BusinessFieldType.select:
      case BusinessFieldType.multiSelect:
        if (_.isArray(data)) {
          data.forEach((line) => {
            const lineColumnData = this.formatOCRDataToColumnData(line, column);
            if (lineColumnData) {
              details.push(lineColumnData);
            }
          });
          // 上面这种方式转换失败, 还是按照一条的去转换
          if (details.length === 0) {
            details = [this.formatOCRDataToColumnData(data, column)];
          }
        }
        break;
      default:
        break;
    }

    return details;
  };

  // 打包数据 ... 打包数据的过程中 还需要处理数据格式.....
  target.prototype.formatOCRDataToColumnData = function formatOCRDataToColumnData(data, column) {
    // 主表字段
    switch (column.fieldType) {
      case BusinessFieldType.date:
      case BusinessFieldType.inputAmount:
      case BusinessFieldType.inputCommon:
      case BusinessFieldType.inputNumber:
      case BusinessFieldType.multiText:
        if (_.isNumber(data) || _.isString(data)) {
          return _.toString(data);
        }
        break;
      case BusinessFieldType.select:
      case BusinessFieldType.multiSelect:
        if (_.isArray(data)) {
          const returnDatas = [];
          data.forEach((value) => {
            if (_.isPlainObject(value) && !_.isNull(value)) {
              returnDatas.push(value);
            } else if (_.isString(value)) {
              // 自定义项包装一下
              if (enEqual(column.dataSource, 2)) {
                if (value.length > 0) {
                  returnDatas.push({
                    id: value,
                    name: value
                  });
                }
              }
            }
          });
          return returnDatas;
        }
        break;
      case BusinessFieldType.detailParent:
        if (_.isArray(data)) {
          return data;
        }
        break;
      default:
        return undefined;
    }
    return undefined;
  };
}
