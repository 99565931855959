/*
 * @Description: 服务器返回的字段信息
 * @Author: 梁平贤
 * @Date: 2020/6/29 09:41
 */

import BaseClass from "@/tools/classes/base/BaseClass";
import { enEqual } from "@/tools/compare";
import { BusinessFieldType } from "../../BusinessCommonHeader";

export default class FieldInfo extends BaseClass {
  // 显示前缀
  showPrefix = true;

  // 前缀长度
  showPrefixLength = "";

  // 与前端约定的唯一id
  uuid = "";

  // 属性ID
  ID = "";

  // 字段英文名（新增时为空）
  field = "";

  // APP显示名字  只取这个
  appFieldName = "";

  // 字段中文名
  name = "";

  // 0-主数据 1-明细
  fieldArea;

  // APP填单是否可见(是:0,否:1)
  appUpdateIsShow;

  // APP列表是否可见(是:0,否:1)
  appListIsShow;

  // 版本号
  version = "";

  // 编号规则
  codeRule = [];

  // 编码规则生成方式，1打开生成，2提交是生成
  ruleGenerateType;

  // 折叠设置  1 默认折叠 2 默认展开
  foldSet;

  /**
   区域字段（明细区、主表区（mainTopArea）、子集区）
   */
  areaShowField = "mainTopArea";

  /**
   默认填充值
   字段默认填写  单选或多选为: defaults:“[{id:xx,name:xxx},{id:xx,name:xxx}]”
   时间：defaults: 0   请选择，没有默认值
   defaults: 1#2017-09-18 08:09/2017-09-15 08:43   当前时间或者当前日期
   defaults: 2#2017-09-18  指定日期/指定时间
   */
  defaults;

  // 填写说明
  instructions = "";

  // 0必填1选填 别设置默认值
  requrein;

  // 显示大写 000-是 001-否
  uppercaseEnable;

  /**
   1 单行文本   2 多行文本  3数值 4金额  5选项（单选）6业务关联 7日期8 标识
   9 附件图片  10进度
   11明细 15 多选 16-纯文本控件 28基础数据  29地址 30子项 31 分割线 34 开票选项卡
   35 子集 36 图片
   string类型，转成int
   */
  fieldType;

  originalFieldType;

  // 子项带出父项数据，0带出1不带
  isDefaultOut;

  // 以下为选项属性
  // 多选
  isAllowMulChoice;

  // 范围对象ID
  areaObjTypeId = "";

  /**
   范围对象数据类型
   自定义 000、
   组织机构 001、
   人员 002、
   岗位 003、
   职级 004、
   科目 005、
   客商 006、
   合同 007、
   项目 008 、
   客商类别 009、
   关系类型 010 、
   支付方式 011、
   城市类别 012
   账套013,
   会计期间014,
   月度汇率015,
   会计科目016,
   现金流量科目017,
   银行账户018,
   隐藏的019科目体系,
   记账主体020
   所在位置021
   */
  areaObjType;

  // [
  // {
  // id:’’,name:’’，
  // color:'',
  // exceptFlag:'排除标记（0是、1否)'
  // fieldControl:[{
  //
  // field:code,
  // flag:0 显示 1不显示
  // }]
  // }
  // ]自定义项

  dataArea = [];
  // 指定的数据ids
  // - (NSString *)specifiedDataIds;

  // 排除的数据ids
  // - (NSString *)ruleOutDataIds;

  // 基础数据范围
  baseDataArea = "";

  // 级联关系id
  cascadeGroupId = "";

  // 前置uuId
  prefixField = "";

  // 是否级联 默认0是，1否 默认1
  isCascade;

  // /数据来源 1-基础数据 2-自定义
  dataSource;

  // 允许添加 0-允许 1-不允许
  allowAddFlag;

  // 选项选择数据需要用到
  content = "";

  // 选择规则0-最底层节点,1-父子节点 2.16
  choiceRule;

  // 选项卡配置数据 key:ID,value:config
  tabControlConfigData = undefined;
  // - (void)updateTabControlConfigData;

  // 范围对象数据类型，获取选择数据类型是人员还是其他
  // - (void)checkDataTypeWithTenantId:(NSString *)tenantId usingBlock:(void(^)(BusinessBasicDataSourceType type))block;
  // 选项的数据源类型
  // - (BusinessBasicDataSourceType)basicDataType;

  /** 2.17自动赋值，字段映射
   * srcField: "name",
   srcFieldType:"1";
   srcUuid: "xsdfs-111",
   srcName: "基础数据名称",
   srcArea: "mainTopArea， depstAndPost",
   srcAreaName:"兼岗信息"
   destField:"m1";
   destName:"目标字段名称";
   destUuid:"目标字段uuid";
   destFieldType:"1";
   */
  autoEvaluation = [];

  // 自动赋值用,mainTopArea或明细区key 2.17自动赋值
  autoChooseArea = "";

  // - (ENBusinessAssociatedAreaType)autoAssignmentAreaType;
  // 自动取值关联区域类型
  // + (ENBusinessAssociatedAreaType)autoAssignmentAreaType:(NSString *)aString;
  //
  assignType = "";

  // /范围过滤配置条件，数据结构参考dataFiltration
  singleRangeFilter = [];

  // 3.7数据来源为业务建模时，配置的单选字段
  choiseField = {};

  // 3.7数据来源为业务建模时，配置的关联条件
  singleRelationCondition = [];

  // -关联数据属性
  // 关联数据类型 1-表单 2-基础数据 3-管理对象 4-单维业务
  relationType;

  // - (NSString *)relationDataDisplayImageName;

  // 关联基础数据，基础数据id
  relationData = "";

  relationTempName = "";

  // 关联基础数据类型 参考areaObjType
  relationDataType = "";

  // 1-仅能关联自己 2-可以关联本部门 3-可以关联全公司
  relationRange = "";

  // 允许多条 0-允许 1-不允许，只能添加一条
  multipleFlag;

  // 关联数据类型
  // - (ENBusinessDataSourceType)associatedDataType;
  // //查看关联数据
  // - (BOOL)isViewRelationData;

  // //#pragma mark-日期
  // /时间格式 8-（yyyy-MM-dd）9-（yyyy-MM-dd HH:mm）
  timeFormat;

  // 日期区间 日期区间 0-未勾选 1-勾选
  dateRange;

  // 统计日期 0-未勾选 1-勾选
  statisticalDate;

  // 时间条件启用标识 0 启用 1 禁用
  timeConditionEnable;

  /** 输入限制
   */
  inputConfig = [];

  // 配置的输入限制提示语
  inputHints = "";
  // 有输入限制
  // - (BOOL)hasInputConfig;
  // 日期格式
  // - (NSString *)dateFormat;
  // 最大日期格式
  // - (NSString *)endDateFormat;

  // - (XDatePickerStyle)datePickerStyle;
  // 不是日期区间的时间控件
  // - (BOOL)isNoRangeOfDateItem;

  accuracySet = "";// 时间间隔(可能为半天)

  // //#pragma mark-数值
  // /1分、2小时、3天
  timeUnit = "";

  // 百分比显示 0 是 1 否
  percentFlag;

  // 后缀,数值需要用到
  suffix = "";

  // 保留小数 0不保留 1保留1位 2保留2位 3保留3位 4保留4位
  reserverDecimal = "";

  // 显示后缀
  showSuffix = "";

  // 数值百分比展示
  // - (BOOL)isPersentNumberValue;
  // 万元单位
  // - (BOOL)isWanYuanUnit;
  // - (void)updateReserverDecimalCount:(NSInteger)count;
  //   //#pragma mark-单文本
  // 标记 0开启 1未开启
  completeFlag = "";

  /**
   1.身份证 正则校验
   2.电话号码 区号+7/8位数字或无区号+7/8位数字
   3.手机号码 正则校验
   4.邮箱 正则校验
   5.三证合一 营业执照、税号、社会信用代码
   6 限制特殊字符 限制特殊字符：[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]。
   7 发票号码
   8 发票代码
   */
  formatCheck;

  // - (NSString *)formatCheckName;

  // #pragma mark-标记完成
  // 关联目标字段
  targetField;
  // 关联了其他字段
  // - (BOOL)isAssociatedOtherField;

  // //#pragma mark-进度
  // 关联进度 0关联 1不关联
  relatedPlanFlag;

  // /进度关联日期(uuid)
  relatedDate = "";

  /**
   进度关联时间类型1.关联时间区间 2.关联时间 （默认1）
   1- 用relatedDate即可，
   2- relatedDate开始时间，relatedEndDate结束时间
   */
  relatedDateType;

  // /关联结束时间，存储结束时间字段
  relatedEndDate = "";

  // #pragma mark-纯文本
  // 子标题
  subTitle = "";

  // 子标题显示位置 居左-1 居中-2 居右-3
  subtitleShowPosition = "";

  // 标题说明
  captionDescription = "";

  // 标题说明显示位置 居左-1 居中-2 居右-3
  captionDescriptionShowPosition = "";

  // /对齐方式。1居左、2居中、3居右。默认0无配置。
  align = "";
  // 子标题显示位置
  // - (NSTextAlignment)subtitleTextAlignment;
  // 标题说明显示位置
  // - (NSTextAlignment)captionDescriptionTextAlignment;

  // #pragma mark-子项数据、关联的数据显示的内容
  // 模板设置显示内容，[{"field":"","name":""}],子项的时候field对应的值是uuid，关联数据的时候对应的是字段
  showContent = [];
  // //#pragma mark-基础数据
  // - (ENBusinessDataSourceType)addBasicDataType;

  // #pragma mark- V2.9新增 自动取值以及其他
  // 对象字段uuid(当前模板里的属性字段)(V2.9版本)
  getObjFieldUuid = "";

  // 对象字段(当前模板里的属性字段)(V2.9版本) 受影响的field
  getObjField;

  // 象字段关联的基础数据里同字段类型的属性字段 (V2.9版本)  取值的key
  getValField;

  // 辅助选择[{field:'',name:''}]
  assistSelect = [];

  // 辅助查看[{field:'',name:''}]
  assistView;

  // 字段分类ID
  classifyId;

  // 自动拆分 是:0,否:1
  autoSplit;

  // 是否更新   是:0,否:1
  isUpdate;

  // 分割线类型1 实线  2虚线 3空白行
  lineType;

  // #pragma mark-明细
  cardIconConfig = {};

  // 明细区关联/穿透字段 关联数据 uuid 支持单行、多行、数值、金额、日期、单选
  penetrateField;

  // 统计启用 0-是 1-否，统计明细中的字段
  sumEnable;

  // 1 卡片式 2 列表式 3 平铺式
  showStyle;

  // 图片张数
  imgNumber;

  // 图片大小
  imgSize;

  // 图标路径
  imgUrl;

  // 字段类型：000 固化 001 自定义
  type;

  // 不参加卡片式的默认为0,参加卡片式的字段，按照左右左右的分别赋值1234
  showsSort;

  // 明细卡片式
  // - (BOOL)isDetailCardStyle;
  // 最大行数 默认1
  maxNum;

  /**
   计息函数配置和行累加配置
   */
  dFunction = [];

  // #pragma mark- 选项卡 2.11
  // UUID
  parentOption;

  // 是否为选项卡属性字段 0是（字段为以t开头） 1不是
  isTabAttrField;
  // 属于选项卡
  // - (BOOL)isBelongsToTab;

  // 对应主区域字段
  mainTabUuid;

  // 对应主区域字段提交数据使用
  mainTabField;

  // 所属选项卡Id，和parentOption对应对象的值对比
  inTabDataId;

  // 所属选项卡uuid
  tabUuid;

  // 所属选项卡字段
  tabField;

  // 模板配置真实字段
  // - (NSString *)configRealField;
  // - (NSString *)configRealUuid;
  //   #pragma mark- 子项关联主体字段、子集
  mainField;

  mainUuid;

  // #pragma mark- 2.13控件优化
  // 0唯一1 不唯一
  onlyFlag;

  // #pragma mark-关联数据属性
  // 关联数据 显示方式1自动生成 2自行添加 3映射生成
  // 明细区 1-选择添加    2-关联添加
  showModel;

  // 规则类型 1:映射赋值， 2映射规则;
  mappingType;

  // 映射规则id
  mappingId;

  /**
   [{
 targetUuid: 目标字段UUID
 targetField: 目标字段Field
 targetFieldType: 目标字段类型
 sourceUuid: 来源字段Field
 sourceField: 来源字段Field
 sourceFieldType:来源字段类型
 }
   ]
   */
  mappingRule;

  // 数据过滤
  dataFiltration = [];

  /**
   关联条件
   */
  relationCondition = [];
  /**
   更新最后一条的
   日期需要属性一致，设置了编码规则的不管，小数点截断，文本截断，所属上级不管，自定义单选的允许自行添加才行
   @param infos
   */
  // #pragma mark-子集明细关联数据属性

  /**
   数据变更[{
 field:'status',//字段
 fieldType:'字段类型 1 单行文本     3数值 4金额 5单选  6 关联数据 7日期  ',//字段类型
 areaObjTypeId:’基础数据类型ID’,
 perator: :3, //判断类型：1-等于，2-不等于，3-包含，4-不包含，5-大于，6-大于且等于，7-小于且等于，8-小于11-区间
 value:"01,02",//判断值，多个值用逗号分割
 valueText:”张三,李四”
 }
   ]
   */
  dataChanges = [];

  /**
   分组设置
   [{
 detailField:明细字段field,
 detailFieldUuid:明细字段uuid,
 subField:子集field,
 subFieldUuid: 子集uuid,
 subChildField: 子集字段field,
 subChildUuid: 子集字段uuid,
 schemeId:关联规则ID,
 }]

   */
  groupSet = [];

  // 子集区分组字段
  groupField;

  // #pragma mark-OCR
  // 配置了OCR能力
  hasOcr;

  // 4.4紧急需求 1:ocr 2:企业征信 3:其他
  interfaceType;

  // 接口id
  refInterfaceId;

  // ocr限制文件类型
  ocrLimitType;

  // #pragma mark- 2.13控件优化，收付对象
  // 收付对象范围 1-员工 2-客商对象 3-手工录入多个用逗号隔开
  cashObjectRange;

  // 0 不勾选 1 勾选  默认不勾选 (收付对象用)
  autoAccount;

  /**
   [ {objType:002,
 objTypeId:xxxx,
 cashObjName: 收付对象名称，
 cashObjType:1 固化对象  2自定义对象
 isAllowAdd: 0 是  1 否
 isAllowShow:0 是 1 否
 }
   ]
   */
  cashObjInfo;

  // - (NSArray *)addCashObjInfos;
  // 0不勾选，1勾选
  durationCalculationFlg;

  // 值 5-5分钟，30-30分钟，60-60分钟
  calculationAccuracy;

  // 1:去尾法、2:进一法、3:四舍五入，默认为去尾法
  valueLogic;

  // #pragma mark-子集

  upCollectSet = [];

  // 选择模板id 发票的是9个9，其它就是业务建模，暂时只有两种数据源
  templateDataId;

  // 关联数据区域类型
  selAreaType;

  // 配置的选择区域对应的字段
  selAreaField;

  // 配置选择区域对应的上一级区域字段
  selAreaParentField;

  // 模板数据类型，发票或者业务数据
  templateType;

  // 显示设置列表样式1:平铺式 2:抽屉式
  showListStyle;

  // 更新selAreaType
  // - (void)updateSelAreaType;

  /**
   平铺样式
   */
  // - (BOOL)isShowTileStyle;
  //
  //   #pragma mark-时间轴
  // 可用时间段 availableTime String 选填 开始时间,结束时间。如08:00,16:00
  availableTime;

  // 是否允许冲突 allowConflict int 0允许、1不允许。默认1
  allowConflict;

  // #pragma mark-附件
  // 限制类型，1附件和图片、2仅附件、3仅图片。默认0无配置。
  limitType;

  // 按照输入框的数据来，如果没有设置则默认是99个。
  limitNum;

  // 仅允许拍照上传 0是 1否
  isPhotograph;

  // 拍照上传添加水印  0是 1否
  isWatermark;

  // 附件数量1 不限 2 单个 3 自定义 默认1不限
  limitNumType;

  // 附件路径类型 1:本地上传、2:我的文件夹
  enclosurePathType;

  // 1.自动生成 2.在线创建 3手工上传 默认3
  produceType;

  // 创建标识:0勾选 1未勾选
  wordCreateFlag;

  // 模板创建标识:0勾选 1未勾选
  templateCreateFlag;

  // 附件显示样式 1:缩略图 2:图标
  enclosureShowStyle;

  // 本地上传 0勾选 1未勾选
  enclosurePathLocalType;

  // 我的文件夹 0勾选 1未勾选
  enclosurePathFileType;

  // 0:无1:正方形 2:长方形
  enlargetyle;

  // #pragma mark-合成控件
  // 位置
  position;

  // 父控件字段
  parentField;

  // #pragma mark-地址
  // 地址控件范围  1省市区地址  2.省市区  3省市  4省
  addrRange;

  // #pragma mark-签到
  // 必填图片
  picRequrein;

  // 提交后允许修改
  submitAllowEdit;

  // 签到多次 1、仅一次 2、可以多次
  signInSecond;

  // 二维码签到
  codeCheckIn;

  // 签到人员字段
  signInField;

  // 签到统计
  signInCount;

  // #pragma mark-4.7
  // 控件隐藏属性
  actived;

  // #pragma mark-other
  // 动态字段
  //   @property (nonatomic, copy) NSString *(^dynamicField)(NSDictionary *data);
  // 后台返回是是JSON字符串，手动解析成数组
  selContent;

  constructor(fieldsData) {
    super();
    // 解析数据
    this.parsingData(fieldsData);
    // fieldsData.forEach( (value, key) => this[key] = value);
    this.color = fieldsData.color ? fieldsData.color : "#606060"; // 字体颜色
    this.fieldType = parseInt(fieldsData.fieldType, 10);
  }

  // 默认是否展开
  get defaultListOpen() {
    return this.foldSet !== 1;
  }

  // 设置了编码规则
  get hasSetCodeRule() {
    return this.codeRule.length > 0;
  }

  // 是否是打开时生成
  get isOpenGenerate() {
    return this.ruleGenerateType === 1;
  }

  // /是否是级联
  get columnIsCascade() {
    return this.isCascade === 0 && this.prefixField.length > 0;
  }

  // /是否多选
  get isMultiSelect() {
    // isAllowMulchoice 暂未校验
    return this.fieldType === 15; // || this.isAllowMulChoice;
  }

  // /选择数据需要的参数
  get selectDataParameter() {
    const dict = {
      objectTypeId: this.areaObjTypeId,
      objType: this.areaObjType,
      field: this.field
    };
    //   [dict setObjectOrNilObject:self.areaObjTypeId forKey:@"objectTypeId"];
    //   [dict setObjectOrNilObject:self.areaObjType forKey:@"objType"];
    //   [dict setObjectOrNilObject:self.field forKey:@"field"];//机构写死参数
    //   if (self.baseDataArea.length > 0) {
    //   [dict setObject:self.baseDataArea forKey:@"ids"];
    // }
    // if ([self.field isEqualToString:kBusinessFixedField_requestOrg] ||
    // [self.field isEqualToString:kBusinessFixedField_createOrgId]) {
    //   [dict setObject:@"002" forKey:@"deptType"];//机构写死参数
    // }
    // NSString *ruleOutDataIds = [self ruleOutDataIds];
    // if (ruleOutDataIds) {
    //   [dict setObject:ruleOutDataIds forKey:@"exceptIds"];
    // }
    //
    // //配置了辅助查看，模糊查询所有字段和辅助选择的字段
    // if (self.assistSelect.count > 0) {
    //   [dict setObject:[self.assistSelect toJSONString] forKey:@"assistSelect"];
    //   [dict setObject:@"0" forKey:@"allFields"];
    // }

    return dict;
  }

  // 数值方法
  get isPersentNumberValue() {
    if (this.fieldType !== BusinessFieldType.inputNumber) {
      return false;
    }
    return enEqual(this.percentFlag, "0") || (this.percentFlag?.length && this.suffix === "%");
  }

  get isWanYuanUnit() {
    return enEqual(this.suffix, "2");
  }

  // 是否新增
  get allowCreate() {
    return this.allowAddFlag === 0;
  }

  // 是否开启辅助功能
  get openAssistSelect() {
    return this.assistSelect.length > 0;
  }

  // 是否开启辅助查看
  get openAssistView() {
    return this.assistView?.length > 0;
  }

  // 是否自动赋值
  get autoAssignment() {
    return this.autoEvaluation.length > 0;
  }
}
