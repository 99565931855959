/*
 * @Description: 流程处理状态枚举等
 * @Author: 梁平贤
 * @Date: 2020/7/8 09:28
 */

export const BillStatus = {
  unCommit: "000", // 未提交
  pending: "001", // 待处理
  approving: "002", // 审批中
  over: "003", // 已结束
  terminated: "004", // 已终止
  suspension: "005", // 暂缓
  approved: "006", // 已审批
  obsoleted: "007", // 已作废
  committed: "008" // 已提交

};

export const RejectType = {
  default: 0, // 默认退回
  node: 1, // 选择节点退回
  nodePerson: 2// 选择人员退回
};
