/* eslint-disable */
/*
 * @Description: 文本字段类,包含文本,数值,金额,多行文本
 * @Author: 梁平贤
 * @Date: 2020/6/29 15:32
 */

import _ from "lodash";
import { enEqual } from "@/tools/compare";
import BaseColumn from "../base/BaseColumn";
import { AssignmentOptions, BusinessFieldType } from "../../BusinessCommonHeader";
import handleOCRData from "../base/extensions/handleOCRData";

// 处理ocr
@handleOCRData
export default class TextColumn extends BaseColumn {
  // 标记完成
  isComplete = false;

  // 文本值，或最小单位元的值，输入框不用管这个,提交用这个，业务逻辑用这个
  columnValue;

  // 关联的天眼查字段,需要获取它的值去调用接口
  skyEyeColumn;
  // 是否被天眼查关联了,需要提供搜索功能 暂时只有文本处理了这个逻辑,其他的忽略
  isConnectBySkyEye = false;
  // 是否被向上汇总或者向下分解关联了
  isConnectByRollup = false;
  isConnectByBreakDown = false;

  // // 关联的标记
  // associatedTagInfo;
  //
  // // 实时搜索数据类型
  // searchType;

  // 最大可输入长度
  get maxLength() {
    switch (this.fieldType) {
      case BusinessFieldType.inputAmount:
      case BusinessFieldType.inputNumber:
        return 15;
        break;
      case BusinessFieldType.inputCommon:
        if (enEqual(this.formatCheck, 5)) {
          return 18;
        } else if (enEqual(this.formatCheck, 10)) {
          return parseInt(this.limitNum, 10);
        }
        return 100;

      case BusinessFieldType.multiText:
        const limit = parseInt(this.limitNum, 10);
        return limit === 0 ? 1000 : limit;
      default:
        break;
    }
  }

  // 暂时先这样写, 字段的值转换文本展示,后续有需要再改
  get textValue() {
    if (this.isHiddenValueState) {
      return this.showFlag;
    }
    return this.showValue;
  }

  // 是否能提交数据,校验
  get checkCouldSubmit() {
    // 没有填写不校验其它条件,但是要校验必填
    if (!this.columnValue) {
      if (this.required) {
        return new Error(`${this.name}不能为空`);
      }
      return;
    }
    return this.inputLimitCheck();
  }

  // 是否手动修改的key
  get assignmentTypeKey() {
    return this.field + "_manual_modification";
  }


  // 提交
  get paramsForSubmit() {
    if (this.columnValue?.length) {
      let param = {
        [this.field]: this.columnValue,
        // fieldType:this.fieldType
      };
      if (this.fieldType === BusinessFieldType.inputNumber || this.fieldType === BusinessFieldType.inputAmount) {
        // 数值提交
        param = {
          [this.field]: Number(this.columnValue),
          // fieldType:this.fieldType
        };
        // 手动修改需要添加标志
        if (this.historyAssignment & AssignmentOptions.manual) {
          param = Object.assign(param, { [this.assignmentTypeKey]: 1 });
        }
      }
      return param;
    }
  }

  // 输入的最大值
  get numberAndMoneyMaxInput() {
    // 最大15位, 得到类似 9999999.99
    return (Math.pow(10, 15) - 1) / Math.pow(10, this.showReserverDecimal);
  }


  // 展示的小数位数
  get showReserverDecimal() {
    // 金额
    if (this.fieldType === BusinessFieldType.inputAmount) {
      const decimal = _.toNumber(this.reserverDecimal);
      // return decimal;
      return decimal > 0 ? decimal : 2;
    }
    // 数值
    return _.toNumber(this.reserverDecimal);
  }

  // 参与后台交互的小数位数
  get commitReserverDecimal() {
    const showReserverDecimal = this.showReserverDecimal;
    if (this.isPersentNumberValue) {
      return showReserverDecimal + 2;
    }
    return showReserverDecimal;
  }

  // 更新值
  updateValueWithOptions(value, opts,rowIndex) {


    value = _.toString(Array.isArray(value) ? value.map(item => item.name) 
      : typeof value === "object" ? value?.name 
      : value);
    // 不能赋值返回
    if (!this.canAssignmentWithValueAndOpts(value, opts)) {
      return;
    }
    if (this.fieldType === BusinessFieldType.inputAmount
      || this.fieldType === BusinessFieldType.inputNumber) {
      if (value.length > 0) {
        // 如果是百分比，默认值、手动输入的、手动输入直接用就好了，其它的来源数据需要乘100
        if (!(opts & AssignmentOptions.manual || opts & AssignmentOptions.change)) {
          if (this.isPersentNumberValue) {
            value = _.toString(_.multiply(value, 100));
          }
        }
        if (opts != AssignmentOptions.server && !(opts & AssignmentOptions.manual || opts & AssignmentOptions.change)) {
          value = _.toString(_.round(value, this.showReserverDecimal));
        }
      }
    }

    if (enEqual(this.columnValue, value)) {
      // /值没改变的时候，触发改变后触发手动填写事件，回调其它业务逻辑
      if (!(this.lastAssignment & AssignmentOptions.change)) {
        return;
      }
    }
    this.updateColumnValueWithOptions(value, opts,rowIndex);
    super.updateValueWithOptions(value, opts,rowIndex);
 
  }

  // 根据赋值类型来判断能否赋值  这个方法貌似没有作用,并且存在bug, 编码规则没赋值, 先这样处理 2021-02-23 lpx
  canAssignmentWithValueAndOpts(value, opts) {
    // 设置了编码规则的不让赋值
    // if (this.hasSetCodeRule) {
    //   return false;
    // }
    // 默认值并且已经有值的情况下不赋值
    if (opts & AssignmentOptions.default && this.columnValue?.length) {
      return false;
    }
    return true;
  }

  // 更新真实存储的值
  updateColumnValueWithOptions(val, opt) {
    this.columnValue = val;
    this.showValue = val;
    if (this.fieldType === BusinessFieldType.inputNumber) {
      // 百分比给后台时需要除以100
      if (this.isPersentNumberValue) {
        this.columnValue = _.toString(_.multiply(val, 0.01));
      }
      // ocr识别可能带%
      if (val.lastIndexOf("%") === val.length - 1) {
        val = val.slice(0, val.length - 1);
        this.columnValue = val;
        this.showValue = val;
      }
    } else if (this.fieldType === BusinessFieldType.inputAmount) {
      // 万元单位手动触发,除以一万. 其他的是解析后台的值, 需要乘以一万
      if (this.isWanYuanUnit) {
        if (opt & AssignmentOptions.manual) {
          this.columnValue = _.toString(_.multiply(val, 10000));
        } else {
          this.showValue = _.toString(_.divide(val, 10000));
          this.columnValue = val;
        }
      }
    }
  }
}
