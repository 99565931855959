var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "上传附件",
            visible: _vm.visible,
            "close-on-click-modal": false,
            "append-to-body": "",
            "destroy-on-close": "",
            width: "900px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "en-loading",
                  rawName: "v-en-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "main-dialog",
            },
            [
              !_vm.loading
                ? [
                    _c(
                      "div",
                      { staticClass: "btn-list" },
                      [
                        _vm.$attachment.hasLocalPermisson
                          ? _c(
                              "en-upload",
                              {
                                staticClass: "upload",
                                attrs: {
                                  "on-change": _vm.handleUploadChange,
                                  "on-progress": _vm.handleProgressChange,
                                },
                              },
                              [
                                _c("en-button", { attrs: { icon: "add" } }, [
                                  _vm._v(" 本地文件"),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.$attachment.hasTemplatePermisson
                          ? _c(
                              "en-button",
                              {
                                attrs: { icon: "add", type: "green" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$refs.myTemplate.open()
                                  },
                                },
                              },
                              [_vm._v("模版文件")]
                            )
                          : _vm._e(),
                        _vm.$attachment.hasWordPermission
                          ? _c(
                              "en-button",
                              {
                                staticClass: "word-btn",
                                attrs: { icon: "add" },
                                on: { click: _vm.openPageOffice },
                              },
                              [_vm._v("Word文件")]
                            )
                          : _vm._e(),
                        _vm.$attachment.hasMyFolderPermission
                          ? _c(
                              "en-button",
                              {
                                attrs: { icon: "add", type: "green" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$refs.myFolder.open()
                                  },
                                },
                              },
                              [_vm._v("我的文件夹")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "file-list",
                      {
                        attrs: {
                          "file-list": _vm.fileList,
                          "other-height": "50",
                          defaultSelect: true,
                        },
                        on: {
                          "update:fileList": function ($event) {
                            _vm.fileList = $event
                          },
                          "update:file-list": function ($event) {
                            _vm.fileList = $event
                          },
                          change: _vm.handleCheckChanged,
                        },
                      },
                      [
                        _vm._t("default", function () {
                          return [
                            _c(
                              "div",
                              [
                                _c(
                                  "en-button",
                                  {
                                    key: "scfj",
                                    staticClass: "btn",
                                    attrs: {
                                      disabled:
                                        !_vm.selectFiles ||
                                        !_vm.selectFiles.length,
                                    },
                                    on: { click: _vm.handleOKButton },
                                  },
                                  [_vm._v("确定")]
                                ),
                              ],
                              1
                            ),
                          ]
                        }),
                      ],
                      2
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
      _vm.$attachment.hasTemplatePermisson
        ? _c("my-template", {
            ref: "myTemplate",
            attrs: { params: _vm.requestParams },
            on: { change: _vm.handleAddNewFiles },
          })
        : _vm._e(),
      _vm.$attachment.hasWordPermission
        ? _c("my-word", {
            ref: "myWord",
            attrs: { params: _vm.requestParams },
            on: { change: _vm.handleAddNewFiles },
          })
        : _vm._e(),
      _vm.$attachment.hasMyFolderPermission
        ? _c("my-folder", {
            ref: "myFolder",
            on: { change: _vm.handleAddNewFiles },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }