/*
 * @Author: pengyu
 * @Date: 2020-08-06 16:56:46
 * @LastEditors: pengyu
 * @LastEditTime: 2020-08-06 17:01:41
 * @Description: 选项卡控件
 */
import BaseColumn from "../base/BaseColumn";

export default class TabControl extends BaseColumn {

}
