/*
 * @Description:
 * @Author: 梁平贤
 * @Date: 2020/6/24 15:17
 */

export default class BaseClass {
  /**
   * 快速初始化字段,类,数组类等...
   * @param properties
   *
   * @param datas 对应的数据
   */
  //   [
  //     "some",
  // {
  //   name:"some",
  //   type: Object,
  //   className:ClassName
  // },
  // {
  //   name:"some",
  //     type: Array,
  //   className:ClassName
  // }
  // ];
  bindProperties = (properties, datas) => {
    properties.map((property) => {
      // 简单数据类型
      if (typeof property === "string") {
        this[property] = datas[property];
      } else if (typeof property === "object") {
        if (property.type === Object) {
          const ClassName = property.className;
          const propertyName = property.name;
          this[propertyName] = new ClassName(datas[propertyName] || {});
        } else if (property.type === Array) {
          const ClassName = property.className;
          const propertyName = property.name;
          const array = datas[propertyName];
          if (!array) {
            return [];
          }
          const returnArray = array.map((data) => new ClassName(data || {}));
          this[propertyName] = returnArray;
        }
      }
      return [];
    });
  }

  /**
   * 把json数据的key全部解析成class的属性 调试阶段写法,最好还是自己一个个字段解析
   * @param data
   */
  parsingData(data) {
    Object.keys(data)
      .forEach((key) => {
        this[key] = data[key];
      });
  }
}
