/*
 * @Description: 关联接口
 * @Date: 2020-04-27 11:14:25
 * @LastEditors: 梁平贤
 * @LastEditTime: 2020-04-27 13:41:26
 */
import BaseColumn from "../base/BaseColumn";

export default class AssociatedInterfaceColumn extends BaseColumn {

}
