/*
 * @Description:  控制字段展示的一些通用方法等
 * @Author: 梁平贤
 * @LastEditors: linshanfeng
 * @Date: 2019-07-09 18:18:50
 * @LastEditTime: 2023-11-02 15:55:44
 */
import dayjs from "dayjs";
import { dayJsTimeFormat } from "@/tools/date.js";
import { enEqual } from "@/tools/compare";
import { BusinessFieldType, SectionType } from "../../data/BusinessCommonHeader.js";
 // const perator = {
//   1: "===",
//   2: "!=",
//   5: ">",
//   6: ">=",
//   7: "<=",
//   8: "<",
//   3: "包含",
//   4: "不包含"
// };
function peratorFun(left, perator, right) {
  switch (perator) {
    case 1:
      return left === right;
    case 2:
      return left !== right;
    case 5:
      return left > right;
    case 6:
      return left >= right;
    case 7:
      return left <= right;
    case 8:
      return left < right;
    default:
      return false;
  }
}
const MxColumnData = {
  computed: {
    // 绑定的style
    bindStyle() {
      // const lengthStyle = this.columnStyleWidth;
      const color = this.column.color ? this.column.color : "#606060";
      const contentColor = this.column.contentColor ? this.column.contentColor : "#606060";
      // 停用的了指定颜色展示
      const showColor = contentColor;
      // 改成对象形式，因为单选控件可以指定数据并自定义数据的颜色
      const bindStyle = {
        color: showColor,
        "text-align": this.column.align
      };
      return { ...bindStyle, ...this.prominentStyle };
    },
    inputStyle() {
      return (ref, selectType) => {
        this.$nextTick(() => {
          const dom = document.getElementById(ref);
          if (dom) {
            if (["el-input__inner", "el-textarea__inner"].includes(dom.className)) {
                dom.style.color = this.column.contentColor;
                dom.style["border-color"] = this.column.borderColor;

                if (!["001", "002"].includes(selectType)) {
                  dom.style["text-align"] = this.column.align;
                }
            }
          }
        });
        return {};
      };
    },
    // 字段突出显示
    prominentStyle() {
      const { fieldType, extAttr, columnValue } = this.column;
      const {
        inputCommon, inputNumber, inputAmount, select, date
      } = BusinessFieldType;
      // businessData对象不存在 不理解业务 2023/10/24从解构换成赋值
      const originData = this.businessData?.mdData?.originData;
      const styleObj = {};
      if (extAttr?.conditionStyleFlag !== "0"
        || ![inputCommon, inputNumber, inputAmount, select, date].includes(fieldType)) {
        return styleObj;
      }
      // if ([BusinessDataOpenType.add, BusinessDataOpenType.edit].includes(this.businessData.openType) || extAttr?.conditionStyleFlag !== "0"
      //   || ![inputCommon, inputNumber, inputAmount, select, date].includes(fieldType)) {
      //   return styleObj;
      // }
      const compareFlag = extAttr.conditionStyleCompare.some((condition) => condition.items.every((item) => {
          // "valueType": "3-指定字段，30-自定义文本，4-系统当前时间，40-指定时间"
        const { perator, rightField, valueType } = item;
        let targetValue = valueType === "30" ? item.rightValue : originData[rightField];
        if ([inputCommon, inputNumber, inputAmount].includes(fieldType)) {
          if (["3", "4"].includes(perator)) {
            const compare = String(columnValue).indexOf(String(targetValue));
            return perator === "3" ? compare !== -1 : compare === -1;
          }
            return peratorFun(Number(columnValue), Number(perator), Number(targetValue));
        }
        if (select === fieldType) {
          targetValue = targetValue || {};
          // 自定义取value 指定字段取id
          const valueKey = valueType === "30" ? "value" : "id";
          const compareValue = (Array.isArray(targetValue) ? targetValue.map((item) => item[valueKey]).sort((a, b) => a - b)
            : targetValue[valueKey]?.split(",").sort((a, b) => a - b)) || [];
          // 等于
          if (perator === "1") {
            return columnValue?.id === compareValue.join(",") || compareValue.includes(columnValue?.id);
          } if (perator === "2") {
            return columnValue?.id !== compareValue.join(",") && !compareValue.includes(columnValue?.id);
          }
        }
        if (date === fieldType) {
          const targetValue = valueType === "3" ? originData[rightField] : valueType === "4" ? Date.now() : item.rightValue;
          return peratorFun(dayjs(columnValue).format(dayJsTimeFormat[this.column.timeFormat]), Number(perator), targetValue);
        }
        return false;
      }));
      if (compareFlag) {
        const { conditionStyleConfig: { isBold, isUnderline, specialColor } } = extAttr;
        if (isBold === "1") {
          styleObj["font-weight"] = "bold";
        }
        if (isUnderline === "1") {
          styleObj["text-decoration"] = "underline";
        }
        styleObj.color = specialColor;
      }
      return styleObj;
    },
    showFormItemName() {
      if (!this.shouldShowLeftFormName) {
        return "";
      }

      return this.column.name;
    },
    // 行展示的宽度控制
    columnStyleWidth() {
      let lengthStyle = "";
      if (this.column.isBelongToAreaColumn) {
        // 区域里面是垂直flex,不能自动展开
        lengthStyle = "";
      } else if (!this.column.fieldLengthType || enEqual(this.column.fieldLengthType, "1")) {
        lengthStyle = "flex:1;";
      } else {
        const config = this.column.fieldLengthType.split(",");
        const widthType = config[0];
        let length = "";
        switch (widthType) {
          case "2":
            length = `${config[1]}%`;
            break;
          case "3":
            length = `${config[1]}px`;
            break;
          default:
            // 不会执行
            length = "";
        }
        lengthStyle = `flex-shrink: 0;
          flex-grow: 0;
          flex-basis:${length};
          min-width: calc(${length} - 20px);`;
      }

      // 图片合成中的字段需要定位  只需要应用于最外层 el-form-item
      let positionStyle = "";
      if (this.column.isTextComposeColumn && this.column.position && Object.keys(this.column.position).length) {
        const { left, top } = this.column.position;
        positionStyle = `position:absolute;
        left:${left + 10}px; 
        top:${top + 5}px;
        `;
      }
      // const positionPreFixAlign = `text-align: ${this.column.preFixAlign};`;
      return lengthStyle + positionStyle;
    },
    // 左侧的占用区域
    leftColummNameLength() {
      // return "200px";

      if (!this.shouldShowLeftFormName) {
        return "0";
      }
      let lengthStyle = "";
      if (!this.column.showPrefixLength || this.column.showPrefixLength === "1") {
        lengthStyle = "auto";
      } else {
        const config = this.column.showPrefixLength.split(",");
        lengthStyle = `${config[1]}%`;
      }
      return lengthStyle;
    },
    // 是否需要展示控件左侧的标签名称,根据UI和配置来确定.
    shouldShowLeftFormName() {
      if (this.column.showPrefix !== 0) {
        return false;
      }
      // 明细 子集区字段并且配置为列表式 不展示
      if ((this.column.belongSectionType === SectionType.detail || this.column.belongSectionType === SectionType.subset)) {
        // 3才是列表式
        if (enEqual(this.column.parentNode.parentNode.showStyle, 3)) {
          return false;
        }
      }
      // 子项数据在表格中也不展示
      if (this.businessData.dataLevel === 1) {
        return false;
      }
      // 关联数据不显示
      if (this.column.fieldType === BusinessFieldType.associatedData) {
        return false;
      }
      return this.isCommonColumn;
    },
    // 几种特殊的字段 有公共的css或者逻辑处理 高度固定,选中固定等
    isCommonColumn() {
      switch (this.column.fieldType) {
        case BusinessFieldType.pureText:
        case BusinessFieldType.detailParent:
        case BusinessFieldType.shouFu:
        case BusinessFieldType.multiTab:
        case BusinessFieldType.newProgress:
        case BusinessFieldType.mileStone:
        case BusinessFieldType.subItem:
        case BusinessFieldType.line:
        case BusinessFieldType.cashObject:
        case BusinessFieldType.textComposition:
        case BusinessFieldType.seeFlow:
          return false;
        default:
          return true;
      }
    },
    fromItemStyle() {
      return {
        "text-align": this.column.preFixAlign
      };
    },
    // align控制
    columnAlignment() {
      const alignMap = {
        1: "left",
        2: "center",
        3: "right"
      };
      return alignMap[this.column.preFixAlign] || "left";
    },
    // 后缀展示
    columnSuffix() {
      switch (this.column.fieldType) {
        case BusinessFieldType.inputAmount: {
          if (this.column.suffix === "1") {
            return "元";
          }
          if (this.column.suffix === "2") {
            return "万元";
          }
          return "";
        }
        case BusinessFieldType.inputNumber:
          return this.column.suffix;
        default:
          return "";
      }
    }
  }
};

export default MxColumnData;
