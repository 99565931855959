var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("relation-dialog", {
        ref: "relationDialog",
        attrs: {
          relationData: _vm.validatorData,
          fieldList: _vm.relationFieldList,
        },
      }),
      _c(
        "el-tooltip",
        { attrs: { disabled: _vm.isValid, placement: "bottom" } },
        [
          _c("div", {
            attrs: { slot: "content" },
            domProps: { innerHTML: _vm._s(_vm.formatControlMessage) },
            on: { click: _vm.openDialog },
            slot: "content",
          }),
          _vm.isLinePercent || _vm.isCirclePercent
            ? _c("el-progress", {
                staticClass: "bs-progress",
                style: { top: _vm.isLinePercent ? "7px" : "" },
                attrs: {
                  percentage: _vm.column.showValue
                    ? Number(_vm.column.showValue)
                    : 0,
                  type: _vm.isLinePercent ? "line" : "circle",
                  "stroke-width": _vm.isLinePercent ? 12 : 5,
                  width: 40,
                  color: _vm.column.extAttr.percentageStyleColor,
                },
              })
            : _vm.column.isConnectBySkyEye
            ? _c(
                "el-autocomplete",
                {
                  class: {
                    complete: _vm.column.isComplete && _vm.column.textValue,
                    "bm-complete": true,
                  },
                  style: _vm.bindStyle,
                  attrs: {
                    "fetch-suggestions": _vm.querySkyEyeSearch,
                    "trigger-on-focus": false,
                    maxlength: _vm.column.maxLength,
                    value: _vm.column.textValue,
                    title:
                      _vm.column.showFlag && _vm.column.showFlag.length > 0
                        ? ""
                        : _vm.column.showValue,
                    disabled: !_vm.column.editable,
                    placeholder: _vm.column.showPlaceholder,
                  },
                  on: {
                    input: _vm.handleInput,
                    change: _vm.handleValueChange,
                    select: _vm.handleValueChange,
                  },
                  model: {
                    value: _vm.column.showValue,
                    callback: function ($$v) {
                      _vm.$set(_vm.column, "showValue", $$v)
                    },
                    expression: "column.showValue",
                  },
                },
                [
                  _vm.rightAdditionButtons.length > 0
                    ? _c("right-addition", {
                        attrs: {
                          slot: "append",
                          column: _vm.column,
                          "row-index": _vm.rowIndex,
                          "business-data": _vm.businessData,
                        },
                        slot: "append",
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm.isRenderingText || _vm.isRenderingMultiText
            ? _c(
                "el-input",
                {
                  ref: _vm.column.field,
                  class: {
                    complete: _vm.column.isComplete && _vm.column.textValue,
                    "invalid-input": !_vm.isValid,
                    "click-jump": _vm.canJumpToUrl,
                  },
                  style: {
                    ..._vm.bindStyle,
                    ..._vm.inputStyle(_vm.column.field),
                  },
                  attrs: {
                    id: _vm.column.field,
                    type: _vm.isRenderingMultiText ? "textarea" : "",
                    autosize: true,
                    maxlength: _vm.column.maxLength,
                    value: _vm.column.showValue,
                    title:
                      _vm.column.showFlag && _vm.column.showFlag.length > 0
                        ? ""
                        : _vm.column.showValue,
                    disabled: !_vm.column.editable,
                    placeholder: _vm.column.showPlaceholder,
                  },
                  on: { input: _vm.handleInput, change: _vm.handleValueChange },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.handleInuptClick.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm.rightAdditionButtons.length > 0
                    ? _c("right-addition", {
                        attrs: {
                          slot: "append",
                          column: _vm.column,
                          "row-index": _vm.rowIndex,
                          "business-data": _vm.businessData,
                        },
                        slot: "append",
                      })
                    : _vm._e(),
                ],
                1
              )
            : (_vm.isRenderingNumber || _vm.isRenderingAmount) &&
              _vm.column.isHiddenValueState
            ? _c(
                "el-input",
                {
                  ref: _vm.column.field,
                  class: {
                    complete: _vm.column.isComplete && _vm.column.textValue,
                  },
                  style: {
                    ..._vm.bindStyle,
                    ..._vm.inputStyle(_vm.column.field),
                  },
                  attrs: {
                    autosize: true,
                    id: _vm.column.field,
                    maxlength: _vm.column.maxLength,
                    value: _vm.column.showValue,
                    title:
                      _vm.column.showFlag && _vm.column.showFlag.length > 0
                        ? ""
                        : _vm.column.showValue,
                    disabled: !_vm.column.editable,
                    placeholder: _vm.column.showPlaceholder,
                  },
                  on: { input: _vm.handleInput },
                },
                [
                  _vm.rightAdditionButtons.length > 0
                    ? _c("right-addition", {
                        attrs: {
                          slot: "append",
                          column: _vm.column,
                          "row-index": _vm.rowIndex,
                          "business-data": _vm.businessData,
                        },
                        slot: "append",
                      })
                    : _vm._e(),
                ],
                1
              )
            : (_vm.isRenderingNumber || _vm.isRenderingAmount) &&
              !_vm.column.isHiddenValueState
            ? _c(
                "en-input-number",
                {
                  ref: "inputNumber",
                  class: {
                    "invalid-input": !_vm.isValid,
                    "has-suffix": _vm.column.suffix.length > 0,
                    "suffix-2": _vm.showSuffix.length === 2,
                  },
                  style: {
                    ..._vm.bindStyle,
                    ..._vm.inputStyle(_vm.column.field),
                  },
                  attrs: {
                    id: _vm.column.field,
                    placeholder: _vm.column.showPlaceholder,
                    disabled: !_vm.column.editable,
                    precision: _vm.showReserverDecimal,
                    thousandFormat:
                      _vm.column.showThousands == "0" ? true : false,
                    max: _vm.column.numberAndMoneyMaxInput,
                    min: -_vm.column.numberAndMoneyMaxInput,
                    isTabEdit: _vm.isTabEdit,
                  },
                  on: {
                    focus: _vm.handleFocus,
                    blur: function ($event) {
                      _vm.editing = false
                    },
                    input: _vm.numberMoneyInput,
                    change: _vm.handleValueChange,
                  },
                  model: {
                    value: _vm.column.showValue,
                    callback: function ($$v) {
                      _vm.$set(_vm.column, "showValue", $$v)
                    },
                    expression: "column.showValue",
                  },
                },
                [
                  _vm.column.suffix.length > 0
                    ? _c("template", { slot: "suffix" }, [
                        _vm._v(" " + _vm._s(_vm.showSuffix) + " "),
                      ])
                    : _vm._e(),
                  _vm.rightAdditionButtons.length > 0
                    ? _c("right-addition", {
                        attrs: {
                          slot: "append",
                          column: _vm.column,
                          "row-index": _vm.rowIndex,
                          "business-data": _vm.businessData,
                        },
                        slot: "append",
                      })
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }