var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "模板文件",
        visible: _vm.visible,
        "destroy-on-close": "",
        width: "900px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "file-list",
        {
          staticClass: "my-folder",
          attrs: {
            "file-list": _vm.fileList,
            loading: _vm.loading,
            hasDelete: false,
            "show-no-data": "",
          },
          on: { change: _vm.handleCheckChanged },
        },
        [
          _vm._t("default", function () {
            return [
              _c(
                "en-button",
                {
                  staticClass: "ok-btn",
                  attrs: {
                    disabled: !_vm.selectFiles || !_vm.selectFiles.length,
                  },
                  on: { click: _vm.handleOKButton },
                },
                [_vm._v("确定")]
              ),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }