let timer = 0;
const newdebounce = (callback, delay = 1000) => {
    timer !== 0 ? window.clearTimeout(timer) : 0;
    timer = window.setTimeout(() => {
        callback && callback();
    }, delay);
};
export {
    newdebounce
};
