/*
 * @Author: pengyu
 * @Date: 2020-07-28 18:51:14
 * @LastEditors: pengyu
 * @LastEditTime: 2020-12-29 17:09:03
 * @Description: 签到控件
 */

import BaseColumn from "../base/BaseColumn";

export default class SignColumn extends BaseColumn {
  get disableBtn() { // 已签到 且只可 签到一次  则不能再签到
    return this.isSignIn && this.signInSecond === 1;
  }

  get hasBillStatus() { // 提交后 才可以签到
    const { rootNode } = this;
    return !(rootNode.mdData?.originData?.billStatus === "000" || !rootNode.mdData?.originData?.billStatus);
  }
}
