<template>
  <el-dialog
    title="Word文件"
    :visible.sync="visible"
    destroy-on-close
    width="900px">
    <file-list :file-list="fileList" @change="handleCheckChanged" :loading="loading" show-no-data class="my-folder">
      <slot>
        <en-button @click="handleOKButton" class="ok-btn" :disabled="!selectFiles || !selectFiles.length">确定</en-button>
      </slot>
    </file-list>
  </el-dialog>
</template>

<script>
import { request } from "en-js";
import { businessService } from "@/api/businessModel";
import FileList from "../views/fileList";
import Index from "../data/utils";

export default {
  name: "MyWord",
  components: { FileList },
  props: {
    params: Object, // 请求参数
    defaultSelects: Array// 默认选中
  },
  data() {
    return {
      loading: false,
      visible: false,
      fileList: undefined,
      selectFiles: undefined
    };
  },
  created() {
  },
  methods: {
    open() {
      if (!this.fileList) {
        this.requestData();
      }
      this.visible = true;
    },
    @request(true, "loading")
    async requestData() {
      // templateId: 308419559143051264
      // field: m2039
      // type: 3
      // mdDataStr: {"name":"131313","ownerId":[{"id":"217020236642123776","name":"徐大大"}]}
      const res = await businessService.mdGetProduceContent(this.params);
      this.fileList = res?.records || [];
      Index.processInvalidData(this.fileList);
      this.fileList.splice(this.fileList.length);
    },
    handleCheckChanged(selectFiles) {
      this.selectFiles = selectFiles;
    },
    handleOKButton() {
      this.$emit("change", this.selectFiles);
      this.visible = false;
    }
  }
};
</script>

<style scoped lang="scss">
  .my-folder {
    height: 500px;
    .ok-btn {
      width: 70px;
    }
  }
</style>
