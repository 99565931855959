/*
 * @Description: 说明控件
 * @Date: 2020-04-27 11:14:25
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-02-29 10:20:22
 */
import BaseColumn from "../base/BaseColumn";

export default class InstructionColumn extends BaseColumn {
  get checkCouldSubmit() {
    // 没有填写不校验其它条件,但是要校验必填
    if (this.required) {
      if (!this.captionDescription.length) {
        return new Error(`${this.name}不能为空`);
      }
    }
    return true;
  }

  // 提交参数
  get paramsForSubmit() {
    return {
      [this.field]: this.captionDescription.replace(/&lt;/g, "<").replace(/&gt;/g, ">").replace(/\\"/g, "\"").replace(/；/g, ";")
    };
  }

    // 更新值
    updateValueWithOptions(value, opts) {
      this.captionDescription = value;
    }
}
