/*
 * @Description: 区域模型, 基础数据包含区域, 业务建模也包装一层
 * @Author: 梁平贤
 * @LastEditors: 梁平贤
 * @Date: 2019-07-09 18:18:50
 * @LastEditTime: 2020-06-18 14:39:34
 */

import BaseNode from "../columns/base/BaseNode";

export default class BaseSection extends BaseNode {
  constructor(data) {
    super(data || {});
  }

  // 存储布局信息的 主表区需要用到
  areas = [];

  // 打平的字段信息
  fields = [];

  // 类型 主表区,明细区,子集区
  sectionType
}
