/*
 * @Description: 明细区
 * @Author: 梁平贤
 * @LastEditors: Please set LastEditors
 * @Date: 2019-07-09 18:18:50
 * @LastEditTime: 2024-02-27 11:37:26
 */
// import _ from "lodash";
import { cloneDeep, isObject, isArray } from "lodash";
import { Message } from "element-ui";
import { enBooleanReverse } from "@/tools/compare";
import BaseColumn from "../base/BaseColumn";
/* eslint import/no-cycle: [2, { maxDepth: 1 }] */
import * as BusinessColumnProcess from "../../parse/BusinessColumnProcess";
import BaseSection from "../../section/BaseSection";
import { SectionType, AssignmentOptions, BusinessFieldType } from "../../BusinessCommonHeader";

export default class DetailColumn extends BaseColumn {
  subDetails = [];

  loadDatas = [];

  lineId = 0;

  constructor(fields) {
    super(fields);
    this.tableLoading = false;
    this.originData = fields;
    // 所有的明细字段包含子集,只有模板,用于复制.
    this.sub = BusinessColumnProcess.parsingColumns(fields.sub, this);
    // 写死,因为sub不在页面展示的
    this.sub.forEach((column) => { column._belongSectionType = SectionType.detail; });
    // 默认生成一行,这个还需要根据数据进行修改
    // this.createDetails();
  }

  // 是否是卡片式 卡片式有子集时默认改为列表式
  get IsCardType() {
    return this.showStyle === "1" && this.sub.find((item) => item.fieldType === BusinessFieldType.subSet) === undefined;
  }

  // 创建明细
  createDetails(count = (Number(this.lineNum) || 1)) {
    // count 默认获取明细默认行数
    for (let i = 0; i < count; i++) {
      this.subDetails.push(this.createOneLineData());
    }
  }

  findFirstEnRelateField(fields) {
    const result = fields.find((item) => [
                            BusinessFieldType.inputCommon,
                            BusinessFieldType.multiText,
                            BusinessFieldType.inputNumber,
                            BusinessFieldType.select,
                            BusinessFieldType.date,
                            BusinessFieldType.inputAmount].includes(item.fieldType));
    return result?.uuid;
  }

  createOneLineData(sub) {
    const detailLineSection = new BaseSection();
    const fields = sub || this.sub || this.originData.sub;
    detailLineSection.sectionType = SectionType.detail;
    detailLineSection.lineId = ++this.lineId;
    detailLineSection.parentNode = this;
    if (this.relatedPlanFlag === 0) { // 关联字段 关联数据 uuid 支持单行、多行、数值、金额、日期、单选
      detailLineSection.penetrateField = this.penetrateField || this.findFirstEnRelateField(fields);
    }
    console.log("this.penetrateField || this.findFirstEnRelateField(fields)", this.penetrateField || this.findFirstEnRelateField(fields));
    detailLineSection.fields = BusinessColumnProcess.parsingColumns(fields, detailLineSection)?.map((fieldItem) => {
      if (detailLineSection.penetrateField && detailLineSection.penetrateField === fieldItem.uuid) {
        fieldItem.isPenetrateField = true;
        fieldItem.originRelatedField = this.field;// 标记所属区域 用于关联数据取值
      }
      return fieldItem;
    });
    return detailLineSection;
  }

  cloneOneLineData(fields = []) {
    const params = {};
    fields.forEach((column) => {
      if (column.fieldType === BusinessFieldType.subSet) {
        params[column.field] = column.paramsForSubmit;
      } else {
        Object.assign(params, column.paramsForSubmit);
      }
    });
    return params;
  }

  isEmptyValue(val) {
    if (isObject(val)) {
      return Object.keys(val).every((key) => this.isEmptyValue(val[key]));
    } if (isArray(val)) {
      return val.every((v) => this.isEmptyValue(!v));
    }
    return !val;
  }

  /**
   * @description: 判断明细区是否只有一行空的默认数据 //TODO 由于数据结构的不确定 此方法可能不准确
   * @param {*}
   * @return {*}
   */
  onlyDefaultEmptyLine() {
    if (this.subDetails.length !== 1) {
      return false;
    }
    const line = this.subDetails[0];
    const lineValues = line.fields.map((column) => column.paramsForSubmit);
    const isOnlyDefault = lineValues.every((val) => this.isEmptyValue(val));
    console.log(isOnlyDefault, line, "isOnlyDefault");
    return isOnlyDefault && line;
  }

  // 明细区 保存值
  // [{ f1404: "铅笔", f1406: [{ id: "320085185968349184", name: "2007企业会计准则+一般纳税人" }] }]
  get paramsForSubmit() {
    const values = this.subDetails.map((line) => {
      let params;
      line.fields.map((column) => column.paramsForSubmit).forEach((val) => {
        if (val === undefined) return;
        params = { ...params, ...val };
      });
      if (line.refData) {
        params = params || {};
        params.refData = line.refData;
      }
      return params;
    });
    if (values.some((item) => item)) {
      let setValues = values.filter((item) => item);
      // 编辑时未改变需要获取原始数据
      const originFieldData = this.rootNode.mdData.originData[this.field];
      if (originFieldData) {
        setValues = setValues.map((item, index) => {
          const originItem = item.bankAccountNo ? originFieldData.find((field) => field.bankAccountNo === item.bankAccountNo) : originFieldData[index];
          if (originItem) {
            Object.keys(originItem).forEach((key) => {
              if (!item[key]) {
                item[key] = originItem[key];
              }
            });
          }
          return item;
        });
      }
      return {
        [this.field]: setValues
      };
    }
    return undefined;
  }
  // 功能开关

  get functionList() {
    const result = [];
    if (enBooleanReverse(this.allowAddFlag)) { // 手动新增
      result.push("allowAddFlag");
    }
    if (enBooleanReverse(this.multipleFlag)) { // 批量新增
      result.push("multipleFlag");
    }
    if (enBooleanReverse(this.completeFlag)) { // 删除
      result.push("completeFlag");
    }
    if (enBooleanReverse(this.allowConflict)) { // 复制
      result.push("allowConflict");
    }
    if (enBooleanReverse(this.detailImport)) { // 导入
      result.push("detailImport");
    }
    if (enBooleanReverse(this.relatedPlanFlag)) { // 关联数据
      result.push("relatedPlanFlag");
    }
    return result;
  }

  get checkCouldSubmit() {
    // 必填 且为空 返回err
    if (this.requrein === 0 && this.subDetails?.length === 0) {
      return new Error(`请选择${ this.name}`);
    }
    // 否则 true
    return true;
  }

  // 加载明细数据
  loadMoreDetailDatahandle() {
    let loadDatas = this.loadDatas;
    if (this.loadDatas.length > 20) {
      loadDatas = this.loadDatas.slice(0, 20);
      this.loadDatas = this.loadDatas.slice(20, this.loadDatas.length);
    } else {
      this.loadDatas = [];
    }
    this.createDetails(loadDatas.length);
    const addLen = loadDatas.length;
    for (let i = 0; i < addLen; i++) {
      const sourceData = loadDatas[i] || null;
      this.setLineValue(this.subDetails[this.subDetails.length - addLen + i], AssignmentOptions.server, sourceData);
    }
  }

  // 给明细区每一行设置默认值
  setColumnValue(type, sourceList = []) {
    if (type === AssignmentOptions.server && sourceList.length > 20) {
      this.loadDatas = sourceList.slice(20, sourceList.length);
      sourceList = sourceList.slice(0, 20);
    }
    const len = this.subDetails.length - sourceList.length;
    len < 0 && this.createDetails(Math.abs(len));
    for (let i = 0; i < this.subDetails.length; i++) {
      const sourceData = sourceList[i] || null;
      this.setLineValue(this.subDetails[i], type, sourceData);
    }
  }

  // 给指定行设置value
  setLineValue(line = {}, type = AssignmentOptions.manual, sourceData = null) {
    const { fields } = line;
    line.sid = sourceData?.id || null;
    line.refData = sourceData?.refData;
    fields?.forEach((column) => {
      // if (column.isPenetrateField && sourceData?.refData) { // 明细行数据设置关联数据
      //   line.refData = sourceData.refData;
      // }
      const value = (sourceData !== null && sourceData[column.field]) ? sourceData[column.field] : column.defaults;
      if (value || (!value && column.paramsForSubmit)) {
        column.updateValueWithOptions(value || "", type);
        // 字段值隐藏逻辑 根据配置展示******
        const flagKey = `${column.field}_ShowFlag`;
        if (sourceData) {
          const flagValue = sourceData[flagKey];
          if (flagValue) {
            column.updateServerShowFlag(flagValue);
          }
        }
      }
    });
  }

  /**
   * @description: 批量新增
   * @param {*} type 1：覆盖新增 2：依次新增&&新增
   * @param {*} nums 新增的行数
   * @param {Number} insertIndex 插入位置
   * @return {*}
   */
   batchAddLine(type, nums, insertIndex) {
    if (type === 1) {
      this.subDetails = [];
      this.updateValueWithOptions([], AssignmentOptions.manual);
    }
    for (let index = 0; index < nums; index++) {
      insertIndex !== undefined ? this.insertOneLine(insertIndex + index) : this.addOneLine();
    }
  }

  /**
   * @description:  // 新增一行
   * @param {*} opt  插入操作方式  默认手动
   * @return {*}
   */
  addOneLine(opt = AssignmentOptions.manual) {
    if (Number(this.maxNum) && this.subDetails?.length >= this.maxNum) {
      /* eslint-disable */
      Message("该明细最大支持" + this.maxNum + "行");
      return;
    }
    const line = this.createOneLineData();
    this.subDetails.push(line);
    this.setLineValue(line, AssignmentOptions.default);
    this.updateValueWithOptions([], opt);
  }
  /**
   * @description:  // 插入一行
   * @param {*} index  插入的位置，没有则是最后一行 
   * @param {*} opt 插入方式  默认手动
   * @return {*}
   */
   insertOneLine(index=0, opt = AssignmentOptions.manual) {
    if (Number(this.maxNum) && this.subDetails?.length >= this.maxNum) {
      /* eslint-disable */
      Message("该明细最大支持" + this.maxNum + "行");
      return;
    }
    const line = this.createOneLineData();
    this.subDetails.splice(index + 1, 0, line);
    this.setLineValue(line, AssignmentOptions.default);
    this.updateValueWithOptions([], opt);
  }
  deleteOneLine(index,obj = null)  {
    console.log(obj,'删除明细')
    // if(this.subDetails.length === 1 && obj == null){
    //   Message("至少需保留一行数据");
    //   return
    // }
    this.subDetails.splice(index !== -1 ? index : this.lastDelIndex, 1);
    if (index !== -1) {
      this.lastDelIndex = index;
    }
    this.updateValueWithOptions([], AssignmentOptions.manual);
    // 卡片式不需要保持一行
    if (this.subDetails.length === 0 && !this.IsCardType) {
      // this.addOneLine();
    }
  }

  // 复制一行
  copyOneLine(index) {
    console.log(this.subDetails)
    const obj = this.subDetails[index];
    const line = this.createOneLineData();
    const data = this.cloneOneLineData(cloneDeep(obj.fields));
    // 设置手动填入信息
    this.setLineValue(line, AssignmentOptions.manual, data);
    this.subDetails.splice(index + 1, 0, line);
    this.updateValueWithOptions(data, AssignmentOptions.manual, index + 1);
  }

  // 解析数据
  parseColumnDefaults(value) {
    // 部分数据 不是 string 如申请人部门
    return typeof value === "object" ? value : ((value && JSON.parse(value)) || []);
  }
  
  /**
   * 更新值
   * @param {*} value 更新值  Array 或 Object
   * @param {*} opts 更新方式
   * @param {*} index 更新明细指定行数 配合value（Object）传入  不传 默认更新整个明细  value必传(array)
   */
  updateValueWithOptions(value, opts, index) {
    
    value && (value = this.parseColumnDefaults(value));
    // 默认值赋值 || 设置value时  调用设置每一行数据
    if (opts === AssignmentOptions.default
      || (Object.prototype.toString.call(value) === "[object Array]" && value.length)) {
        if(opts === AssignmentOptions.server&&(this.showsSort=='1'||this.showsSort=='2')&&this.targetField){//showsSort 1 升序  2降序
          if(this.showsSort=='1'){
            value.sort((a,b)=>a[this.targetField]-b[this.targetField])
          }else{
            value.sort((a,b)=>b[this.targetField]-a[this.targetField])
          }
          console.log('设置排序')
        }
      this.setColumnValue(opts, value);
    } else if (index !== undefined) {
      try {
        this.setLineValue(this.subDetails?.[index], opts, value);
      } catch {
        throw new Error("调用明细updateValueWithOptions传值错误");
      }
    }
    this.triggerInputLimitWatch = Math.random();
    super.updateValueWithOptions(this.paramsForSubmit, opts);
  }

  // 根据字段是否编辑过,来返回信息 明细,子集,地址需要特殊处理 by:lpx
  get fieldEditInfo() {
    if (this.hasBeenEdit) {
      // 明细区返回的字段编辑需要重写 这里需要修改
      return super.fieldEditInfo;
    }
    return {};
  }

  // 当前column下第一栏所有子集字段   用于数据关联
  get nextLayerSameAreaFields() {
    return this.subDetails[0]?.fields || [];
  }
}
