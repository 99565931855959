/*
 * @Author: your name
 * @Date: 2020-07-24 16:49:33
 * @LastEditTime: 2024-02-21 09:49:30
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \user\src\views\businessModel\list\data\index.js
 */
/**
 * project: user
 * fileName: utils
 * Created by 徐俊 on 2020/07/03
 * Copyright © 2020 Enfry Ltd. All rights reserved.
 */
import _ from "lodash";
import { newEntranceRoute, businessListEntranceRoute, convertCurrentDetailRouteToEdit } from "@/components/businessDetail/helper";
import { BusinessFieldType } from "@/components/businessDetail/data/BusinessCommonHeader";
import API from "@/api/businessModel/list";
// import { getBusIcon } from "@/tools/iconMap";
const copyToClipboard = (str) => {
  const el = document.createElement("textarea");
  el.value = str;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  const selected = document.getSelection().rangeCount > 0
    ? document.getSelection().getRangeAt(0)
    : false;
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
  if (selected) {
    document.getSelection().removeAllRanges();
    document.getSelection().addRange(selected);
  }
};

const Utils = {
  copyToClipboard,
  cellText(row, col) {
    if (!col.field || col.field.length === 0 || !row || !(row instanceof Object)) {
      return "";
    }

    let cellData = row[col.field];
    if (!cellData || cellData.length === 0) {
      return "";
    }

    if (cellData instanceof Array) {
      const nameArray = [];
      cellData.forEach((dic) => {
        if (dic.name && dic.name.length > 0) {
          nameArray.push(dic.name);
        }
      });
      cellData = nameArray.join(",");
    } else {
      // 1 单行文本   2 多行文本  3数值 4金额  5选项（单选）6业务关联 7日期8 标识 9 附件图片  10进度 11明细 15 多选 16-纯文本控件 28基础数据  29地址 30子项 31 分割线 33所属上级 34 开票选项卡 35 子集 36 图片
      const filedType = Number(col.fieldType);
      const { inputAmount, select, date } = BusinessFieldType;
      if (filedType === inputAmount) { // 金额
        cellData = Number(cellData).toFixed(2);
      } else if (filedType === select) { // 状态
        if (Number(cellData)) {
          cellData = row[`${col.field}Name`];
        }
      } else if (filedType === date) {
        // cellData = dateFormatString(cellData, head.timeFormat);
      }
    }

    if (_.isMap(cellData)) {
      if (cellData.name) {
        cellData = cellData.name;
      }
    }
    return cellData;
  },
  buttonDatas(btnList, row, dataType) {
    const datas = [];
    // 是分享数据  业务建模
    if (!btnList || btnList.length === 0 || (row?.isShare === "1" && dataType === 2)) {
      return datas;
    }
    // 固化按钮颜色
    const blueColor = "#3e90fe";
    const greenColor = "#46d48a";
    const grayColor = "#60647d";
    const yellowColor = "#ffa32c";
    const brightYellowColor = "#a971f6";
    const redColor = "#fd635b";
    const btnColorMap = {
      1: blueColor,
      2: greenColor,
      3: grayColor,
      4: grayColor,
      5: greenColor,
      6: yellowColor,
      7: blueColor,
      8: brightYellowColor,
      9: greenColor,
      10: redColor,
      11: greenColor,
      12: yellowColor,
      13: brightYellowColor,
      14: brightYellowColor,
      15: greenColor,
      16: greenColor,
      17: greenColor,
      18: blueColor,
      19: greenColor,
      20: blueColor,
      21: redColor,
      22: greenColor,
      23: yellowColor,
      24: greenColor,
      25: yellowColor
    };
    btnList.forEach((item) => {
      const data = { ...item };
      data.icon = `bus-btn${item.icon}_1`;// getBusIcon(item.icon).hollow;
      data.iconColor = item.colour || btnColorMap[item.icon];
      if (row?.isUserLike === "000" && item.btnKey === "giveLike") {
        data.name = `取消${item.btnName}`;
        data.iconColor = "#ec5353";
      } else {
        data.name = item.btnName;
      }
      // 流程中数据 不可编辑 删除 停用 启用
      if (row) {
        data.icon = `bus-btn${item.icon}`;
        if (["001", "002", "006"].includes(row.billStatus) && ["edit", "delete", "collect", "enable", "disable"].includes(item.btnKey)) {
          return;
        }
        // 003已结束/008已提交的数据，才能有复制按钮
        if (!["003", "008"].includes(row.billStatus) && ["copy"].includes(item.btnKey)) {
          return;
        }
        if ((row.enable === "000" && data.btnKey === "enable") || (row.enable === "001" && data.btnKey === "disable")) {
          return;
        }
      }
      // 去除打印、任务按钮、预览、收藏
      const excludeBtnkeys = ["addtask", "previewPlan", "collect", "comment"];
      if (excludeBtnkeys.includes(data.btnKey)) {
        return;
      }
      datas.push(data);
    });
    console.log("datas", datas, "datas");
    return datas;
  },
  async handleButtonData($list, btnData) {
    const templateName = $list.$route?.query?.templateName;
    const viewType = $list.$route?.query?.viewType;
    switch (btnData.btnKey) {
      case "add":
        $list.$router.push(newEntranceRoute({
          templateName, templateId: btnData.templateId, businessType: btnData.type, sourcetype: "list_normal", viewType
        }));
        break;
      case "edit":
        {
          await API.mdEditObjectCheck({
            templateId: btnData.templateId,
            ids: btnData.dataId
          });
        // 基础路由再转换一次编辑路由
          let detailRoute = businessListEntranceRoute({
            templateName, id: btnData.dataId, templateId: btnData.templateId, businessType: btnData.type, sourcetype: "list_normal"
          });
          detailRoute = convertCurrentDetailRouteToEdit(detailRoute);
          $list.$router.push(detailRoute);
        }
        break;
      case "detail":// 自己定义的
        $list.$router.push(businessListEntranceRoute({
          templateName, id: btnData.dataId, templateId: btnData.templateId, businessType: btnData.type, sourcetype: "list_normal"
        }));
        break;
      case "copy":// 复制
        $list.$router.push(businessListEntranceRoute({
          templateName, copyId: btnData.dataId, templateId: btnData.templateId, businessType: btnData.type, sourcetype: "list_normal"
        }));
        break;
      default:
        $list.$message(`点击了【${btnData.name}】按钮, 图标待上传, 此处点击逻辑每个地方应是是一套逻辑, 不单独处理了`);
        break;
    }
  },
  beforeEmitClick(ctx, method, button) {
    if (button.confirmHints) {
      if (button.confirmType === 1) {
        ctx.$confirm(button.confirmHints, "", {
          confirmButtonText: "确定",
          showCancelButton: false,
          type: "warning"
        }).then(() => {
          method();
        });
      } else if (button.confirmType === 2) {
        ctx.$message.warning(button.confirmHints);
        method();
      }
    } else {
      method();
    }
  },
  exportData(response) {
    // const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8" });
    const blob = new Blob([response.data], { type: `${response.headers["content-type"]}` });
    const el = document.createElement("a");
    const href = window.URL.createObjectURL(blob);
    const fileName = `${response.headers["content-disposition"] || response.headers["Content-disposition"] || ""}`.split("filename=")[1] || "未命名";
    el.href = href;
    el.download = decodeURI(fileName);
    document.body.appendChild(el);
    el.click();
    document.body.removeChild(el);
    window.URL.revokeObjectURL(href);
  }
};

export default Utils;
