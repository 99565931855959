/**
 * project: user
 * fileName: data
 * Created by 徐俊 on 2020/08/14
 * Copyright © 2020 Enfry Ltd. All rights reserved.
 */
import { mapState } from "vuex";

/* eslint no-bitwise: ["error", { "allow": ["<<","|=","&"] }] */
// 添加附件权限
const AddPermisson = {
  none: 0, // 无新增权限
  local: 1 << 0, // 本地文件
  template: 1 << 1, // 模版文件
  folder: 1 << 2, // 我的文件夹
  word: 1 << 3// word创建
};

const Data = {
  data() {
    return {
      addPermisson: AddPermisson.none,
      fileSize: "mini",
      fileType: "file",
      limitType: 1,
      limitNum: 9999,
      isIcon: false
    };
  },
  methods: {
    getAddPermisson() {
      const busData = this.busData || {};
      const mdConfig = this.mdConfig || {};
      let permisson = AddPermisson.none;
      const { isPhotograph } = mdConfig;
      const isOnlyCamera = (isPhotograph !== undefined && Number(isPhotograph) === 0);
      if (isOnlyCamera) { // 仅允许拍照上传
        return permisson;
      }

      const { businessType } = busData;
      if (businessType === 1) { // 基础数据类型业务建模
        permisson |= AddPermisson.local;
      }

      const {
        enclosurePathLocalType, enclosurePathFileType, templateCreateFlag, produceType, wordCreateFlag
      } = mdConfig;

      if (Number(enclosurePathLocalType) === 0) { // TODO: || info.isBasicData勾选了本地上传(基础数据不修改)
        permisson |= AddPermisson.local;
      }
      if (Number(enclosurePathFileType) === 0 && this.$store.getters["main/hasFolderPermission"]) { // kModuleTypeMyFloder有权限(我的文件夹权限)
        permisson |= AddPermisson.folder;
      }

      if (Number(templateCreateFlag) === 0 && Number(produceType) !== 1) { // 勾选选择模板 (非自动生成)
        permisson |= AddPermisson.template;
      }
      if (Number(wordCreateFlag) === 0) {
        permisson |= AddPermisson.word;
      }
      return permisson;
    }
  },
  computed: {
    isReadOnly() {
      return this.mode !== "edit";
    },
    hasOnlyLocalPermisson() {
      const { mode, addPermisson } = this;
      return mode === "edit" && addPermisson === AddPermisson.local;
    },
    hasMorePermisson() {
      const { mode, addPermisson } = this;
      return mode === "edit" && (addPermisson !== AddPermisson.none && addPermisson !== AddPermisson.local);
    },
    hasLocalPermisson() {
      return this.addPermisson & AddPermisson.local;
    },
    hasTemplatePermisson() {
      return this.addPermisson & AddPermisson.template && this.pageOfficeData?.length;
    },
    hasWordPermission() {
      return this.addPermisson & AddPermisson.word && this.pageOfficeData?.length;
    },
    hasMyFolderPermission() {
      return this.addPermisson & AddPermisson.folder;
    },
    ...mapState("businessModel", ["pageOfficeData"])
  },
  watch: {
    mdConfig: {
      immediate: true,
      handler(data) {
        const {
          limitNum, limitType, limitNumType, enlargeStyle, enclosureShowStyle
        } = data || {};
        // NSInteger limitType; //限制类型，1附件和图片、2仅附件、3仅图片。默认0无配置。
        // NSString *limitNum;//按照输入框的数据来，如果没有设置则默认是99个。
        // BOOL isPhotograph;//仅允许拍照上传 0是 1否
        // BOOL isWatermark;//拍照上传添加水印  0是 1否
        // NSInteger limitNumType;//附件数量1 不限 2 单个 3 自定义 默认1不限
        // NSInteger enclosurePathType;//附件路径类型 1:本地上传、2:我的文件夹
        // NSInteger produceType;//1.自动生成 2.在线创建 3手工上传 默认3
        // BOOL wordCreateFlag;//创建标识:0勾选 1未勾选
        // BOOL templateCreateFlag;//模板创建标识:0勾选 1未勾选
        // NSInteger enclosureShowStyle;///附件显示样式 1:缩略图 2:图标 3:正方形 4：长方形 5：文件
        // BOOL enclosurePathLocalType;//本地上传 0勾选 1未勾选
        // BOOL enclosurePathFileType;//我的文件夹 0勾选 1未勾选
        // NSInteger enlargeStyle/enlargetyle;//0:无1:正方形 2:长方形
        if (Number(limitNumType) === 2) {
          this.limitNum = 1; // 单个
        } else if (Number(limitNumType) === 3) {
          this.limitNum = Number(limitNum); // 自定义
        }
        // 这里做了修改  用两个字段判断所有类型 limitType 1附件和图片、2仅附件、3仅图片
        // enclosureShowStyle;///附件显示样式 1:缩略图 2:图标 3:正方形 4：长方形 5：文件
        this.limitType = limitType;
        this.addPermisson = this.getAddPermisson();
        if (this.limitType === 3) {
          this.fileType = "image";// 这里按需求的意思是 全部图片才是图片, 否则以文件显示
        }
        if (Number(enclosureShowStyle) === 3) { //  1:缩略图 2:图标 3:正方形 4：长方形 5：文件
          this.fileSize = "small";
        } else if (Number(enclosureShowStyle) === 4) {
          this.fileSize = "medium";
        } else if ([1, 2, 5].includes(enclosureShowStyle)) {
          this.fileSize = "mini";
        }
      }
    }
  }
};

export default Data;
