/*
 * @Description: 接口功能,文本和关联接口都需要调用,独立出来
 * @Author: 梁平贤
 * @Date: 2020/8/20 10:10
 */

import { request } from "en-js";
import { mapMutations, mapGetters } from "vuex";
import { businessService, btnService } from "@/api/businessModel";
import { corporateService } from "@/api";
import { BillStatus } from "@/tools/classes/process/ProcessHeader";
import { upload } from "@/tools/util";
import { BusinessDataOpenType } from "../../../../data/BusinessCommonHeader";

export default {
  computed: {
    ...mapGetters(["urlParam"])
  },
  methods: {
    ...mapMutations("businessModel", ["saveSkyEyeBusinessData"]),
    // 触发ocr功能
    triggerOcrCommand() {
      let el = this.$refs.fileInput;
      // 关联接口可能是个数组????
      if (el instanceof Array) {
        el = el[0];
      }
      el.dispatchEvent(new MouseEvent("click"));
    },
    // ocr
    handleOcrClick(event) {
      this.handleOCRInterfaceButtonClickWithImage(event);
    },
    async uploadAttachment(file) {
      return new Promise((resolve) => {
        upload({
          file,
          action: this.urlParam.server,
          filename: "storage_file",
          data: {
            ...this.urlParam
          },
          onSuccess: (e) => {
            if (e.code === "0") {
              resolve([{
                code: e.data.fileCode,
                url: e.data.fileUrl,
                name: file.name,
                type: "000"
              }]);
            } else {
              resolve([]);
            }
          }
        });
      });
    },
    @request()
    // OCR接口
    async handleOCRInterfaceButtonClickWithImage(event) {
      // 图片转换base64提交后台
      const file = event.target.files[0];
      const image = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function onload() {
          let base64Img = this.result;
          base64Img = base64Img.substring(base64Img.indexOf(",") + 1, base64Img.length);
          resolve(base64Img);
        };
      });
      event.target.value = "";
      // 是否有附件，有附件的话先赋值附件
      const targetColumn = this.column.findColumnByField(this.column.extAttr.ocrSavePicField);
      if (targetColumn) {
        const fileData = await this.uploadAttachment(file);
        targetColumn?.updateValueWithOptions(fileData);
      }
      const params = {
        interfaceId: this.column.refInterfaceId,
        businessData: JSON.stringify({
          image
        })
      };
      const res = await businessService.queryOtherInterface(params);
      this.column.writeOCRDataToColumns(res);
    },
    @request(false)
    // 其它接口
    async handleOtherInterfaceButtonClick() {
      const params = this.businessData.interfaceParamsForSubmit(this.column.refInterfaceId);
      const res = await businessService.queryOtherInterface(params);
      this.column.writeOCRDataToColumns(res);
    },

    // 天眼查功能
    async handleEyeSkyButtonClick() {
      if (!this.column.skyEyeColumn) {
        this.$message("接口配置错误");
        return;
      }
      // 请求天眼查的name.
      const name = this.column.skyEyeColumn.textValue;
      if (!name?.length) {
        // name没填
        this.$message(`企业名称不能为空,请先填写字段"${this.column.skyEyeColumn.name}"`);
        return;
      }
      // 查询天眼查权限 接口找不到 以后需要再改
      const res = await businessService.querySkyEyeAccess();
      if (res.result !== 0) {
        this.$message.warning("没有权限，请联系管理员");
        return;
      }
      // 企业征信获取需要提交的数据,将数据存入store,然后跳转入企业征信
      // 如果是未提交的单据,要先提交一次,也就是可以暂存的先暂存一次
      if (this.businessData.mdInfo.billStatus === BillStatus.unCommit || this.businessData.openType === BusinessDataOpenType.add) {
        try {
          this.$showLoading();
          const params = this.businessData.paramsForSubmit({ isSave: true });
          const datas = await btnService.mdSubmitData(params);
          this.businessData.id = datas.id;
        } catch {
          return;
        } finally {
          this.$hideLoading();
        }
      }
      const params = this.businessData.interfaceParamsForSubmit(this.column.refInterfaceId);
      // 配置了天眼查按钮
      if (this.column.isConnectBySkyEyeBtn) {
        this.saveSkyEyeBusinessData(params);
        // 获取关联的字段的值,传入企业征信
        const currentQueryString = encodeURI(JSON.stringify(this.column.rootNode.businessParams));
        //  这里跳转企业征信,最好用replace方法
        this.$router.replace({
          path: "/corporate-credit/home",
          query: {
            name,
            business: currentQueryString,
            billStatus: "000"
          }
        });
      } else {
        // 字段->高级->关联接口->接口类型选择了企业征信时
        const pa = {
          tycType: 365,
          aginSel: 1,
          saveType: "2",
          interfaceId: params.interfaceId,
          businessData: params.businessData,
          reqTycData: JSON.stringify({
            name
          })
        };
        const notifyInstance = this.$notify.info({
          title: "提示信息",
          message: "正在查询天眼查数据,请耐心等待..."
        });
        try {
          await corporateService.queryTYC(pa);
          notifyInstance.close();
          this.$message.success("查询并返回数据成功！");
          setTimeout(() => {
            this.$router.replace({
              path: "/businessModel/detail",
              query: {
                ...this.$route.query,
                id: this.businessData.id
              }
            });
          }, 1000);
        } catch (error) {
          console.log(error);
          notifyInstance.close();
        }
      }
    }
  }
};
