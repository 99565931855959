/*
 * @Author: pengyu
 * @Date: 2020-07-31 10:51:44
 * @LastEditors: wuqi
 * @LastEditTime: 2021-07-14 18:30:46
 * @Description: 附件控件
 */
import BaseColumn from "../base/BaseColumn";

// 附件
export default class AttachmentColumn extends BaseColumn {
  columnValue = [];

  // 是否能提交数据,校验
  get checkCouldSubmit() {
    // 没有填写不校验其它条件,但是要校验必填
    if (this.required) {
      if (!this.columnValue.length) {
        return new Error(`${this.name}不能为空`);
      }
    }
    // 超出数量限制
    if (this.limitNum > 0 && this.columnValue.length > this.limitNum) {
      return new Error(`${this.name}上传数量不能超过${this.limitNum}个`);
    }
    return true;
  }

  // 提交参数
  get paramsForSubmit() {
    if (this.columnValue.length) {
      return {
        [this.field]: this.columnValue
      };
    }
      return undefined;
  }

  /**
   * @description: 赋值
   * @param {type}
   * @return:
   */
  updateValueWithOptions(value, opts) {
    this.columnValue = value || [];
    super.updateValueWithOptions(value, opts);
  }
}
