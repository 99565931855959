/*
 * @Author: pengyu
 * @Date: 2021-02-02 15:57:41
 * @LastEditors: wuqi
 * @LastEditTime: 2021-08-10 16:48:22
 * @Description: 此minxin 为时间控件（时间区间）的快捷选项设置 需要使用快捷选项的 引入此minxins
 *              并在使用en-date-picker的组件中增加 :picker-options属性  以及调用查询全局变量的方法
 */

import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import { queryGlobalVarible } from "@/tools/util";

// console.log(quarterOfYear);
dayjs.extend(quarterOfYear);

export default {
  data() {
    return {
      pickerOptions: {}
    };
  },
  methods: {
    /**
     * @description 查询时间区间 全局变量
     * */
    async queryGlobalVarible() {
      const rsp = await queryGlobalVarible(7, 3);
      const shortcuts = [];
      rsp.forEach((itm) => {
        shortcuts.push(this.getPickerOptions(itm));
      });
      if (shortcuts.length) {
        this.pickerOptions.shortcuts = shortcuts;
      }
    },
    /**
     * @description 获取每个变量的点击回调事件
     * */
    getPickerOptions(item) {
      const id = Number(item.id);
      const res = { text: item.name || "" };
      let start;
      let end;
      switch (id) {
        case 1: // 当天
          {
            start = dayjs().startOf("day");
            end = dayjs().endOf("day");
            break;
          }
        case 2: // 前一天
          {
            start = dayjs().subtract(1, "day").startOf("day");
            end = dayjs().subtract(1, "day").endOf("day");
            break;
          }
        case 3: // 本周
          {
            start = dayjs().startOf("week").add(1, "day");
            end = dayjs().endOf("week").add(1, "day");
            break;
          }
        case 4: // 上周
          {
            start = dayjs().startOf("week").subtract(6, "day");
            end = dayjs().endOf("week").subtract(6, "day");
            break;
          }
        case 5: // 本月
          {
            start = dayjs().startOf("month");
            end = dayjs().endOf("month");
            break;
          }
        case 6: // 上月
          {
            start = dayjs().subtract(1, "month").startOf("month");
            end = dayjs().subtract(1, "month").endOf("month");
            break;
          }
        case 7: // 本年
          {
            start = dayjs().startOf("year");
            end = dayjs().endOf("year");
            break;
          }
        case 8: // 去年
          {
            start = dayjs().subtract(1, "year").startOf("year");
            end = dayjs().subtract(1, "year").endOf("year");
            break;
          }
        case 9: // 最近7天
          {
            start = dayjs().subtract(6, "day").startOf("day");
            end = dayjs().endOf("day");
            break;
          }
        case 10: // 最近30天
          {
            start = dayjs().subtract(29, "day").startOf("day");
            end = dayjs().endOf("day");
            break;
          }
        case 14: // 本季
          {
            start = dayjs().startOf("quarter");
            end = dayjs().endOf("quarter");
            break;
          }
        case 23: // 上季
          {
            start = dayjs().subtract(1, "quarter").startOf("day");
            end = dayjs().subtract(1, "quarter").endOf("quarter").endOf("day");
            break;
          }
        default: // 自定义
          {
            let vl;
            try {
                vl = item.valueList[0].value || "";
                start = dayjs(vl.split(",")[0]);
                end = dayjs(vl.split(",")[1]);
            } catch (e) {
                console.log("全局变量格式无法解析：");
                console.log(item);
            }
            break;
          }
      }

      res.onClick = (picker) => {
        let resStart;
        let resEnd;
        start && (resStart = start.format("YYYY-MM-DD HH:mm:ss"));
        end && (resEnd = end.format("YYYY-MM-DD HH:mm:ss"));
        picker.$emit("pick", [resStart, resEnd]);
      };
      return res;
    }
  }
};
