<!--
 * @Descripttion: 辅助选择
 * @Author: 彭博
 * @Date: 2020-07-17 11:18:52
 * @LastEditors: pengyu
 * @LastEditTime: 2021-07-21 14:04:56
-->
<template>
  <div class="assist-preview">
    <en-dialog :visible="showDialog" title="辅助信息" en-body-scroll append-to-body @close="showDialog = false" >
      <en-table
        ref="assistTable"
        :data="assistList"
        v-en-loading="loading"
        :select-config="{ checkRowIds: defaultCheck, checkRowId: defaultCheck, reserve: true }"
        height="400"
        @filter-sort-change="handleTableFilterChange"
        @select-change="handleSelectChange"
      >
        <en-table-column type="index-selection" v-if="column.isMultiSelect" width="50"></en-table-column>
        <en-table-column type="radio" v-else width="50"></en-table-column>
        <en-table-column
          v-for="(item, index) in column.assistSelect"
          :key="index"
          :title="item.name"
          :field="item.field"
          :filter-sort-config="{ sortable: true, filterable: true, sortKey: 'sort' }"
          :filter-render="{ name: 'ElInput', attrs: { placeholder: '请输入名称...' } }"
        >
          <template slot-scope="scope">
            {{ typeof scope.row[item.field] === "object" ? scope.row[item.field][0].name : scope.row[item.field] }}
          </template>
        </en-table-column>
      </en-table>
      <div class="assist-dialog-footer">
        <div class="footer-left">
          <div class="label">已选<span class="count">{{ checkResult.length }}</span>条数据</div>
          <div class="clear" @click="clearSelection">清空</div>
        </div>
        <div class="footer-right">
          <en-pagination :page-model="pageModel" @change="handlePageChanged"></en-pagination>
          <en-button @click="confirmCheck" class="assist-btn">
            确定
          </en-button>
        </div>
      </div>
    </en-dialog>
  </div>
</template>

<script>
import { request } from "en-js";
import { selectService } from "@/api";
import { AssignmentOptions } from "../../../../data/BusinessCommonHeader.js";

export default {
  name: "AssistSelect",
  componentName: "AssistSelect",
  props: {
    column: {
      type: Object,
      require: true,
      default() {
        return {};
      }
    },
    // 可能需要的整体数据
    businessData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      loading: false,
      isLoad: false,
      assistList: [], // 列表数据
      checkResult: [], // 选中内容
      defaultCheck: [], // 默认选中 组件有BUG 暂时无法使用
      pageModel: {
        pageNo: 1,
        pageSize: 10,
        total: null,
        totalPages: null
      },
      showDialog: false // open dialog
    };
  },
  watch: {
    showDialog: {
      handler(bool) {
        if (bool) {
          if (this.isLoad) {
            this.setDefaultCheckValue();
          } else {
            this.getAssistContent();
          }
        }
      }
    }
  },
  methods: {
    // page事件
    handlePageChanged(data) {
      Object.assign(this.pageModel, data);
      this.getAssistContent();
    },
    // 筛选
    handleTableFilterChange({ filterData, sortData }) {
      this.pageModel.pageNo = 1;
      this.filterFields = filterData;
      this.sortField = sortData;
      this.getAssistContent();
    },
    // 显示弹窗
    handleClick() {
      this.showDialog = true;
    },
    // check row
    handleSelectChange({ selection }) {
      this.checkResult = selection;
    },
    handleColumnFilterChange(e) {
      console.log("handle column filter change", e);
    },
    // 弹窗确定
    confirmCheck() {
      // 单选 不允许为空
      if (this.checkResult?.length === 0 && !this.column.isMultiSelect) {
        this.$message("请选择列表数据");
        return;
      }
      const checkResult = this.checkResult;
      console.log("选中结果", this.checkResult, checkResult);
      this.column.updateValueWithOptions(checkResult, AssignmentOptions.manual);
      this.showDialog = false;
    },
    // 获取辅助自动内容
    @request(true, "loading")
    async getAssistContent() {
      const column = this.column;
      const { businessParams } = this.businessData;
      const params = {
        type: column.areaObjType === "002" ? "002" : null,
        areaObjTypeId: column.areaObjTypeId,
        areaObjType: column.areaObjType,
        choiceRule: column.choiceRule,
        templateId: businessParams.templateId,
        field: column.field,
        version: column.version, // column.version,
        commonSet: JSON.stringify({ relationSet: {} }), // field: column.field
        filterFields: this.filterFields || JSON.stringify([]),
        sortField: this.sortField || JSON.stringify({})
      };
      const res = await selectService.getAssist(Object.assign(params, this.pageModel));
      const assistList = res.records || [];
      this.pageModel.total = res.total;
      this.pageModel.totalPages = res.totalPages;
      console.log(this.pageModel.total, res.total);
      this.assistList = assistList;
      this.isLoad = true;
      this.setDefaultCheckValue();
    },
    // 设置默认值
    setDefaultCheckValue() {
      const value = this.column.columnValue;
      this.checkResult = this.matchValue(this.column.isMultiSelect ? value : [value]);
      this.defaultCheck = this.checkResult.map((item) => item.id);
      this.$nextTick(() => {
        this.$refs.assistTable?.setAllSelection(false);
        this.$refs.assistTable?.handleDefault();
      });
    },
    matchValue(value) {
      // 单选为空 return []
      if (!this.column.isMultiSelect) {
        if (!value || JSON.stringify(value) === "{}") return [];
      }
      return value.filter((item) => this.assistList.find((row) => row.id === item?.id));
    },
    // 清除选中
    clearSelection() {
      this.checkResult = [];
      this.$refs.assistTable.setAllSelection(false);
      this.$refs.assistTable.setRadioRow(false);
    }
  }
};
</script>

<style lang="scss" scoped>

.assist-dialog-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .footer-left{
    display: flex;
    font-size: 12px;
    .count{
      color: #3e90fe;
    }
    .clear{
      margin-left: 20px;
      color: #3e90fe;
      cursor: pointer;
    }
  }
  .footer-right{
    display: flex;
    align-items: center;
  }
}
</style>
