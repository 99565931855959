/**
 * project: user
 * fileName: utils
 * Created by 徐俊 on 2020/08/18
 * Copyright © 2020 Enfry Ltd. All rights reserved.
 */

const Utils = {
  indexOfFile(fileList, file) {
    return fileList.findIndex((item) => item === file || this.getFileId(item) === this.getFileId(file));
  },
  // 文件id
  getFileId(file) {
    return file.id || file.uid;
  },
  // 是否相等
  isEqualFile(file1, file2) {
    return this.getFileId(file1) === this.getFileId(file2);
  },
  // 文件合并
  mergeFileList(fileList, oldFileList) {
    let list = [];

    if (fileList && fileList.length > 0) {
      const hasOld = oldFileList && oldFileList.length;
      fileList.forEach((file) => {
        const equal = hasOld ? oldFileList.some((item) => this.isEqualFile(file, item)) : false;
        if (!equal) {
          list.push(file);
        }
      });
    }
    list = (oldFileList || []).concat(list);
    return list;
  },
  getImageList(fileList = []) {
    return fileList.filter((file) => file && (file.dataType || file.type) === "000");
  },
  getFileList(fileList = []) {
    return fileList.filter((file) => file && (file.dataType || file.type) !== "000");
  },
  processInvalidData(fileList) {
    if (!fileList) {
      return;
    }
    fileList.forEach((file) => {
      if (!file.name) {
        file.name = file.fileName;
      }
      if (!file.code) {
        file.code = file.fileUrl;
      }
      if (!file.size) {
        file.size = file.attachmentSize;
      }
      if (!file.extension) {
        // 文件后缀名
        const name = file.name || "";
        const extension = name.substr(name.lastIndexOf(".") + 1);
        file.extension = extension ? extension.toLowerCase() : "";
      }

      if (!file.dataType) {
        const extension = (file.extension || "").toLowerCase();
        if (["gif", "jpg", "jpeg", "bmp", "png"].indexOf(extension) >= 0) {
          file.dataType = "000";
        } else {
          file.dataType = "001";
        }
      }
    });
  }
};

export default Utils;
