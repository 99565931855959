<template>
  <en-dialog
    en-body-scroll
    :visible="dialogVisible"
    title="关联查看"
    width="1000"
    @close="dialogVisible = false" >
    <div class="relation-container">
      <span>{{relationData.modelName}}</span>
      <en-table
        height="400"
        :data="dataList"
        :page-config="null"
       >
          <en-table-column
            type="index"
          ></en-table-column>
          <en-table-column
            v-for="column in fieldList"
            min-width="120"
            :key="column.field"
            :label="column.name"
            :prop="column.field">
            <template v-slot="{row}">
              {{
                typeof row[column.field] === "object" ? row[column.field].map(item => item.name).join(",") : row[column.field]
              }}
            </template>
          </en-table-column>
      </en-table>
    </div>
  </en-dialog>
</template>

<script>
// 关联查看
export default {
  name: "RelationDialog",
  props: {
    relationData: {
      type: Object,
      default() {
        return {};
      }
    },
    fieldList: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      dialogVisible: false
    };
  },
  computed: {
    // fieldList() {
    //   return this.relationData.modelFieldList || [];
    // },
    dataList() {
      if (this.relationData.detailNumList) {
        return this.relationData.detailNumList[0]?.standardList || [];
      }
      return [];
    }
  },
  methods: {
    show() {
      this.dialogVisible = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.relation-container {
  padding-bottom: 20px;
}
</style>
