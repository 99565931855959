/*
 * @Author: Zhouql
 * @Date: 2020-06-22 14:58:08
 * @LastEditTime: 2020-07-08 17:26:54
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \user\src\api\folders\index.js
 */
import FoldersService from "./FoldersService";

export const foldersService = new FoldersService();
