<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <!-- 进度条(数值) -->
  <el-progress
    class="bs-progress"
    v-if="isLinePercent || isCirclePercent"
    :percentage="row.progress"
    :type="isLinePercent ? 'line' : 'circle'"
    :width="36"
    :strokeWidth="4"
    :color="setProgressColor(row.progress)"
    :format="() => row.progress"
  ></el-progress>
  <!-- <el-progress class="bs-progress" v-if="isLinePercent || isCirclePercent" :percentage="80"
       :type="isLinePercent ? 'line' : 'circle'"  :width="36"
       :color="col.extAttr.percentageStyleColor" :format="()=> 80"></el-progress> -->
  <!--弹出明细表格 -->
  <en-button
    v-else-if="isSubSet && !isHomePage"
    icon="chakan-liebiao"
    type="text"
    icon-color="darkGreen"
    icon-size="20"
    stop-propagation
    @click.native="handleOpenTable"
  />
  <!-- 流程 -->
  <span
    v-else-if="isConfigFlow && !isHomePage && forceRendering"
    @click.stop="handleOpenTable"
    class="detail-data-isConfigFlow"
  >
    <en-button
      icon="iconliebiao-liuchengrizhi"
      type="text"
      icon-color="#FFA32B"
      icon-size="14"
    >
      <span>查看详情</span>
    </en-button>
  </span>
  <!-- 明细 -->
  <span
    v-else-if="isDetailTable && !isHomePage"
    @click.stop="handleOpenTable"
    class="detail-data-isDetailTable"
  >
    <en-button
      icon="iconguanlianfangan"
      type="text"
      icon-color="#3E90FE"
      icon-size="14"
    >
      <span>查看详情</span>
    </en-button>
  </span>
  <!-- 收付对象 -->
  <span
    v-else-if="isPayObjectTable"
    @click.stop="handleOpenTable"
    class="detail-data-isPayObjectTable"
  >
    <en-button
      icon="iconliebiao-shoufuduixiang"
      type="text"
      icon-color="#A871F6"
      icon-size="14"
    >
      <span>查看详情</span>
    </en-button>
  </span>
  <!-- 收付信息 -->
  <span
    v-else-if="isPayObjectshouFu"
    @click.stop="handleOpenTable"
    class="detail-data-isPayObjectshouFu"
  >
    <en-button
      icon="iconliebiao-shoufuduixiang"
      type="text"
      icon-color="#47BAFF"
      icon-size="14"
    >
      <span>查看详情</span>
    </en-button>
  </span>
  <!--附件-->
  <attachment-display
    v-else-if="isAttachment"
    :isTableCell="true"
    :data="value || []"
    :config="col"
  ></attachment-display>
  <!-- 图文合成 -->
  <span
    v-else-if="isTextComposition"
    @click.stop="handleOpenTextComposition"
    class="detail-data-isTextComposition"
  >
    <en-button
      icon="iconliebiao-tuwenhecheng"
      type="text"
      icon-color="#45D489"
      icon-size="14"
    >
      <span>查看详情</span>
    </en-button>
  </span>
  <!-- 关联数据 -->
  <span
    v-else-if="isAssociatedData"
    @click.stop="handleOpenTable"
    class="associated-data"
  >
    <en-button
      icon="iconliebiao-guanlianshujuchakan"
      type="text"
      icon-color="#45D489"
      icon-size="14"
    >
      <span>查看详情</span>
    </en-button>
    <!-- <en-button
      v-else-if="col.showModel === 1"
      icon="chakan-liebiao"
      type="text"
      icon-color="darkGreen"
      icon-size="20"
    /> -->
  </span>
  <!-- 子项 -->
  <span
    v-else-if="fieldType === 30 && cellText"
    @click.stop="handleOpenTable"
    class="associated-data"
  >
    <en-button
      icon="iconguanlianfangan"
      type="text"
      icon-color="#49BEF2"
      icon-size="14"
    >
      <span style="color: #49bef2">查看详情</span>
    </en-button>
  </span>
  <!-- <el-tag :type="tagType" v-else-if="col.field === 'billStatus'" size="mini">
    {{cellText}}
  </el-tag> -->
  <!-- 状态 -->
  <span v-else-if="col.field === 'billStatus'">
    <div
      v-if="true"
      class="status-style-one"
      :style="{ backgroundColor: tagType.oneStyleBGColor }"
    >
      <!-- 样式一 -->
      <div
        class="status-style-one-text"
        :style="{ backgroundColor: tagType.oneStyleBGColor }"
      >
        {{ cellText }}
      </div>
    </div>
    <div
      v-if="false"
      class="status-style-two"
      :style="{ backgroundColor: tagType.twoStyleBGColor + '10' }"
    >
      <!-- 样式二 -->
      <div class="status-style-two-icon">
        <en-icon
          size="14"
          :name="tagType.twoIcon"
          style="opacity: 1"
          :color="tagType.twoStyleTextColor"
        ></en-icon>
      </div>
      <div
        class="status-style-two-text"
        :style="{ color: tagType.twoStyleTextColor, opacity: 1 }"
      >
        {{ cellText }}
      </div>
    </div>
    <div v-if="false" class="status-style-there">
      <!-- 样式三 -->
      <div class="status-style-there=icon">
        <en-icon
          size="14"
          class="status-style-two-icon"
          :name="tagType.thereIcon"
          style="opacity: 1"
          :color="tagType.thereStyleTextColor"
        ></en-icon>
      </div>
      <div
        class="status-style-there-text"
        :style="{ backgroundColor: tagType.thereStyleTextColor, opacity: 1 }"
      >
        {{ cellText }}
      </div>
    </div>
  </span>
  <!-- 名称 名称不用 -->
  <!-- <span v-else-if="col.field === 'name' && cellText">
  <div class="name-style" v-if="true">
    <div class="name-sculpture">{{[...cellText][0] || ""}}</div>
    <el-tooltip :content="cellText" placement="top" effect="light">
      <div class="name-text">{{cellText}}</div>
    </el-tooltip>
  </div>
  <div class="name-style" v-if="false">{{[...cellText][0]}}</div>
  </span> -->
  <!-- 等于时间类型的时候 -->
  <div v-else-if="col.fieldType === BusinessFieldType.date" class="parent" :class="{isNoValue:!cellText}">
    <BMDateColumn
      @click.stop.native
      :column="columInfo"
      v-if="col.canEdit && row.id === col.rowId"
      @trChange="cellChange"
    ></BMDateColumn>
    <p v-else class="content">
      {{ Utils.dateFormatString(row[col.field], col.timeFormat) }}
    </p>
    <span
      class="iconfont edit"
      v-show="col.showEdit && row.id === col.rowId"
      @click.stop="toEdit(col, row)"
      >&#xe80e;</span
    >
  </div>
  <!-- 等于单行文本,多行文本，数值，金额类型的时候 -->
  <div
    v-else-if="
      [
        BusinessFieldType.multiText,
        BusinessFieldType.inputCommon,
        BusinessFieldType.inputNumber,
        BusinessFieldType.inputAmount,
      ].includes(col.fieldType)
    "
    class="parent"
    :class="{ isNoValue: !cellText }"
  >
    <BMTextColumn
     :isTabEdit="true"
      @click.stop.native
      :column="columInfo"
      @trChange="cellChange"
      v-if="col.canEdit && row.id === col.rowId"
    ></BMTextColumn>
    <p
      v-else
      class="content"
      :title="cellText"
      :class="isEnabled ? '' : 'disable'"
      :style="{ color: col.color, textAlign: col.align, ...nodeStyle }"
    >
    {{ commafy(col,cellText)  }}
    </p>
    <span
      class="iconfont edit"
      v-show="col.showEdit && row.id === col.rowId"
      @click.stop="toEdit(col, row)"
      >&#xe80e;</span
    >
  </div>
  <!-- 等于多选,单选类型的时候 -->
  <div
    v-else-if="
      col.fieldType === BusinessFieldType.multiSelect ||
      col.fieldType === BusinessFieldType.select
    "
    class="parent"
    :class="{ isNoValue: !cellText }"
  >
    <BMSelectColumn
      @trChange="cellChange"
      :column="columInfo"
      :tabEdit="true"
      @click.stop.native
      v-if="col.canEdit && row.id === col.rowId"
    ></BMSelectColumn>
    <!-- <p  v-else class="content" :title="cellText" :class="isEnabled ? '' : 'disable'" :style="{color: col.color, textAlign: col.align, ...nodeStyle}">{{ cellText }}
    </p> -->
    <div v-else-if="cellText">
      <!-- 单选多选 人员机构 -->
      <div v-if="col.areaObjType === '001' || col.areaObjType === '002'">
        <!-- 人员 -->
        <!-- 多选小于等于二 -->
        <div v-if="cellText?.split('、').length <= 2">
          <!-- 方框样式 -->
          <div style="display: flex">
            <div
              v-for="(item, index) in cellText?.split('、')"
              :key="index"
              class="name-style"
            >
              <div class="name-sculpture">{{ [...item][0] || "" }}</div>
              <el-tooltip :content="item" placement="top" effect="light">
                <div class="name-text">{{ item }}</div>
              </el-tooltip>
            </div>
          </div>
        </div>
        <div v-else>
          <!-- 头像样式 -->
          <el-tooltip :content="cellText" placement="top" effect="light">
            <div class="sculpture-list" v-if="cellText">
              <div
                v-for="(item, index) in cellText.split('、')"
                :key="index"
                class="sculpture-style"
                v-if="index < 3"
              >
                {{ [...item][0] || "" }}
              </div>
              <div
                v-if="cellText.split('、').length > 3"
                class="sculpture-style-other"
              >
                +{{ cellText.split("、").length - 3 }}
              </div>
            </div>
          </el-tooltip>
        </div>
      </div>
      <div v-else>
        <div
          class="content"
          v-if="true"
          :title="cellText"
          :class="isEnabled ? '' : 'disable'"
          :style="{ color: col.color, textAlign: col.align, ...nodeStyle }"
        >
          <!-- 默认字体样式 -->
          {{ cellText }}
        </div>
        <div v-else-if="cellText?.split('、').length > 1" class="select-list">
          <!-- 样式二 -->
          <div
            v-for="(item, index) in cellText.split('、')"
            :key="index"
            class="box-style"
            v-if="index < 3"
          >
            <span class="text">{{
              item.length > 9 ? item.splice(0, 9) + "..." : item
            }}</span>
          </div>
        </div>
        <div v-else-if="false" class="select-list">
          <!-- 样式三 十六进制后加10为透明度 -->
          <div
            v-for="(item, index) in cellText.split('、')"
            :style="{ backgroundColor: '#3E90FE10', color: '#3E90FE' }"
            :key="index"
            class="box-style"
            v-if="index < 3"
          >
            <span class="text">{{
              item.length > 9 ? item.splice(0, 9) + "..." : item
            }}</span>
          </div>
        </div>
        <div v-else-if="false" class="select-list">
          <!-- 样式四  -->
          <div
            v-for="(item, index) in cellText.split('、')"
            :key="index"
            class="box-style-other"
            v-if="index < 3"
          >
            <div class="drop" :style="{ backgroundColor: '#3E90FE' }"></div>
            <div class="text" :style="{ color: '#3E90FE' }">
              {{ item.length > 9 ? item.splice(0, 9) + "..." : item }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <span
      class="iconfont edit"
      v-show="col.showEdit && row.id === col.rowId"
      @click.stop="toEdit(col, row, 'isSelect')"
      >&#xe80e;</span
    >
  </div>
  <!-- 标识 未完成&&已完成 -->
  <span v-else-if="fieldType === 8">
    <div v-if="true">
      <!-- 样式一 未完成蓝色 已完成绿色 -->
      <div v-if="cellText === '未完成'" class="incomplete">{{ cellText }}</div>
      <div v-else class="Completed">{{ cellText }}</div>
    </div>
    <div v-if="false">
      <!-- 样式二 -->
      <div v-if="cellText === '未完成'" class="incomplete-style-two">
        <div>
          <en-icon
            name="iconOCR-kongjian"
            size="14px"
            style="color: #3e90fe; opacity: 1"
          ></en-icon>
        </div>
        <div style="opacity: 1; margin-left: 1px">{{ cellText }}</div>
      </div>
      <div v-else class="Completed-style-two">
        <div>
          <en-icon
            name="iconbiaojiwancheng-kongjian"
            size="14px"
            style="color: #45d489; opacity: 1"
          ></en-icon>
        </div>
        <div style="opacity: 1; margin-left: 1px">{{ cellText }}</div>
      </div>
    </div>
  </span>
  <!-- 地址 -->
  <div
    v-else-if="fieldType === 29 && cellText"
    class="address-text"
    :title="cellText"
    :class="isEnabled ? '' : 'disable'"
    :style="{ color: col.color, textAlign: col.align, ...nodeStyle }"
  >
    <div class="address-icon">
      <en-icon
        name="icondizhi-kongjian"
        size="14px"
        style="color: #3e90fe"
      ></en-icon>
    </div>
    <div class="address-name">{{ cellText }}</div>
  </div>
  <!--其他-->
  <div v-else class="parent" :class="{isNoValue:!cellText}">
    <p
      class="content"
      :title="cellText"
      :class="isEnabled ? '' : 'disable'"
      :style="{ color: col.color, textAlign: col.align, ...nodeStyle }"
    >
      {{ cellText }}
    </p>
    <span
      class="iconfont edit"
      v-show="col.showEdit && row.id === col.rowId"
      @click.stop="toEdit(col, row)"
      >&#xe80e;</span
    >
  </div>
</template>

<script>
import { isMap } from "lodash";
import { uuid } from "en-js";
import {
  BusinessFieldType,
  BillStatus,
  BaseStatus
} from "@/components/businessDetail/data/BusinessCommonHeader";
import AttachmentDisplay from "@/components/en-attachment/display";
import { fmoney } from "@/tools/util";
import BMSelectColumn from "@/components/businessDetail/components/columnRendering/select/BMSelectColumn";
import BMTextColumn from "@/components/businessDetail/components/columnRendering/text/BMTextColumn";
import { columnClassCreater } from "@/components/businessDetail/data/parse/BusinessColumnProcess";
import BMDateColumn from "@/components/businessDetail/components/columnRendering/date/index";
import { btnService } from "@/api/businessModel";
import SeeFlowColumn from "@/components/businessDetail/data/columns/seeFlow/SeeFlowColumn.js";
import Utils from "../data/utils";

export default {
  name: "tableContent",
  components: {
    AttachmentDisplay,
    BMSelectColumn,
    BMTextColumn,
    BMDateColumn
  },
  provide() {
    return { $businessDetail: this };
  },
  props: {
    isHomePage: {
      type: Boolean,
      default: false
    },
    isDetail: {
      type: Boolean,
      default: false
    },
    allEdit: {
      type: Array,
      default: () => []
    },
    row: {
      // 行数据
      type: Object,
      default() {
        return {};
      }
    },
    col: {
      // 列数据
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      code: "", // 当前行数据code
      Utils,
      isEdit: false,
      BusinessFieldType,
      columInfo: {},
      params: {
        mdData: {},
        mdEditField: {},
        templateId: this.$route.query.templateId,
        id: "",
        version: "2",
        submitToken: "",
        saveType: "2",
        relationSessionId: "",
        selectionFields: [],
        channelType: "PC",
        channelcode: "PC",
        appSessionId: localStorage.getItem("appSessionId"),
        tenantId: ""
      }
    };
  },
  computed: {
    commafy() {
      return (col, num) => {
        if ((col.fieldType === 3 || col.fieldType === 4) && col.showThousands === "0") {
            if ((`${num }`).trim() === "") {
              return "";
            }
            if (isNaN(num)) {
              return "";
            }
            num += "";
            if (/^.*\..*$/.test(num)) {
              const pointIndex = num.lastIndexOf(".");
              let intPart = num.substring(0, pointIndex);
              const pointPart = num.substring(pointIndex + 1, num.length);
              intPart += "";
              const re = /(-?\d+)(\d{3})/;
              while (re.test(intPart)) {
                intPart = intPart.replace(re, "$1,$2");
              }
              num = `${intPart }.${ pointPart}`;
            } else {
              num += "";
              const re = /(-?\d+)(\d{3})/;
              while (re.test(num)) {
                num = num.replace(re, "$1,$2");
              }
            }
            return num;
        }
        return num;
      };
    },
    // 这里计算的是进度条的值
    percentValue() {
      console.log(this.col, "colcolcolcol");
      const { col, row } = this;
      const value = row[col.field];
      const percentFlag = Number(col.percentFlag);
      const reserverDecimal = Number(col.reserverDecimal || col.decimals || 0);
      if (!value) return "0";
      if (percentFlag === 0) {
        // 百分显示
        return `${Number(value * 100).toFixed(reserverDecimal)}`;
      }
      return Number(value).toFixed(reserverDecimal);
    },
    // eslint-disable-next-line vue/return-in-computed-property, consistent-return
    tagType() {
      //  "000": "未提交",
      // "001": "待处理",
      // "002": "审批中",
      // "003": "已结束",
      // "004": "已终止",
      // "005": "暂缓",
      // "006": "已审批",
      // "008": "已提交"
      const { billStatus } = this.row;
      // 未提交
      if (["000"].includes(billStatus)) {
        return {
          oneStyleTextColor: "#FFFFFF",
          oneStyleBGColor: "#3E90FE",
          twoStyleTextColor: "#3E90FE",
          twoStyleBGColor: "#3E90FE",
          twoIcon: "",
          thereStyleTextColor: "#1A1C1E",
          thereStyleIconColor: "#3E90FE",
          thereIcon: ""
        };
      }
      // 待处理
      if (["001"].includes(billStatus)) {
        return {
          oneStyleTextColor: "#FFFFFF",
          oneStyleBGColor: "#FFA32B",
          twoStyleTextColor: "#FFA32B",
          twoStyleBGColor: "#FFA32B",
          twoIcon: "",
          thereStyleTextColor: "#1A1C1E",
          thereStyleIconColor: "#FFA32B",
          thereIcon: ""
        };
      }
      // 审批中
      if (["002"].includes(billStatus)) {
        return {
          oneStyleTextColor: "#FFFFFF",
          oneStyleBGColor: "#FFA32B",
          twoStyleTextColor: "#FFA32B",
          twoStyleBGColor: "#FFA32B",
          twoIcon: "",
          thereStyleTextColor: "#1A1C1E",
          thereStyleIconColor: "#FFA32B",
          thereIcon: ""
        };
      }
      // 已结束
      if (["003"].includes(billStatus)) {
        return {
          oneStyleTextColor: "#FFFFFF",
          oneStyleBGColor: "#91A1B7",
          twoStyleTextColor: "#91A1B7",
          twoStyleBGColor: "#91A1B7",
          twoIcon: "",
          thereStyleTextColor: "#1A1C1E",
          thereStyleIconColor: "#91A1B7",
          thereIcon: ""
        };
      }
      // 已终止
      if (["004"].includes(billStatus)) {
        return {
          oneStyleTextColor: "#FFFFFF",
          oneStyleBGColor: "#FD625B",
          twoStyleTextColor: "#FD625B",
          twoStyleBGColor: "#FD625B",
          twoIcon: "",
          thereStyleTextColor: "#1A1C1E",
          thereStyleIconColor: "#FD625B",
          thereIcon: ""
        };
      }
      // 暂缓
      if (["005"].includes(billStatus)) {
        return {
          oneStyleTextColor: "#FFFFFF",
          oneStyleBGColor: "#FD625B",
          twoStyleTextColor: "#FD625B",
          twoStyleBGColor: "#FD625B",
          twoIcon: "",
          thereStyleTextColor: "#1A1C1E",
          thereStyleIconColor: "#FD625B",
          thereIcon: ""
        };
      }
      // 已审批
      if (["006"].includes(billStatus)) {
        return {
          oneStyleTextColor: "#FFFFFF",
          oneStyleBGColor: "#45D489",
          twoStyleTextColor: "#45D489",
          twoStyleBGColor: "#45D489",
          twoIcon: "",
          thereStyleTextColor: "#1A1C1E",
          thereStyleIconColor: "#45D489",
          thereIcon: ""
        };
      }
      // 已提交
      if (["008"].includes(billStatus)) {
        return {
          oneStyleTextColor: "#FFFFFF",
          oneStyleBGColor: "#45D489",
          twoStyleTextColor: "#45D489",
          twoStyleBGColor: "#45D489",
          twoIcon: "",
          thereStyleTextColor: "#1A1C1E",
          thereStyleIconColor: "#45D489",
          thereIcon: ""
        };
      }
    },
    // 字段类型
    fieldType() {
      return Number(this.col.fieldType);
    },
    // 是否启用
    isEnabled() {
      return this.row === undefined || this.row.enable !== "001";
    },
    // 数值 (线型)
    isLinePercent() {
      return (
        this.col.colType === "1"
        && this.fieldType === BusinessFieldType.newProgress
      );
    },
    // 数值 (环型)
    isCirclePercent() {
      return (
        this.col.colType === "3"
        && this.fieldType === BusinessFieldType.newProgress
      );
    },
    // 关联数据
    isAssociatedData() {
      return this.fieldType === BusinessFieldType.associatedData;
    },
    // 子集
    isSubSet() {
      return (
        this.fieldType === BusinessFieldType.subSet && this.col.relationType
      );
    },
    // 明细表
    isDetailTable() {
      return this.fieldType === BusinessFieldType.detailParent;
    },
    // 附件
    isAttachment() {
      return (
        this.fieldType === BusinessFieldType.attachment
        || this.fieldType === BusinessFieldType.imageAttachment
      );
    },
    // 图文附件
    isTextComposition() {
      return this.fieldType === BusinessFieldType.textComposition;
    },
    //  收付对象
    isPayObjectTable() {
      return this.fieldType === BusinessFieldType.cashObject && !this.isDetail;
    },
    // 收付信息
    isPayObjectshouFu() {
      return this.fieldType === BusinessFieldType.shouFu;
    },
    // 流程
    isConfigFlow() {
      return (
        (this.fieldType === BusinessFieldType.configFlow && !this.isDetail)
        || (this.fieldType === BusinessFieldType.seeFlow && !this.isDetail)
      );
    },
     // 强制展示的, return false为不展示 流程不展示不渲染
     forceRendering() {
      if (this.col.fieldType === BusinessFieldType.seeFlow && !this.columnDisplayable) {
        return false;
      }
      return true;
    },
    columnDisplayable() {
      const nesSeeFlowColumn = new SeeFlowColumn(this.col);
      if (this.col.field && nesSeeFlowColumn.findColumnByField(this.col.field)) {
        return nesSeeFlowColumn.findColumnByField(this.col.field).displayable; // 再查下一遍的原因是因为明细区行数据对应的是fields字段  数据更新后可能会不准确，需要来自sub属性中的源头数据
      }
      return nesSeeFlowColumn.displayable;
    },
    value() {
      return this.row[this.col.field];
    },
    nodeStyle() {
      // 单选控件 指定数据范围 单个选项自定义的颜色
      const { dataArea, fieldType } = this.col;
      if (
        fieldType === BusinessFieldType.select
        && dataArea?.length
        && this.value
        && this.value[0]
      ) {
        const opt = dataArea.find((itm) => itm.id === this.value[0].id);
        if (opt) {
          return {
            color: opt.color
          };
        }
      }
      return {};
    },
    cellText() {
      const { row } = this;
      const col = { ...this.col };
      if (
        !col.field
        || col.field.length === 0
        || !row
        || !(row instanceof Object)
      ) {
        return "";
      }
      const fieldFormatMap = {
        createDepId: "createDepName",
        createId: "createName",
        createOrgId: "createOrgName"
      };
      col.field = fieldFormatMap[col.field] || col.field;
      let cellData = row[col.field];
      if (col.field.indexOf("_planProgress") === -1) {
        if ((!cellData && cellData !== 0) || cellData.length === 0) {
          // return "";
        }
      }
      if (cellData instanceof Array) {
        // 关联数据只展示第一项
        if (Number(col.fieldType) === BusinessFieldType.associatedData) {
          cellData = cellData[0]?.name || cellData[0]?.code || cellData[0]?.barCode;
        } else {
          const nameArray = [];
          cellData.forEach((dic) => {
            if (dic.name && dic.name.length > 0) {
              nameArray.push(dic.name);
            }
          });
          cellData = nameArray.join("、");
        }
      } else {
        // 1 单行文本   2 多行文本  3数值 4金额  5选项（单选）6业务关联 7日期8 标识 9 附件图片  10进度 11明细 15 多选 16-纯文本控件 28基础数据  29地址 30子项 31 分割线 33所属上级 34 开票选项卡 35 子集 36 图片
        const filedType = Number(col.fieldType);
        const {
          inputAmount,
          select,
          newProgress,
          checkBox,
          inputNumber,
          address
        } = BusinessFieldType;
        if (filedType === address) {
          cellData = typeof cellData === "object"
              ? cellData.content
                || `${cellData.provinceName}${cellData.cityName}${cellData.districtName}${cellData.address}`
              : cellData;
        } else if (filedType === inputNumber) {
          // 数值
          // cellData = Number(col.suffix) === 2 ? Number(cellData / 10000).toFixed(2) : Number(cellData).toFixed(2);
          // cellData = Utils.commafy(cellData);
          const value = cellData;
          if (!Number(cellData)) {
            if (value === "******") {
              cellData = value;
            } else if (col.defaultFormat === "") {
              cellData = "";
            } else if (Number(value) === 0) {
              cellData = "0";
            } else {
              cellData = "";
            }
          } else {
            const percentFlag = Number(col.percentFlag);
            const reserverDecimal = Number(
              col.reserverDecimal || col.decimals || 0
            );
            if (percentFlag === 0) {
              // 百分显示
              cellData = `${Number(value * 100).toFixed(reserverDecimal)}${
                col.suffix
              }`;
            } else {
              cellData = Number(value).toFixed(reserverDecimal);
            }
          }
        } else if (filedType === inputAmount) {
          cellData = (typeof cellData === "string" && cellData.indexOf("*") !== -1)
            || cellData === 0
            || cellData === undefined
              ? cellData
              : Number(col.suffix) === 2
              ? fmoney(Number(cellData / 10000))
              : fmoney(Number(cellData));
          if (!cellData) cellData = "0.00";
        } else if (filedType === select) {
          // 状态
          if (col.field === "enable") {
            cellData = cellData === "000" ? "启用" : cellData === "001" ? "停用" : "";
          } else if (Number(cellData) || cellData === "000") {
            // Number("000") 会转换为0
            cellData = row[`${col.field}Name`] || cellData;
          }
        } else if (filedType === BusinessFieldType.default) {
          if (col.field === "billStatus") {
            cellData = BillStatus[row.billStatus];
          }
          if (col.field === "enable") {
            cellData = BaseStatus[row.enable];
          }
        } else if (filedType === newProgress) {
          const { field } = col;
          if (field.indexOf("_") < 0) {
            // 实际进度
            cellData = cellData && cellData.toString()
                ? `${cellData.toString()}%`
                : "0%";
          } else if (field.indexOf("_planProgress") >= 0) {
            // 计划进度
            cellData = cellData ? `${cellData}%` : "暂无";
          } else if (field.indexOf("_progressStatus") >= 0) {
            // 进度状态
            const statusMap = {
              1: "未开始",
              2: "已完成",
              3: "已延期",
              4: "逾期完成",
              5: "预警中",
              6: "进行中"
            };
            cellData = statusMap[cellData];
          }
        } else if (filedType === checkBox) {
          cellData = cellData === 1 ? "未完成" : "已完成";
        }
      }

      if (isMap(cellData)) {
        if (cellData.name) {
          cellData = cellData.name;
        }
      }
      return cellData;
    }
  },
  methods: {
    // 设置进度条颜色 根据0-100
    setProgressColor(progress) {
      const progressNum = Number(progress);
      if (progressNum >= 0 && progressNum <= 35) {
        return "#3e90fe";
      }
      if (progressNum > 35 && progressNum <= 65) {
        return "#44d489";
      }
      return "#FFA32B";
    },
    toEdit(col, row, type) {
      if (row.code) {
        // 确保每次点击，获取最新的当前唯一值
        if (typeof this.params.mdData === "string") {
          this.params.mdData = JSON.parse(this.params.mdData);
        }
        this.params.mdData.code = row.code;
      }
      // 判断是当前单元格
      col.showEdit = false;
      col.canEdit = col.cellKey === row.id + col.id;
      this.columInfo = columnClassCreater(col);
      this.columInfo.editable = true;
      if (type === "isSelect") {
        if (
          col.fieldType === BusinessFieldType.select
          && row[col.field] && row[col.field].length
        ) {
          this.columInfo.columnValue = row[col.field][0];
          this.columInfo.showValue = row[col.field][0];
        } else {
          this.columInfo.columnValue = row[col.field];
          this.columInfo.showValue = row[col.field];
        }
      } else {
        this.columInfo.columnValue = row[col.field];
        this.columInfo.showValue = row[col.field];
      }
      console.log(col.canEdit);
    },
    handleOpenTable() {
      const { row, col } = this;
      let type;
      if (this.isDetailTable) {
        type = "detail";
      } else if (this.isSubSet) {
        type = "subset";
      } else if (this.isPayObjectTable || this.isPayObjectshouFu) {
        type = "pay";
      } else if (this.isConfigFlow) {
        type = "process";
      } else if (this.isAssociatedData) {
        type = "associatedData";
      }
      this.$emit("openTable", { type, row, col });
    },
    handleOpenTextComposition() {
      const { row, col } = this;
      this.$emit("openTextComposition", { row, col });
    },
    /**
     * 单元格数据修改
     */
    cellChange() {
      console.log(this.columInfo.showValue);
      this.params.mdData[this.col.field] = this.columInfo.showValue;
      this.params.mdEditField[this.col.field] = this.col.name;
      this.params.id = this.row.id;
      this.params.tenantId = this.col.tenantId;
      this.params.submitToken = uuid();
      console.log(this.col);
      this.updateCell();
    },
    /**
     * 单元格修改后保存
     */
    async updateCell() {
      // 将其他可编辑字段添加到 mdData
      this.allEdit.forEach((item) => {
        if (
          item.field !== this.col.field
          && item.fieldType !== BusinessFieldType.address
        ) {
          this.params.mdData[item.field] = this.row[item.field];
        }
      });
      // if (this.code) {
      //   this.params.mdData.code = this.code;.
      // }
      if ((this.col.fieldType === 5 || this.col.fieldType === 15)) {
          // 只管基础数据类型的 数据
          Object.keys(this.params.mdData).forEach((a) => {
            if (this.col.field === a) {
              console.log(this.params.mdData[a]);
                if (!Array.isArray(this.params.mdData[a])) {
                  console.log(1234);
                   // 找到相同的 field 的 字段
                  this.params.mdData[a] = [{
                    id: this.params.mdData[a].id,
                    name: this.params.mdData[a].name,
                    fieldType: this.col.fieldType
                  }];
                  const b = this.params.mdData[a];
                } else {
                  this.params.mdData[a] = this.params.mdData[a].map((it) => ({
                      id: it.id,
                      name: it.name,
                      fieldType: this.col.fieldType
                    }));
                }
            }
        });
      }
      if (this.col.fieldType === 7) {
          // 只管基础数据类型的 数据
          Object.keys(this.params.mdData).forEach((a) => {
            if (this.col.field === a && Array.isArray(this.params.mdData[a])) {
                // 找到相同的 field 的 字段
                if (this.params.mdData[a][0]) {
                  this.params.mdData[a][0] = `${this.params.mdData[a][0] }:00`;
                }
                if (this.params.mdData[a][1]) {
                  this.params.mdData[a][1] = `${this.params.mdData[a][1] }:00`;
                }
                this.params.mdData[a] = this.params.mdData[a].join(",");
            }
        });
      }
      this.params.mdData = JSON.stringify(this.params.mdData);
      this.params.mdEditField = JSON.stringify(this.params.mdEditField);
      this.params.selectionFields = JSON.stringify(this.params.selectionFields);
      console.log(this.params);
      const res = await btnService.mdEditDataInfo(this.params);
      if (res) {
        this.$message(`${res.controlMessage}`);
        this.row[this.col.field] = this.columInfo.showValue;
        this.$emit("changeEditStatu", false);
      }
    }
  },
  watch: {
    col: {
      handler(val) {
        this.$nextTick(() => {
          this.$forceUpdate();
        });
      },
      deep: true
    }
  }
};
</script>

<style scoped lang="scss">
// 多选&&单选 方框样式
.select-list {
  display: flex;
  align-items: center;
  white-space: nowrap; /* 防止文本换行 */
  overflow: hidden; /* 隐藏超出部分 */
  text-overflow: ellipsis; /* 显示省略号 */
  .box-style {
    margin: 0 5px;
    text-align: center;
    height: 26px;
    background: #3e90fe;
    border-radius: 4px;
    line-height: 26px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    .text {
      padding: 0 12px;
    }
  }
  .box-style-other {
    margin: 0 5px;
    height: 26px;
    line-height: 26px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #3e90fe;
    display: flex;
    align-items: center;
    .drop {
      width: 6px;
      height: 6px;
      background: #3e90fe;
      border-radius: 50%;
      margin-right: 5px;
    }
  }
}
// 状态样式
.status-style-one {
  width: 66px;
  height: 26px;
  line-height: 26px;
  border-radius: 4px;
  text-align: center;
  .status-style-one-text {
    border-radius: 4px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
.status-style-two {
  width: 86px;
  height: 26px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  .status-style-two-text {
    margin-left: 5px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
  }
  .status-style-two-icon {
    margin-left: 5px;
  }
}
.status-style-there {
  width: 86px;
  height: 26px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  .status-style-there-text {
    margin-left: 5px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
  }
  .status-style-there-icon {
    margin-left: 5px;
  }
}
.address-text {
  display: flex;
  align-items: center;
  .address-icon {
    margin-left: 8px;
  }
  .address-name {
    margin-left: 8px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1a1c1e;
  }
}
.Completed-style-two {
  display: flex;
  justify-content: center;
  width: 86px;
  height: 26px;
  background: #45d489;
  opacity: 0.1;
  border-radius: 4px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #45d489;
  line-height: 26px;
}
.incomplete-style-two {
  display: flex;
  justify-content: center;
  width: 86px;
  height: 26px;
  background: #3e90fe;
  opacity: 0.1;
  border-radius: 4px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #3e90fe;
  line-height: 26px;
}
.incomplete {
  width: 66px;
  height: 26px;
  background: #3e90fe;
  border-radius: 4px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 26px;
  text-align: center;
}
.Completed {
  width: 66px;
  height: 26px;
  background: #45d489;
  border-radius: 4px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 26px;
  text-align: center;
}
.sculpture-list {
  margin-left: 5px;
  width: 80px;
  display: flex;
  align-items: center;
  .sculpture-style-other {
    margin-left: -5px;
    width: 22px;
    height: 22px;
    line-height: 1.5;
    border: 2px solid #f2f4f7;
    text-align: center;
    background: #b4bed8;
    border-radius: 50%;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
  .sculpture-style {
    margin-left: -5px;
    width: 22px;
    height: 22px;
    line-height: 1.5;
    border: 2px solid #f2f4f7;
    text-align: center;
    background: #3e90fe;
    border-radius: 50%;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
.name-style {
  width: 78px;
  height: 24px;
  background: #f5f8fc;
  border: 1px solid #dce5ec;
  border-radius: 4px;
  display: flex;
  align-items: center;
  .name-sculpture {
    margin-left: 4px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    background: #3e90fe;
    border-radius: 50%;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
  .name-text {
    margin-left: 4px;
    width: 46px;
    height: 13px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1a1c1e;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.bs-progress {
  /deep/ .el-progress-bar {
    width: 92%;
  }
  /deep/ .el-progress__text {
    font-size: 12px !important;
  }
}
.disable {
  color: #cdcdcd !important;
}
.content {
  margin: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  word-break: break-all;
  overflow: hidden;
  height: 100%;
  line-height: 100%;
  min-height: 14px;
}
.parent {
  position: relative;
  width: 100%;
}
.isNoValue {
  min-height: 24px;
}
.edit {
  font-size: 14px;
  color: #3e90fe;
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 11;
  cursor: pointer;
}
.detail-data-isTextComposition {
  display: flex;
  cursor: pointer;
  span {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #45d489;
  }
}
.detail-data-isDetailTable {
  display: flex;
  cursor: pointer;
  span {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #3e90fe;
  }
}
.detail-data-isPayObjectTable {
  display: flex;
  cursor: pointer;
  span {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #a871f6;
  }
}
.detail-data-isPayObjectshouFu {
  display: flex;
  cursor: pointer;
  span {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #47baff;
  }
}
.detail-data-isConfigFlow {
  display: flex;
  cursor: pointer;
  span {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffa32b;
  }
}
.associated-data {
  cursor: pointer;
  margin: 0;
  span {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #45d489;
  }
}
</style>
