var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "en-dialog",
    {
      attrs: {
        "en-body-scroll": "",
        visible: _vm.dialogVisible,
        title: "关联查看",
        width: "1000",
      },
      on: {
        close: function ($event) {
          _vm.dialogVisible = false
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "relation-container" },
        [
          _c("span", [_vm._v(_vm._s(_vm.relationData.modelName))]),
          _c(
            "en-table",
            {
              attrs: { height: "400", data: _vm.dataList, "page-config": null },
            },
            [
              _c("en-table-column", { attrs: { type: "index" } }),
              _vm._l(_vm.fieldList, function (column) {
                return _c("en-table-column", {
                  key: column.field,
                  attrs: {
                    "min-width": "120",
                    label: column.name,
                    prop: column.field,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  typeof row[column.field] === "object"
                                    ? row[column.field]
                                        .map((item) => item.name)
                                        .join(",")
                                    : row[column.field]
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }