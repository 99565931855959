import BaseColumn from "../base/BaseColumn";
/* eslint import/no-cycle: [2, { maxDepth: 1 }] */
import * as BusinessColumnProcess from "../../parse/BusinessColumnProcess";
import { SectionType, AssignmentOptions } from "../../BusinessCommonHeader";
import BaseSection from "../../section/BaseSection";

export default class SubsetColumn extends BaseColumn {
  subDetails = [];

  constructor(fields) {
    super(fields);
    this.originData = fields;
    // 所有配置在子集的字段
    this.sub = BusinessColumnProcess.parsingColumns(fields.sub, this);
    // 写死,因为sub不在页面展示的
    this.sub.forEach((column) => { column._belongSectionType = SectionType.subset; });
    this.createDetails();
  }

  // 创建明细
  createDetails(count = (Number(this.lineNum) || 1)) {
    // count 默认获取明细默认行数
    for (let i = 0; i < count; i++) {
      this.subDetails.push(this.createOneLineData());
    }
  }

  createOneLineData(sub) {
    const detailLineSection = new BaseSection();
    const fields = sub || this.originData.sub;
    detailLineSection.sectionType = SectionType.subset;
    detailLineSection.parentNode = this;
    detailLineSection.fields = BusinessColumnProcess.parsingColumns(fields, detailLineSection);
    return detailLineSection;
  }

  cloneOneLineData(fields = []) {
    const params = {};
    fields.forEach((column) => {
      params[column.field] = column.showValue;
    });
    return params;
  }

  // 明细区 保存值
  // [{ f1404: "铅笔", f1406: [{ id: "320085185968349184", name: "2007企业会计准则+一般纳税人" }] }]
  get paramsForSubmit() {
    const values = this.subDetails.map((line) => {
      let params;
      line.fields.map((column) => column.paramsForSubmit).forEach((val) => {
        if (val === undefined) return;
        params = { ...params, ...val };
      });
      return params;
    });
    if (values.some((item) => item)) {
      const setValues = values.filter((item) => item);
      return {
        [this.field]: setValues
      };
    }
    return undefined;
  }

  // 给明细区每一行设置默认值
  setColumnValue(type, sourceList = []) {
    const len = this.subDetails.length - sourceList.length;
    len < 0 && this.createDetails(Math.abs(len));
    for (let i = 0; i < this.subDetails.length; i++) {
      const sourceData = sourceList[i] || null;
      this.setLineValue(this.subDetails[i], type, sourceData);
    }
  }

  // 给指定行设置value
  setLineValue(line = {}, type = AssignmentOptions.manual, sourceData = null) {
    const { fields } = line;
    fields.forEach((column) => {
      const value = (sourceData !== null && sourceData[column.field]) ? sourceData[column.field] : column.defaults;
      column.updateValueWithOptions(value || "", type);
    });
  }

  // 新增一行
  addOneLine(index) {
    if (Number(this.maxNum) && this.subDetails?.length >= this.maxNum) {
      this.$message(`该明细最大支持${this.maxNum}行`);
      return;
    }
    const line = this.createOneLineData();
    if (index > -1) {
      this.subDetails.splice(index + 1, 0, line);
    } else {
      this.subDetails.push(line);
    }

    this.setLineValue(line, AssignmentOptions.default);
    this.updateValueWithOptions([], AssignmentOptions.manual);
  }

  deleteOneLine(index) {
    this.subDetails.splice(index, 1);
    this.updateValueWithOptions([], AssignmentOptions.manual);
    if (this.subDetails.length === 0) {
      this.addOneLine();
    }
  }

  // 复制一行
  copyOneLine(index) {
    const obj = this.subDetails?.[index] || {};
    const line = this.createOneLineData();
    const data = this.cloneOneLineData(obj.fields);
    // 设置手动填入信息
    this.setLineValue(line, AssignmentOptions.manual, this.cloneOneLineData(obj.fields));
    this.subDetails.push(line);
    this.updateValueWithOptions(data, AssignmentOptions.manual);
  }

  // 解析数据
  parseColumnDefaults(value) {
    // 部分数据 不是 string 如申请人部门
    return typeof value === "object" ? value : ((value && JSON.parse(value)) || []);
  }

  /**
   * 更新值
   * @param {*} value 更新值  Array 或 Object
   * @param {*} opts 更新方式
   * @param {*} index 更新明细指定行数 配合value（Object）传入  不传 默认更新整个明细  value必传(array)
   */
  updateValueWithOptions(value, opts, index) {
    value && (value = this.parseColumnDefaults(value));
    // 默认值赋值  || 设置value时  调用设置每一行数据
    if (opts === AssignmentOptions.default
      || (Object.prototype.toString.call(value) === "[object Array]" && value.length)) {
      this.setColumnValue(opts, value);
    } else if (index !== undefined) {
      try {
        this.setLineValue(this.subDetails?.[index], opts, value);
      } catch {
        throw new Error("调用明细updateValueWithOptions传值错误");
      }
    }
    super.updateValueWithOptions(this.paramsForSubmit, opts);
  }

  // 根据字段是否编辑过,来返回信息 明细,子集,地址需要特殊处理 by:lpx
  get fieldEditInfo() {
    if (this.hasBeenEdit) {
      // 明细区返回的字段编辑需要重写 这里需要修改
      return super.fieldEditInfo;
    }
    return {};
  }

  // 当前子集下第一栏所有子集字段   用于数据关联
  get nextLayerSameAreaFields() {
    return this.subDetails[0]?.fields || []; // subDetails [0] . sectionType === SectionType.subset
  }
}
