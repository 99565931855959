/*
 * @Description:
 * @Author: 梁平贤 查看流程
 * @Date: 2021/1/6 09:36
 */

import BaseColumn from "../base/BaseColumn";

// 查看流程
export default class SeeFlowColumn extends BaseColumn {
  // 重写下显示的控制逻辑
  get displayable() {
    const hasFlowButton = this.rootNode.mdInfo?.shouldShowSeeFlow;
    return super.displayable && hasFlowButton;
  }
}
