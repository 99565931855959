/*
 * @Author: 梁平贤
 * @Date: 2020-12-28 18:38:07
 * @LastEditors: 梁平贤
 * @LastEditTime: 2020-12-28 18:38:07
 * @Description: 里程碑
 */

import BaseColumn from "../base/BaseColumn";

export default class MultiTabColumn extends BaseColumn {

}
