var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "en-loading",
          rawName: "v-en-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "file-list",
    },
    [
      _c(
        "div",
        { staticClass: "top-box", style: _vm.topBoxHeightStyle },
        [
          _vm._l(_vm.fileList, function (file) {
            return _c(
              "div",
              { key: file.id || file.uid, staticClass: "file-box" },
              [
                _c("el-checkbox", {
                  staticClass: "check",
                  attrs: {
                    value: _vm.indexOfSelectFile(file) >= 0,
                    disabled: !file.url && !file.code,
                  },
                  on: {
                    change: function ($event) {
                      return _vm.handleCheckBox(file)
                    },
                  },
                }),
                _c("image-file", {
                  attrs: {
                    data: file,
                    height: "35",
                    "delete-method": _vm.handleDelete,
                    hasDownload: _vm.hasDownload,
                    hasDelete: _vm.hasDelete,
                  },
                }),
              ],
              1
            )
          }),
          _vm.showNoData &&
          !_vm.loading &&
          (!_vm.fileList || !_vm.fileList.length)
            ? _c("en-result", {
                staticStyle: { height: "100%" },
                attrs: { type: "NoData" },
              })
            : _vm._e(),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "bottom-box" },
        [
          _c(
            "div",
            { staticClass: "left" },
            [
              _c("div", { staticClass: "left-text" }, [
                _c("span", [_vm._v("已选")]),
                _c("span", { staticClass: "select" }, [
                  _vm._v(_vm._s(_vm.selectFiles.length)),
                ]),
                _c("span", [_vm._v("条文件")]),
              ]),
              _c(
                "en-button",
                {
                  attrs: { text: "", disabled: !_vm.selectFiles.length },
                  on: { click: _vm.handleClearButton },
                },
                [_vm._v("清空")]
              ),
            ],
            1
          ),
          _vm._t("default"),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }