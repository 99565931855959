<template>
  <div class="file-list" v-en-loading="loading">
    <div class="top-box" :style="topBoxHeightStyle">
      <div v-for="file in fileList" :key="file.id || file.uid" class="file-box">
        <el-checkbox :value="indexOfSelectFile(file) >= 0" class="check" @change="handleCheckBox(file)" :disabled="!file.url && !file.code"></el-checkbox>
        <image-file :data="file" height="35" :delete-method="handleDelete" :hasDownload="hasDownload" :hasDelete="hasDelete"></image-file>
      </div>
      <en-result type="NoData" v-if="showNoData && !loading && (!fileList || !fileList.length)" style="height: 100%"></en-result>
    </div>

    <div class="bottom-box">
      <div class="left">
        <div class="left-text">
          <span>已选</span>
          <span class="select">{{selectFiles.length}}</span>
          <span>条文件</span>
        </div>
        <en-button text @click="handleClearButton" :disabled="!selectFiles.length">清空</en-button>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import ImageFile from "./imageFile";

export default {
  name: "FileList",
  components: { ImageFile },
  props: {
    loading: Boolean, // 加载
    showNoData: Boolean, // 显示无数据
    fileList: Array, // 文件列表
    otherHeight: [String, Number], // 其他空间,用于固定底部
    defaultSelects: Array, // 默认选中
    deleteMethod: Function, // 删除方法
    hasDownload: Boolean, // 是否有下载按钮
    hasDelete: {
      type: Boolean,
      default: true
    },
    defaultSelect: { // 是否默认选中
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectFiles: []
    };
  },
  computed: {
    topBoxHeightStyle() {
      // 50为 bottomHeight
      return `height: calc(100% - ${50 + Number(this.otherHeight || 0)}px)`;
    }
  },
  watch: {
    fileList: {
      deep: true,
      handler(val) {
        if (this.defaultSelect) {
          this.selectFiles = [...val];
        }
      }
    }
  },
  methods: {
    // fileId(file) {
    //   return file.uid || file.id;
    // },
    indexOfFile(file) {
      return this.fileList.indexOf(file);
    },
    indexOfSelectFile(file) {
      return this.selectFiles.indexOf(file);
    },
    handleClearButton() {
      this.selectFiles.splice(0, this.selectFiles.length);
      this.$forceUpdate();
      this.$emit("change", this.selectFiles);
    },
    handleDelete(file) {
      if (this.deleteMethod) {
        this.deleteMethod(file);
      } else {
        const index = this.indexOfFile(file);
        this.fileList.splice(index, 1);
      }

      const selectIndex = this.indexOfSelectFile(file);
      if (selectIndex >= 0) {
        this.handleCheckBox(file);
      }
    },
    handleCheckBox(file) {
      const index = this.indexOfSelectFile(file);
      if (index < 0) {
        this.selectFiles.push(file);
      } else {
        this.selectFiles.splice(index, 1);
      }
      this.$emit("change", this.selectFiles);
    }
  }
};
</script>

<style scoped lang="scss">

  .file-list {
    height: 100%;
    //文件列表
    .top-box {
      overflow-y: auto;

      .file-box {
        display: flex;
        align-items: center;
        padding-top: 12px;
        .image-file {
          margin-left: 12px;
          width: 100%;
        }
      }
    }

    //底部选中按钮
    .bottom-box {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      .left {
        display: flex;
        align-items: center;
        .left-text {
          color: #999999;
          margin-right: 15px;
          //高亮选中数字
          .select {
            font-weight: bold;
            color: #3e90fe;
          }
        }
      }
    }
  }

</style>
