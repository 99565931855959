var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "assist-preview" },
    [
      _c(
        "en-dialog",
        {
          attrs: {
            visible: _vm.showDialog,
            title: "辅助信息",
            "en-body-scroll": "",
            "append-to-body": "",
          },
          on: {
            close: function ($event) {
              _vm.showDialog = false
            },
          },
        },
        [
          _c(
            "en-table",
            {
              directives: [
                {
                  name: "en-loading",
                  rawName: "v-en-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "assistTable",
              attrs: {
                data: _vm.assistList,
                "select-config": {
                  checkRowIds: _vm.defaultCheck,
                  checkRowId: _vm.defaultCheck,
                  reserve: true,
                },
                height: "400",
              },
              on: {
                "filter-sort-change": _vm.handleTableFilterChange,
                "select-change": _vm.handleSelectChange,
              },
            },
            [
              _vm.column.isMultiSelect
                ? _c("en-table-column", {
                    attrs: { type: "index-selection", width: "50" },
                  })
                : _c("en-table-column", {
                    attrs: { type: "radio", width: "50" },
                  }),
              _vm._l(_vm.column.assistSelect, function (item, index) {
                return _c("en-table-column", {
                  key: index,
                  attrs: {
                    title: item.name,
                    field: item.field,
                    "filter-sort-config": {
                      sortable: true,
                      filterable: true,
                      sortKey: "sort",
                    },
                    "filter-render": {
                      name: "ElInput",
                      attrs: { placeholder: "请输入名称..." },
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  typeof scope.row[item.field] === "object"
                                    ? scope.row[item.field][0].name
                                    : scope.row[item.field]
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
            ],
            2
          ),
          _c("div", { staticClass: "assist-dialog-footer" }, [
            _c("div", { staticClass: "footer-left" }, [
              _c("div", { staticClass: "label" }, [
                _vm._v("已选"),
                _c("span", { staticClass: "count" }, [
                  _vm._v(_vm._s(_vm.checkResult.length)),
                ]),
                _vm._v("条数据"),
              ]),
              _c(
                "div",
                { staticClass: "clear", on: { click: _vm.clearSelection } },
                [_vm._v("清空")]
              ),
            ]),
            _c(
              "div",
              { staticClass: "footer-right" },
              [
                _c("en-pagination", {
                  attrs: { "page-model": _vm.pageModel },
                  on: { change: _vm.handlePageChanged },
                }),
                _c(
                  "en-button",
                  {
                    staticClass: "assist-btn",
                    on: { click: _vm.confirmCheck },
                  },
                  [_vm._v(" 确定 ")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }