/*
 * @Description: 图片控件实体
 * @Author: 梁平贤
 * @LastEditors: linshanfeng
 * @Date: 2019-09-11 16:23:21
 * @LastEditTime: 2024-01-31 17:57:37
 */
import BaseColumn from "../base/BaseColumn";

export default class ImageColumn extends BaseColumn {
    columnValue = [];

    // 是否能提交数据,校验
    get checkCouldSubmit() {
      // 没有填写不校验其它条件,但是要校验必填
      if (this.required) {
        if (!this.columnValue.length) {
          return new Error(`${this.name}不能为空`);
        }
      }
      // 超出数量限制
      if (this.limitNum > 0 && this.columnValue.length > this.limitNum) {
        return new Error(`${this.name}上传数量不能超过${this.limitNum}个`);
      }
      return true;
    }

    // 提交参数
    get paramsForSubmit() {
      if (this.columnValue.length > 0) {
        return {
          [this.field]: this.columnValue
        };
      }
        return undefined;
    }

    /**
     * @description: 赋值
     * @param {type}
     * @return:
     */
    updateValueWithOptions(value, opts) {
      console.log("赋值了13", value, opts);
      this.columnValue = value || [];
      super.updateValueWithOptions(value, opts);
    }
  }
