/*
 * @Description: 节点继承关系
 * @Author: 梁平贤
 * @Date: 2020/7/14 17:15
 */

import FieldInfo from "./FieldInfo";

export default class BaseNode extends FieldInfo {
  // 根节点 其实就是业务建模的实体 使用的话用get方法 这是缓存属性,可能会为空
  _rootNode;

  // 父节点
  parentNode;

  // 根节点 业务建模
  get rootNode() {
    if (this._rootNode) {
      return this._rootNode;
    }

    let preParentNode = this;
    let parentNode = this.parentNode;
    // 找到根节点,根节点就是业务建模的实体
    while (parentNode) {
      preParentNode = parentNode;
      parentNode = parentNode.parentNode;
    }
    this._rootNode = preParentNode;
    return this._rootNode;
  }

  // 搜索层级,找到对应class的的对象
  searchParentNodeWithClassName(Class) {
    let parentNode = this.parentNode;
    while (parentNode && !(parentNode instanceof Class)) {
      parentNode = parentNode.parentNode;
    }
    return parentNode;
  }
}
