<template>
  <div class="assciated-sub-table">
    <div class="title">{{subData.name}}</div>
    <en-table v-if="colList" ref="table" :expand-config="{trigger:'default'}" :loading="loading"
      :data="tableList" :page-config="null" max-height="600" @filter-sort-change="handleFilterSortChange">
      <en-table-column v-if="canExpand" type="expand" title="展开" width="50">
        <template v-slot="{ row }">
          <sub-table :area="3" :row-data="row" :column="column"></sub-table>
        </template>
      </en-table-column>
      <en-table-column width="50" type="index"></en-table-column>
      <table-column v-for="item in colList"  :key="item.field" :data="item"></table-column>
      <template slot="empty">
        <div>暂无数据</div>
      </template>
    </en-table>
  </div>
</template>

<script>
import { detailListService } from "@/api/businessModel";
import tableColumn from "@/components/en-table-extra/tableColumn";

export default {
  name: "SubTable",
  components: {
    tableColumn
  },
  props: {
    rowData: {
      type: Object,
      default() {
        return {};
      }
    },
    column: {
      type: Object,
      default() {
        return {};
      }
    },
    area: {
      type: Number,
      default: 2
    }
  },
  data() {
    return {
      params: {},
      subData: {},
      tableList: [],
      loading: true
    };
  },
  computed: {
    canExpand() {
      const { selAreaType, showListStyle } = this.column;
      return showListStyle === 2 && Number(selAreaType) === 3 && this.area !== 3;
    },
    colList() {
      return this.subData.colList?.filter((item) => Number(item.listIsShow) === 0) || [];
    }
  },
  created() {
    this.querySubDataColList();
    this.querySubDataList();
  },
  methods: {
    // 筛选
    handleFilterSortChange({ filterData, sortData }) {
      this.params.filterFields = filterData;
      this.params.sortField = sortData;
      this.loading = true;
      this.querySubDataList();
    },
    async querySubDataColList() {
      const {
        nameVariable, version, templateId, objectTypeId, selAreaField, selAreaParentField
      } = this.column;
      const params = {
        templateId: this.rowData.templateId,
        field: selAreaParentField || selAreaField,
        area: this.area,
        controlField: nameVariable,
        controlTempId: templateId || objectTypeId,
        controlVersion: version
      };
      if (this.area === 3) {
        params.field = selAreaField;
        params.parentField = selAreaParentField;
      }
      const res = await detailListService.mdQuerySubDataColList(params);
      this.subData = res;
    },
    async querySubDataList() {
      const {
        nameVariable, version, templateId, objectTypeId, selAreaField, selAreaParentField
      } = this.column;
      const params = {
        ...this.params,
        templateId: this.rowData.templateId,
        field: selAreaParentField || selAreaField,
        area: this.area,
        controlField: nameVariable,
        controlTempId: templateId || objectTypeId,
        controlVersion: version,
        pageNo: 1,
        pageSize: 2000,
        parentIds: this.rowData.id
      };
      if (this.area === 3) {
        params.field = selAreaField;
        params.parentField = selAreaParentField;
      }
      const res = await detailListService.mdQuerySubDataList(params);
      this.loading = false;
      this.tableList = res.records;
    }
  }
};
</script>

<style lang="scss" scoped>
.assciated-sub-table {
  padding: 10px;
  margin: 10px;
  border: 1px solid #b8e1f4;
  background: #fcfcfc;
  .title {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 5px;
  }
}
</style>
