/*
 * @Description: // 收付对象
 * @Author: 梁平贤
 * @LastEditors: Please set LastEditors
 * @Date: 2019-07-09 18:18:50
 * @LastEditTime: 2024-02-29 09:59:54
 */
import BaseColumn from "../base/BaseColumn";
/* eslint import/no-cycle: [2, { maxDepth: 1 }] */
import * as BusinessColumnProcess from "../../parse/BusinessColumnProcess";

export default class CashObjectColumn extends BaseColumn {
  constructor(fields) {
    super(fields);
    this.sub = BusinessColumnProcess.parsingColumns(fields.sub, this);
  }

  get checkCouldSubmit() {
    // 没有填写不校验其它条件,但是要校验必填
    if (this.required) {
      if (!this.columnValue.length) {
        return new Error(`${this.name}不能为空`);
      }
    }
    return true;
  }

  // 提交参数
  get paramsForSubmit() {
    return {
      [this.field]: this.columnValue
    };
  }
}
