var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "assciated-sub-table" },
    [
      _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.subData.name))]),
      _vm.colList
        ? _c(
            "en-table",
            {
              ref: "table",
              attrs: {
                "expand-config": { trigger: "default" },
                loading: _vm.loading,
                data: _vm.tableList,
                "page-config": null,
                "max-height": "600",
              },
              on: { "filter-sort-change": _vm.handleFilterSortChange },
            },
            [
              _vm.canExpand
                ? _c("en-table-column", {
                    attrs: { type: "expand", title: "展开", width: "50" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c("sub-table", {
                                attrs: {
                                  area: 3,
                                  "row-data": row,
                                  column: _vm.column,
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      11281281
                    ),
                  })
                : _vm._e(),
              _c("en-table-column", { attrs: { width: "50", type: "index" } }),
              _vm._l(_vm.colList, function (item) {
                return _c("table-column", {
                  key: item.field,
                  attrs: { data: item },
                })
              }),
              _c("template", { slot: "empty" }, [
                _c("div", [_vm._v("暂无数据")]),
              ]),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }