<!--
 * @Author: pengyu
 * @Date: 2020-07-21 16:58:15
 * @LastEditors: wuqi
 * @LastEditTime: 2021-08-10 09:56:13
 * @Description: 自动赋值-设置弹框
-->
<template>
   <en-dialog
      :visible="visible"
      title="自动赋值"
      width="1100px"
      @close="visible=false"
   >
      <div class="container">
        <div class="left-tree">
          <!-- 搜索框 -->
          <el-input placeholder="请输入关键字" suffix-icon="sousuo-danchuang" v-model="filterText"></el-input>
          <!-- 树 -->
          <div class="tree-content" v-en-loading="treeLoading">
            <div v-if="show">
              <!-- <en-tree
                mode="tree"
                :data="treeData"
                :props="{value:'id',label:'name',isLeaf:isLeaf}"
                :expand-on-click-node="false"
                :check-on-click-node="true"
                :check-limit="1"
                :page-size='20'
                lazy
                :load="loadData"
                :filter-text="filterText"
                :filter-node-method="filterNode"
                @node-click="handleNodeClick"
              >
              </en-tree> -->
              <en-tree
                node-key="id"
                lazy
                :page-size="20"
                :load="loadNode"
                :props="{value:'id',label:'name',isLeaf:isLeaf}"
                :filter-text="filterText"
                :filter-node-method="filterNode"
                @node-click="handleNodeClick"
              ></en-tree>
            </div>
          </div>
        </div>
        <div class="right-table" v-en-loading="tableLoading">
          <en-table
            :data="tableData"
            width="100%"
            height="100%"
            :select-config="{checkField:'checked'}"
            ref="baseTable"
            @select-change="radioChangeEvent"
          >
            <en-table-column type="radio" width="60"></en-table-column>
            <en-table-column
              v-for="(itm,idx) in columnData"
              :key="idx"
              :field="itm.field"
              :title="itm.name"
            >
              <template v-slot:edit="{row}">
                <div style="padding-left:12px">
                  {{initRowData(itm, row)}}
                </div>
              </template>
            </en-table-column>
          </en-table>
        </div>
      </div>
      <div slot="footer">
        <en-button @click="submit">
          确定
        </en-button>
      </div>
    </en-dialog>

</template>

<script>
import { Message } from "element-ui";
import { request } from "en-js";
import { businessService } from "@/api/businessModel";

export default {
  name: "PopAutoAssign",
  props: {
    column: { // 字段属性
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      leftSelect: null,
      pageNo: 1,
      show: true,
      visible: false,
      columnData: [], // 列头
      tableData: [], // 表格数据
      treeData: [], // 树 数据
      treeLoading: true,
      tableLoading: true,
      filterText: "" // 搜索字段
    };
  },
  watch: {
    visible(nVal) {
      if (nVal) {
        this.init();
      } else {
        Object.assign(this.$data, this.$options.data());
      }
    }
  },
  methods: {
    /**
     * @description: 初始化 树 和 表格 表头
     */
    init() {
      this.querySubColInfo();
      this.queryTreeData();
    },
    /**
     * @description: 树 异步加载子节点
     * @param node
     * @param resolve
     */
    async loadData(node, resolve) {
      console.log(node);
      const { data } = node;
      const { hasChildren, id } = data;
      const { areaObjType } = this.attr;
      if (hasChildren === "000") { // 有子项
        if (areaObjType === "002") {
          const rsp = await this.queryUserTree(id);
          resolve(rsp);
        } else {
          const rsp = await this.queryBaseTree(id);
          resolve(rsp);
        }
      } else {
        resolve([]);
      }
    },
    // eslint-disable-next-line consistent-return
    async loadNode(node, resolve) {
      const { pageNo, pageSize = 20 } = node;

      console.log(pageNo);
      let datas = [];

      datas = await this.queryBaseTree();
      if (datas?.length) {
        this.pageNo++;
        resolve({
          pageNo: this.pageNo + 1,
          pageSize,
          datas
        });
      } else {
        resolve({
          pageNo: this.pageNo + 1,
          pageSize,
          datas: [],
          total: 0
        });
      }
    },
    /**
     * @description: 查询自动赋值 数据
     */
    @request(true, "tableLoading")
    async querySubColInfo() {
      const { areaObjTypeId, autoChooseArea } = this.column;
      const pa = {
        objectTypeId: areaObjTypeId,
        field: autoChooseArea
      };
      const rsp = await businessService.querySubColInfo(pa);
      this.columnData = rsp;
      this.columnData.splice(0, 0);
    },
    /**
     * @description:查询树 数据
     */
    @request(true, "treeLoading")
    async queryTreeData() {
      const { areaObjType } = this.column;
      let rsp = [];
      if (areaObjType === "002") { // 人员
        rsp = await this.queryUserTree();
      } else {
        rsp = await this.queryBaseTree();
      }
      this.treeData = rsp;
    },
    /**
     * @description: 查询人员树 数据
     * @param id     节点id 0为最高层级
     */
    async queryUserTree(id = 0) {
      const { assistSelect, baseDataArea } = this.column;
      const pa = {
        ids: baseDataArea,
        id,
        allFields: assistSelect?.length ? 0 : 1,
        isNeedLogo: 1,
        assistSelect: JSON.stringify(assistSelect)
      };
      const rsp = await businessService.queryPersonDeptTree(pa);
      return rsp;
    },
    /**
     * @description: 查询其他基础数据树 数据
     * @param id     节点id 0为最高层级
     */
    async queryBaseTree(id = 0) {
      const { assistSelect, baseDataArea, areaObjTypeId } = this.column;
      const pa = {
        objectTypeId: areaObjTypeId,
        ids: baseDataArea,
        id,
        pageNo: this.pageNo,
        pageSize: 20,
        allFields: assistSelect?.length ? 0 : 1,
        assistSelect: JSON.stringify(assistSelect)
      };
      const rsp = await businessService.queryObjectTree(pa);
      return rsp;
    },
    /**
     * @description: 指定节点是否为叶子节点
     * @param data 节点数据
     * @return:
     */
    isLeaf(data) {
      return data.hasChildren === "001";
    },
    /**
     * @description: 树节点选中
     * @param data 节点数据
     * @return:
     */
    handleNodeClick(data) {
      const { hasChildren, id } = data;
      this.leftSelect = data;
      if (hasChildren === "001") { // 叶子节点
        this.queryTableData(id);
      }
    },
    /**
     * @description: 查询列表数据
     * @param {type}
     * @return:
     */
    @request(true, "tableLoading", {
      subTitle: "数据加载中"
    })
    async queryTableData(id) {
      const { areaObjTypeId, autoChooseArea } = this.column;

      const pa = {
        objectTypeId: areaObjTypeId,
        field: autoChooseArea,
        id,
        pageNo: 1,
        pageSize: 999
      };

      const rsp = await businessService.querySubPageMode(pa);
      this.tableData = rsp.records;
    },
    /**
     * @description: 表格数据 初始化
     * @param col 列数据
     * @param row 行数据
     */
    initRowData(col, row) {
      const { field, fieldType } = col;
      let re = "";
      if (fieldType.toString() === "5") {
        const arr = row[field] || [];
        arr.forEach((itm) => {
          re += itm.name;
        });
      } else {
        re = row[field];
      }

      return re;
    },
    /**
     * @description: 单选框
     * @param {type}
     * @return:
     */
    radioChangeEvent({ rowIndex }) {
      this.tableData.forEach((itm, idx) => {
        itm.checked = idx === rowIndex;
      });
    },
    /**
     * @description: 确定
     */
    submit() {
      const checked = this.tableData.filter((itm) => itm.checked);
      const { name } = this.column;
      if (checked[0]) {
        this.$emit("handleBaseChoose", this.leftSelect, checked[0]);
        this.visible = false;
      } else {
        Message(`请选择${name}`);
      }
    },
    /**
     * @description: 自定义搜索
     * @param value 搜索值
     * @param data
     */
    filterNode(value, data) {
      return data.name.indexOf(value) !== -1;
    }

  }
};
</script>

<style lang='scss' scoped>
/deep/ .el-dialog__header{
  text-align: left;
}
.container{
  display: flex;
  .left-tree{
    flex: 1;
    border: 1px solid #e8ecf2;
    border-radius: 6px;
    padding: 10px;
    height: 400px;
    overflow: hidden;
    overflow-y: auto;
  }
  .right-table{
    margin-left: 10px;
    flex: 2;
    overflow: hidden;
    overflow-y: auto;
    height: 400px;
  }
}
</style>

<!--
<pop-auto-assign
  ref="autoAssignMod"
  :attr="column"  //字段属性
  @handleBaseChoose="handleChoose"
></pop-auto-assign>
-->
