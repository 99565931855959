<template>
  <en-dialog
    class="summary-resolve-detail"
    width="65%"
    style="text-align: left"
    :visible="visible"
    append-to-body
    :title="title"
    @close="close"
    :close-on-click-modal="false"
  >
    <div v-en-loading="loading" v-if="datas" style="height:60vh">
      <div v-if="isSummaryUp" class="header">
        <span style="color: #636C78;font-size: 12px;margin-left: 13px">总{{ name }}：</span>
        <span style="color: #3e90fe;font-size: 14px">{{ value }}</span>
      </div>
      <div v-else class="header">
        <span style="color: #636C78;font-size: 12px;margin-left: 13px">{{ "剩余" + name }}：</span>
        <span style="color: #3e90fe;font-size: 14px">{{ surplusValue }}</span>
        <span style="color: #01AA73;font-size: 12px">（已分解：{{ showUseValue }}）</span>
      </div>
      <el-table
        :data="datas"
        border
        style="width: 100%">
        <el-table-column
          type="index"
          label="序号"
          width="80">
        </el-table-column>
        <el-table-column
          prop="name"
          label="业务名称">
        </el-table-column>
        <el-table-column
          prop="value"
          :label="labelValueTitle">
        </el-table-column>
      </el-table>
    </div>
  </en-dialog>
</template>

<script>
import { BusinessFieldType } from "../../../../data/BusinessCommonHeader.js";

export default {
  name: "summaryResolveDetail",
  props: {
    column: {
      type: Object
    },
    businessData: {
      type: Object
    },
    visible: {
      type: Boolean
    }
  },
  data() {
    return {
      datas: [],
      loading: false
    };
  },
  mounted() {
    this.queryData();
  },
  computed: {
    templateHandle() {
      return this.businessData.templateHandle;
    },
    // 是否是汇总
    isSummaryUp() {
      return this.templateHandle.summaryUpFields.indexOf(this.column.field) !== -1;
    },
    // 金额标题
    labelValueTitle() {
      return `${this.isSummaryUp ? "汇总" : "分解"}${this.column.name}${this.suffix?.length > 0 ? `（${this.suffix}）` : ""}`;
    },
    // 后缀
    suffix() {
      if (this.column.fieldType === BusinessFieldType.inputAmount) {
        return Number(this.column.suffix) === 2 ? "万元" : "元";
      }
      return this.column.suffix;
    },
    title() {
      return this.isSummaryUp ? "汇总明细" : "分解明细";
    },
    name() {
      return this.column.name;
    },
    // 控件值
    value() {
      return this.column.showValue.toFixed(2);
    },
    // 剩余金额
    surplusValue() {
      return (Number(this.column.showValue || 0) - this.totalAmount).toFixed(2);
    },
    // 显示已使用的值
    showUseValue() {
      return this.totalAmount.toFixed(2);
    },
    // 总值
    totalAmount() {
      const values = this.datas.map((item) => item.value);
      let val = 0;
      values.forEach((value) => {
        val += Number(value);
      });
      return val;
    }
  },
  methods: {
    async queryData() {
      this.loading = true;
      const datas = await this.templateHandle.querySumaryResolveDetail(this.column.field);
      this.datas = datas || [];
      // if (this.isSummaryUp) {
      //   this.value = this.datas.reduce((a, b) => Number(a.value) + Number(b.value), { value: 0 });
      // }
      this.loading = false;
    },
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped>

.summary-resolve-detail {
  padding-bottom: 20px;
  .header {
    width: 100%;
    height:34px;
    background:rgba(245,247,250,1);
    border:1px solid rgba(232, 236, 242, 1);
    border-radius:5px;
    margin-bottom: 12px;
    line-height: 34px;
  }
  & /deep/ .el-dialog__header {
    display: block;
  }
  /deep/ .el-dialog__body {
    padding-bottom: 20px;
  }
  /deep/ .el-table__header .cell {
    color: #333333;
    font-size: 12px;
  }
  /deep/ .el-table__body .cell {
    color: #636C78;
    font-size: 12px;
  }
}

</style>
