<template>
  <div>
    <el-dialog
      title="上传附件"
      :visible.sync="visible"
      :close-on-click-modal="false"
      append-to-body
      destroy-on-close
      width="900px">
      <div class="main-dialog" v-en-loading="loading">
        <template v-if="!loading">
          <!--按钮列表-->
          <div class="btn-list" >
            <en-upload v-if="$attachment.hasLocalPermisson" :on-change="handleUploadChange" :on-progress="handleProgressChange" class="upload">
              <en-button icon="add"> 本地文件</en-button>
            </en-upload>
            <en-button v-if="$attachment.hasTemplatePermisson" icon="add" type="green" @click="$refs.myTemplate.open()">模版文件</en-button>
            <en-button v-if="$attachment.hasWordPermission" icon="add" class="word-btn"  @click="openPageOffice">Word文件</en-button>
            <en-button v-if="$attachment.hasMyFolderPermission" icon="add" type="green" @click="$refs.myFolder.open()">我的文件夹</en-button>
          </div>
          <!--显示的文件列表-->
          <file-list :file-list.sync="fileList" other-height="50" @change="handleCheckChanged" :defaultSelect="true">
            <slot>
              <div>
                <!-- <en-button v-if="hasComparePermisson" @click="handleCompareButton" class="btn" type="white" :disabled="!selectFiles || selectFiles.length !== 2">对比</en-button> -->
                <en-button key="scfj" @click="handleOKButton" class="btn" :disabled="!selectFiles || !selectFiles.length">确定</en-button>
              </div>
            </slot>
          </file-list>
        </template>
      </div>
    </el-dialog>
    <!--其他类型弹框-->
    <my-template v-if="$attachment.hasTemplatePermisson"  ref="myTemplate" :params="requestParams" @change="handleAddNewFiles"></my-template>
    <my-word v-if="$attachment.hasWordPermission" ref="myWord" :params="requestParams" @change="handleAddNewFiles"></my-word>
    <my-folder v-if="$attachment.hasMyFolderPermission" ref="myFolder" @change="handleAddNewFiles"></my-folder>
  </div>
</template>

<script>
import { request } from "en-js";
import { mapState } from "vuex";
import { enService } from "@/api/en/index";
import { businessService } from "@/api/businessModel";
import FileList from "../views/fileList";
import MyFolder from "./myFolder";
import MyTemplate from "./myTemplate";
import MyWord from "./myWord";
import Utils from "../data/utils";

export default {
  name: "MainDialog",
  components: {
    MyWord, MyTemplate, MyFolder, FileList
  },
  props: {
    onEnd: Function
  },
  inject: ["$attachment"],
  watch: {
    visible(val) {
      if (val) {
        this.fileList = undefined;
        this.selectFiles = undefined;
      }
    }
  },
  data() {
    return {
      loading: false,
      visible: false,
      fileList: undefined,
      selectFiles: undefined
    };
  },
  computed: {
    requestParams() {
      // TODO: 这里需要业务建模 字段数据
      const { busData, mdConfig } = this.$attachment;
      const submitData = busData.paramsForSubmit();
      return {
        templateId: submitData.templateId,
        field: mdConfig.field,
        mdDataStr: submitData?.mdData || "",
        version: submitData.version
      };
    },
    // 是否有对比权限
    hasComparePermisson() {
      return (this.pageOfficeData || []).some((item) => Number(item.id) === 5);
    },
    ...mapState("businessModel", ["pageOfficeData"])
  },
  methods: {
    open() {
      this.visible = true;
      this.requestPermission();
    },
    @request(true, "loading")
    async requestPermission() {
      if (this.pageOfficeData) {
        return;
      }
      const res = await enService.post("queryPageOfficeData");
      this.$store.commit("businessModel/savePageOfficeData", res || []);
    },
    handleUploadChange({ fileList }) {
      this.handleAddNewFiles(fileList);
    },
    handleProgressChange() {
      this.fileList.splice(this.fileList.length);
    },
    handleAddNewFiles(fileList = []) {
      this.fileList = Utils.mergeFileList(fileList, this.fileList);
      this.fileList.splice(this.fileList.length);
       this.selectFiles = [...this.fileList];
    },
    handleCheckChanged(selectFiles) {
      this.selectFiles = selectFiles;
    },
    handleCompareButton() {
      const ret = this.selectFiles.every((file) => {
        const extension = file?.extension?.toLocaleLowerCase();
        return extension === "doc" || extension === "docx";
      });
      if (!ret) {
        this.$message("请选择两个word文件");
        return;
      }

      this.$message("mac电脑不能使用pageOffice对比, 哪个大佬完善下");
    },
    handleOKButton() {
      if (this.selectFiles) {
        if (this.$parent.checkLimitNum(this.selectFiles.length)) {
          return;
        }
        this.$emit("change", this.selectFiles);
      }
      this.visible = false;
    },
    /**
     * @description 打开pageoffice 创建word文件
     *
    */
    async openPageOffice() {
      const rsp = await businessService.mdCreateNewDocx();
      const file = {
        attachmentSize: 0,
        code: rsp,
        extension: "docx",
        fileCode: rsp,
        name: `文件(${new Date().toISOString().split("T")[0] } ${new Date().toTimeString().substring(0, 8)}).docx`,
        type: "001"
      };
       this.handleAddNewFiles([file]);
    }
  }
};
</script>

<style scoped lang="scss">
  .main-dialog {
    height: 550px;
    .btn-list {
      display: flex;
      align-items: center;
      height: 50px;//这里就是上方的otherHeight
      .upload {
        margin-right: 6px;
      }
    }
    .btn {
      width: 70px;
    }
    .word-btn{
      background: #D69E79;
      border-color: #D69E79;
      &:hover{
        background: #E4AD89 !important;
        border-color: #E4AD89 !important;
      }
      &:active{
        background: #D69E79 !important;
        border-color: #D69E79 !important;
      }
      &:focus{
        background: #D69E79 !important;
        border-color: #D69E79 !important;
      }
    }
  }

</style>
