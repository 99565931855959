/*
 * @Description: 创建column
 * @Author: 梁平贤
 * @Date: 2020/7/4 15:39
 */

export default function createColumn(Target) {
  // 根据columnType创建column,这个方法别轻易使用,因为创建了可能不一定展示,还需要控制权限等等
  Target.createFixedColumn = function createFix(columnType) {
    const column = new Target({ fieldType: columnType });
    return column;
  };
}
