<template>
  <el-dialog
    title="我的文件夹"
    :visible.sync="visible"
    append-to-body
    destroy-on-close
    width="900px">
    <file-list :file-list="fileList" @change="handleCheckChanged" :loading="loading" :hasDelete="false" show-no-data class="my-folder">
      <slot>
        <en-button key="wdwjj" @click="handleOKButton" class="ok-btn" :disabled="!selectFiles || !selectFiles.length">确定</en-button>
      </slot>
    </file-list>
  </el-dialog>
</template>

<script>
import { request } from "en-js";
import { foldersService } from "@/api/folders";
import FileList from "../views/fileList";
import Index from "../data/utils";

export default {
  name: "MyFolder",
  components: { FileList },
  props: {
    defaultSelects: Array// 默认选中
  },
  data() {
    return {
      loading: false,
      visible: false,
      fileList: undefined,
      selectFiles: undefined
    };
  },
  created() {
  },
  methods: {
    open() {
      if (!this.fileList) {
        this.requestData();
      }
      this.visible = true;
    },
    @request(true, "loading")
    async requestData() {
      const res = await foldersService.queryMyFolderFiles({ pageNo: 1, pageSize: 1000 });
      this.fileList = (res?.records || []).filter((itm) => itm.sourceType !== 2);
      Index.processInvalidData(this.fileList);
      this.fileList.splice(this.fileList.length);
    },
    handleCheckChanged(selectFiles) {
      this.selectFiles = selectFiles;
    },
    handleOKButton() {
      this.$emit("change", this.selectFiles);
      this.visible = false;
    }
  }
};
</script>

<style scoped lang="scss">
  .my-folder {
    height: 500px;
    .ok-btn {
      width: 70px;
    }
  }
</style>
