var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "attachment-box",
        _vm.border || !_vm.isReadOnly ? "border" : "",
        _vm.fixedHeight ? "fixedHeight" : "",
      ],
    },
    [
      _vm.hasOnlyLocalPermisson && _vm.uploadVisible
        ? _c("en-upload", {
            staticClass: "add-btn-box",
            attrs: {
              "on-change": _vm.handleUploadChange,
              "on-progress": _vm.handleProgressChange,
              "on-exceed": _vm.handleExceed,
              "file-list": _vm.fileList,
              limitNum: _vm.limitNum,
              limitType: _vm.mdConfig.limitType || 1,
            },
            on: { error: _vm.handleError },
          })
        : _vm._e(),
      _vm.isUploadStatus ? _c("span", { staticClass: "logind-box" }) : _vm._e(),
      _vm.hasMorePermisson
        ? _c(
            "div",
            { staticClass: "add-btn-box" },
            [
              _c(
                "en-button",
                {
                  attrs: {
                    iconColor: "#5694df",
                    text: "",
                    type: "blue",
                    icon:
                      _vm.mdConfig.limitType === 2
                        ? "iconjintupianleixing"
                        : "fujianhetupian-kongjian",
                    "icon-size": "18",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$refs.mainDialog.open()
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.mdConfig.limitType === 2 ? "上传图片" : "上传附件"
                    )
                  ),
                ]
              ),
              _c("main-dialog", {
                ref: "mainDialog",
                on: { change: _vm.handleAddNewFiles },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.mdConfig.enclosureShowStyle === 5
        ? _c("div", { staticClass: "icon-box" }, [
            _vm.allImageList.length
              ? _c(
                  "div",
                  { staticStyle: { display: "flex" } },
                  [
                    _vm._l(_vm.allImageList, function (item, index) {
                      return _c("div", { key: index }, [
                        item.type === "000" && index < 2
                          ? _c(
                              "div",
                              {
                                staticClass: "annex",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.$refs.preview.open()
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "annex-icon" },
                                  [
                                    _c("en-icon", {
                                      attrs: {
                                        name: "icontupian",
                                        size: "small",
                                        color: "#FFA32B",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "annex-name" }, [
                                  _vm._v(_vm._s(item.name)),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ])
                    }),
                    _vm.allImageList.length > 2
                      ? _c("div", { staticClass: "file-other" }, [
                          _vm._v("+" + _vm._s(_vm.allImageList.length - 2)),
                        ])
                      : _vm._e(),
                  ],
                  2
                )
              : _vm._e(),
            _vm.allFileList.length
              ? _c(
                  "div",
                  { staticStyle: { display: "flex" } },
                  [
                    _vm._l(_vm.allFileList, function (item, index) {
                      return _c("div", { key: index }, [
                        item.type === "001" && index < 2
                          ? _c(
                              "div",
                              {
                                staticClass: "annex",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.$refs.preview.open()
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "annex-icon" },
                                  [
                                    _c("en-icon", {
                                      staticClass: "icon",
                                      staticStyle: { "margin-left": "5px" },
                                      attrs: {
                                        name: _vm.fileIcon[item.extension].icon,
                                        color:
                                          _vm.fileIcon[item.extension].color,
                                        size: "small",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "annex-name" }, [
                                  _vm._v(_vm._s(item.name)),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ])
                    }),
                    _vm.allFileList.length > 2
                      ? _c("div", { staticClass: "file-other" }, [
                          _vm._v("+" + _vm._s(_vm.allFileList.length - 2)),
                        ])
                      : _vm._e(),
                  ],
                  2
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm.mdConfig.enclosureShowStyle === 2
        ? _c(
            "div",
            { staticClass: "icon-box" },
            [
              _vm.allImageList.length && _vm.mdConfig.enclosureShowStyle === 2
                ? _c(
                    "en-button",
                    {
                      attrs: {
                        icon: "gaoqingtupian",
                        "icon-size": "18",
                        text: "",
                      },
                      nativeOn: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.$refs.preview.open()
                        },
                      },
                    },
                    [_vm._v("(" + _vm._s(_vm.allImageList.length) + ")")]
                  )
                : _vm._e(),
              _vm.allFileList.length && _vm.mdConfig.enclosureShowStyle === 2
                ? _c(
                    "en-button",
                    {
                      attrs: {
                        icon: "fujianhetupian-kongjian",
                        "icon-size": "18",
                        text: "",
                      },
                      nativeOn: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.$refs.preview.open()
                        },
                      },
                    },
                    [_vm._v("(" + _vm._s(_vm.allFileList.length) + ")")]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm.mdConfig.limitType === 3 &&
          [1, 3, 4].includes(_vm.mdConfig.enclosureShowStyle)
        ? _c(
            "div",
            { staticClass: "files" },
            [
              _vm._l(_vm.showList, function (file, index) {
                return _c("image-file", {
                  key: file.id || file.uid || index,
                  ref: "imgFileMod",
                  refInFor: true,
                  staticClass: "file",
                  attrs: {
                    data: { ...file },
                    type:
                      file.type === "000" || file.dataType === "000"
                        ? "image"
                        : "file",
                    size: _vm.newFileSize,
                    direction: "h",
                    "delete-method": _vm.handleDelete,
                  },
                  nativeOn: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleClickImage(index)
                    },
                  },
                })
              }),
              _vm.moreList.length
                ? _c("image-file", {
                    attrs: {
                      type: "more",
                      size: _vm.newFileSize,
                      moreNum: _vm.moreList.length,
                      direction: "h",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "more",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                { staticClass: "more-list" },
                                _vm._l(_vm.moreList, function (file, index) {
                                  return _c("image-file", {
                                    key: file.id || file.uid || index,
                                    staticClass: "file",
                                    attrs: {
                                      data: { ...file },
                                      direction: "v",
                                      "delete-method": _vm.handleDelete,
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.handleClickImage(
                                          index + _vm.showList.length
                                        )
                                      },
                                    },
                                  })
                                }),
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      3509339385
                    ),
                  })
                : _vm._e(),
            ],
            2
          )
        : _c("div", { staticClass: "icon-box" }, [
            _vm.allImageList.length
              ? _c(
                  "div",
                  { staticStyle: { display: "flex" } },
                  [
                    _vm._l(_vm.allImageList, function (item, index) {
                      return _c("div", { key: index }, [
                        item.type === "000" && index < 2 && !_vm.isTableCell
                          ? _c(
                              "div",
                              {
                                staticClass: "annex",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.$refs.preview.open()
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "annex-icon" },
                                  [
                                    _c("en-icon", {
                                      staticStyle: { "margin-left": "5px" },
                                      attrs: {
                                        name: "icontupian",
                                        size: "small",
                                        color: "#FFA32B",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "annex-name" }, [
                                  _vm._v(_vm._s(item.name)),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        item.type === "000" && index < 1 && _vm.isTableCell
                          ? _c(
                              "div",
                              {
                                staticClass: "annex",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.$refs.preview.open()
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "annex-icon" },
                                  [
                                    _c("en-icon", {
                                      staticStyle: { "margin-left": "5px" },
                                      attrs: {
                                        name: "icontupian",
                                        size: "small",
                                        color: "#FFA32B",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "annex-name" }, [
                                  _vm._v(_vm._s(item.name)),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ])
                    }),
                    _vm.allImageList.length > 2 && !_vm.isTableCell
                      ? _c("div", { staticClass: "file-other" }, [
                          _vm._v("+" + _vm._s(_vm.allImageList.length - 2)),
                        ])
                      : _vm._e(),
                    _vm.allImageList.length > 1 && _vm.isTableCell
                      ? _c("div", { staticClass: "file-other" }, [
                          _vm._v("+" + _vm._s(_vm.allImageList.length - 1)),
                        ])
                      : _vm._e(),
                  ],
                  2
                )
              : _vm._e(),
            _vm.allFileList.length
              ? _c(
                  "div",
                  { staticStyle: { display: "flex" } },
                  [
                    _vm._l(_vm.allFileList, function (item, index) {
                      return _c("div", { key: index }, [
                        item.type === "001" && index < 2 && !_vm.isTableCell
                          ? _c(
                              "div",
                              {
                                staticClass: "annex",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.$refs.preview.open()
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "annex-icon" },
                                  [
                                    _c("en-icon", {
                                      staticClass: "icon",
                                      staticStyle: { "margin-left": "5px" },
                                      attrs: {
                                        name: _vm.fileIcon[item.extension].icon,
                                        color:
                                          _vm.fileIcon[item.extension].color,
                                        size: "small",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "annex-name" }, [
                                  _vm._v(_vm._s(item.name)),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        item.type === "001" && index < 1 && _vm.isTableCell
                          ? _c(
                              "div",
                              {
                                staticClass: "annex",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.$refs.preview.open()
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "annex-icon" },
                                  [
                                    _c("en-icon", {
                                      staticClass: "icon",
                                      staticStyle: { "margin-left": "5px" },
                                      attrs: {
                                        name: _vm.fileIcon[item.extension].icon,
                                        color:
                                          _vm.fileIcon[item.extension].color,
                                        size: "small",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "annex-name" }, [
                                  _vm._v(_vm._s(item.name)),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ])
                    }),
                    _vm.allFileList.length > 2 && !_vm.isTableCell
                      ? _c("div", { staticClass: "file-other" }, [
                          _vm._v("+" + _vm._s(_vm.allFileList.length - 2)),
                        ])
                      : _vm._e(),
                    _vm.allFileList.length > 1 && _vm.isTableCell
                      ? _c("div", { staticClass: "file-other" }, [
                          _vm._v("+" + _vm._s(_vm.allFileList.length - 1)),
                        ])
                      : _vm._e(),
                  ],
                  2
                )
              : _vm._e(),
          ]),
      _c("preview-dialog", {
        ref: "preview",
        attrs: {
          "file-list": _vm.fileList,
          title: _vm.mdConfig ? _vm.mdConfig.name : undefined,
          mode: _vm.mode,
          mdConfig: _vm.mdConfig,
        },
        on: {
          "update:fileList": function ($event) {
            _vm.fileList = $event
          },
          "update:file-list": function ($event) {
            _vm.fileList = $event
          },
          handleDelete: _vm.handleDelete,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }