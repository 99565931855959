/*
 * @Author: 梁平贤
 * @Date: 2020-06-16 14:44:40
 * @LastEditors: linshanfeng
 * @LastEditTime: 2024-01-31 18:04:43
 * @Description: 解析处理服务器数据
 */

// 处理服务器返回的行信息
import { BusinessFieldType, BusinessType } from "../BusinessCommonHeader.js";
/* eslint import/no-cycle: [2, { maxDepth: 1 }] */
import * as Column from "../columns/index";
import BusinessSection from "../section/BusinessSection";

// 处理数据行
const columnClassCreater = (element, parent) => {
  let field;
  const fieldType = parseInt(element.fieldType, 10);
  switch (fieldType) {
    case BusinessFieldType.inputCommon:
    case BusinessFieldType.multiText:
    case BusinessFieldType.inputNumber:
    case BusinessFieldType.inputAmount:
      field = new Column.TextColumn(element);

      break;
    case BusinessFieldType.detailParent:
      field = new Column.DetailColumn(element);
      break;
    case BusinessFieldType.subSet:
      field = new Column.SubsetColumn(element);
      break;
    case BusinessFieldType.select:
    case BusinessFieldType.multiSelect:
      field = new Column.SelectColumn(element, parent);
      break;
    case BusinessFieldType.date:
      field = new Column.DateColumn(element);
      break;
    case BusinessFieldType.pureText:
      field = new Column.InstructionColumn(element);
      break;
    case BusinessFieldType.subItem:
      field = new Column.SubItemColumn(element);
      break;
    case BusinessFieldType.attachment:
      field = new Column.AttachmentColumn(element);
      break;
    case BusinessFieldType.associatedData:
      field = new Column.AssociatedDataColumn(element);
      break;
    case BusinessFieldType.address:
      field = new Column.AddressColumn(element);
      break;
    case BusinessFieldType.superior:
      field = new Column.SuperiorColumn(element);
      break;
    case BusinessFieldType.cashObject:
      field = new Column.CashObjectColumn(element);
      break;
    case BusinessFieldType.shouFu:
      field = new Column.ShouFuColumn(element);
      break;
    case BusinessFieldType.line:
      field = new Column.SeperatorLineColumn(element);
      break;
    case BusinessFieldType.timeAxis:
      field = new Column.TimeAxisColumn(element);
      break;
    case BusinessFieldType.newProgress:
      field = new Column.ProgressColumn(element);
      break;
    case BusinessFieldType.checkBox:
      field = new Column.MarkCompleteColumn(element);
      break;
    case BusinessFieldType.sign:
      field = new Column.SignColumn(element);
      break;
    case BusinessFieldType.textComposition:
      field = new Column.TextComposeColumn(element);
      break;
    case BusinessFieldType.ocr:
      field = new Column.OCRColumn(element);
      break;
    case BusinessFieldType.tabControl:
      field = new Column.TabControl(element);
      break;
    case BusinessFieldType.multiTab:
      field = new Column.MultiTab(element);
      break;
    case BusinessFieldType.mileStone:
      field = new Column.MileStone(element);
      break;
    case BusinessFieldType.seeFlow:
      field = new Column.SeeFlow(element);
      break;
    case BusinessFieldType.imageAttachment:
      field = new Column.ImageColumn(element);
      break;
    default:
      field = new Column.SelectColumn(element);
      break;
  }
  return field;
};

/**
 *
 * @param fieldsDatas json字段数组
 * @param parent 父控件
 * @returns {*}
 */
const parsingColumns = (fieldsDatas, parent) => {
  const fields = fieldsDatas?.map((columnData) => {
    const column = columnClassCreater(columnData, parent);
    column.parentNode = parent;
    return column;
  });
  return fields;
};

/**
 * 处理区域的方法.. 业务建模单区域 数据建模多区域
 * @param fieldsData 待解析的字段数据
 * @param businessType 类型
 * @param rootData 业务建模数据
 * @returns {Array} 区域
 */
const parsingSections = (fieldsData, rootData) => {
  const sections = [];

  if (rootData.businessType !== BusinessType.basic && rootData.businessType !== BusinessType.pureBasic) {
    // 除了基础数据都没有区域 人为添加一层
    fieldsData = [{ fields: fieldsData }];
  }

  // 循环区域
  fieldsData.forEach((element) => {
    const section = new BusinessSection(element, rootData.businessType);
    const parsingFields = parsingColumns(element.fields, section);
    section.fields = [...parsingFields];
    section.parentNode = rootData;
    sections.push(section);
  });
  return sections;
};

/**
 * 根据布局信息,把数据处理好,引用类型(控件内存地址完全一样,修改会进行同步)
 * @param datas
 * @param sections
 * @param businessType
 */
const parsingLayout = (datas, sections, businessType) => {
  console.log("业务建模开始布局");
  const data = datas.mdHtml; // BusinessType.basic ? datas.bdHtml :
  if (!data) {
    // 布局数据不存在的时候直接当成单行处理
    sections.forEach((section) => {
      section.areas = section.fields.map((val) => [val]);
    });
    return;
  }
  const layoutData = JSON.parse(data);
  let dataSections = layoutData.model;
  if (businessType !== BusinessType.basic && businessType !== BusinessType.pureBasic) {
    // 除了基础数据都没有区域 人为添加一层
    dataSections = [{ areas: dataSections }];
  }
  // 循环区域
  dataSections.forEach((dataSection, index) => {
    // 真实数据section
    const section = sections[index];
    // 区域循环获取字段
    // let currentIndex = 0;
    dataSection.areas.forEach((element) => {
      const oneLineData = [];
      if (element.fields) {
        element.fields.forEach((itm) => {
          const itmFieldType = Number(itm.fieldType);
          /**
           *  如果是选项卡控件的时候，本身并不是一个字段，也没有特定样式
           *  需要把每个tab页中的字段 按照布局 抽出来
           *  根据控制tab单选字段的值来确定隐藏显示哪些字段
           * */
          if (itmFieldType === BusinessFieldType.tabControl) {
            itm.items?.forEach((tab) => {
              tab.refField?.forEach((fdg) => {
                const fdgArr = [];
                fdg.forEach((fd) => {
                  const sd = section.fields.filter((sfd) => sfd.mainTabUuid === fd.mainTabUuid);
                  sd[0].tabId = tab.dataId; // 对应tab页的id
                  sd[0].tabName = tab.dataName;
                  fdgArr.push(sd[0]);
                });

                if (fdgArr.length) {
                  section.areas.push(fdgArr);
                }
              });
            });
          } else if (itmFieldType === BusinessFieldType.area) {
            // 区域比较特殊,在fieldAttr里面没有字段信息
            const area = Column.Area.creatAreaColumnWithConfig(itm, section);
            oneLineData.push(area);
            // 找到区域里面第一个字段的位置,在它之前插入一个自定义的区域字段
            // itm.areas.forEach((it) => {
            //   it.sub.forEach((i) => {
            //     const field = section.fields.filter((fd) => fd.field === i.field)[0];
            //     field && oneLineData.push(field);
            //   });
            // });
          } else if (itmFieldType === BusinessFieldType.multiTab) {
            // 页签 页签里面又有一套布局的
            const multiTabColumn = section.fields.filter((fd) => fd.field === itm.field || Number(fd.fieldType) === BusinessFieldType.multiTab)[0];
            const colums = [];
            // 页签数据 处理
            itm.showContent.forEach((i) => {
              const oneTabData = i.fieldList.map((val) => val.fields.map((col) => multiTabColumn.findMainAreaFieldByUuid(col.uuid)));
              colums.push({
                  id: i.id,
                  values: oneTabData,
                  // 是否显示
                  show: true,
                  name: i.name
                });
            });
            multiTabColumn.showColumns = colums;
            // 如果页签的子项都不显示 页签本体也不显示
            multiTabColumn.showColumnsFlg = true;
            oneLineData.push(multiTabColumn);
          } else if (itmFieldType === BusinessFieldType.outsideArea) { // 外勤套件
            itm.item?.forEach((tab) => {
              const field = section.fields.filter((fd) => (fd.field === tab.field || fd.uuid === tab.uuid))[0];
              field && oneLineData.push(field);
            });
          } else {
            // 不能通过 currentIndex 来到fieldAttr中取  不准确
            const field = section.fields.filter((fd) => (fd.field === itm.field || fd.uuid === itm.uuid))[0];
            field && oneLineData.push(field);
          }
        });
      }
      section.areas.push(oneLineData);
    });
  });
};

export {
  parsingSections, parsingColumns, parsingLayout, columnClassCreater
};
