/*
 * @Description: 日期控件
 * @Author: 梁平贤
 * @LastEditors: wuqi
 * @Date: 2019-07-09 18:18:50
 * @LastEditTime: 2022-01-12 17:32:08
 */
import dayjs from "dayjs";
import { roundNumWithDeci } from "en-js";
import { dayJsTimeFormat, endOfTimeFormat } from "@/tools/date.js";
import Store from "@/store";
import { attendanceModel } from "@/api/businessModel";
import BaseColumn from "../base/BaseColumn";
import { AssignmentOptions } from "../../BusinessCommonHeader.js";

export default class DateColumn extends BaseColumn {
  constructor(column) {
    super(column);
    this.showValue = "";
    this.columnValue = "";
  }

  showFlag;

  // 是否能提交数据,校验
  get checkCouldSubmit() {
    // 没有填写不校验其它条件,但是要校验必填
    if (!this.columnValue && this.required) {
      return new Error(`${this.name}不能为空`);
    }
    // 校验且或输入限制
    if (this.columnValue) {
      return this.inputLimitCheck();
    }
    return true;
  }

  // 提交参数
  get paramsForSubmit() {
    // 需要格式化 提交时间  YYYY-MM-DD hh:mm:ss
    const value = this.columnValue;
    let res = "";
    if (value) {
      if (value.indexOf(",") > -1) {
        const vals = value.split(",");
        if (vals[0]) {
          vals[0] = dayjs(vals[0]).format(dayJsTimeFormat[10]);
        }
        if (vals[1]) { // 结束时间补值处理
          vals[1] = dayjs(vals[1]).endOf(endOfTimeFormat[this.timeFormat]).format(dayJsTimeFormat[10]);
        }
        res = vals.join(",");
      } else {
        res = dayjs(value).format(dayJsTimeFormat[10]);
      }
    }

    return {
      [this.field]: res
    };
  }

  /**
   * @description: 获取el-date-picker 的类型
   */
  getDatePickerType() {
    let { dateRange, timeFormat } = this;
    dateRange = String(dateRange);
    timeFormat = Number(timeFormat);
    if ([1, 2, 3].includes(timeFormat)) {
      if (dateRange === "0") {
        return "monthrange";
      }
      return "month";
    } if ([4, 6, 8].includes(timeFormat)) {
      if (dateRange === "0") {
        return "daterange";
      }
      return "date";
    } if ([5, 7, 9, 11].includes(timeFormat)) {
      if (dateRange === "0") {
        return "datetimerange";
      }
      return "datetime";
    } if (timeFormat === 12) {
      if (dateRange === "0") {
        return "yearrange";
      }
      return "year";
    }

    if (dateRange === "0") { // 区间
      return "datetimerange";
    }
    return "datetime";
  }

  /**
   * @description: 获取el-date-picker 的format
   */
  getDatePickerFormat() {
    const timeFormatMap = {
      1: "yyyy-MM",
      2: "yyyy/MM",
      3: "yyyyMM",
      4: "yyyy/MM/dd",
      5: "yyyy/MM/dd HH:mm",
      6: "yyyyMMdd",
      7: "yyyyMMdd HH:mm",
      8: "yyyy-MM-dd",
      9: "yyyy-MM-dd HH:mm",
      10: "yyyy-MM-dd HH:mm:ss",
      11: "yyyy-MM-dd HH",
      12: "yyyy"
    };
    return timeFormatMap[this.timeFormat] || "yyyy-MM-dd";
  }

  /**
   * @description 处理时间控件的默认值设置
   * @param value default属性
   * @param opts  值更新类型
  */
  updateValueWithOptions(value, opts) {
    console.log(value);
    const { dateRange, timeFormat } = this;
    const ft = dayJsTimeFormat[timeFormat];
    // eslint-disable-next-line no-bitwise
    if (opts & AssignmentOptions.default) { // 新增 且 有默认数据
      if (dateRange === 0) { // 区间
        if (value.includes("1#")) { // 当前时间
          value = [dayjs(new Date()).format(ft), dayjs(new Date()).format(ft)];
        } else if (value.includes("2#")) { // 自定义
          const cval = value.split("#")[1];
          value = [cval.split(",")[0], cval.split(",")[1]];
        }
      } else { // 非区间
        // eslint-disable-next-line no-lonely-if
        if (value.includes("1#")) { // 当前时间
          value = dayjs(new Date()).format(ft);
        } else if (value.includes("2#")) { // 自定义
          value = value.split("#")[1];
        }
      }
    }
    if (value instanceof Array) {
      const cvalue = value.join(",");
      // 更新属性值
      this.columnValue = cvalue;
      this.showValue = value;
    } else if (value?.includes(",")) {
      this.columnValue = value;
      this.showValue = value.split(",");
    } else {
      this.columnValue = value;
      this.showValue = value;
    }
    // eslint-disable-next-line no-bitwise
    if (opts & (AssignmentOptions.change | AssignmentOptions.manual)) {
      this.updateConnectedField();
    }
    super.updateValueWithOptions(value, opts);
  }

  /**
   * @description 主要是用来处理考勤(请假,销假,加班)时长
  */
  async updateConnectedField() {
    const { field, columnValue } = this;
    // 肯定为区间
    const carr = columnValue?.split(",") || [];
    const st = carr[0];
    const et = carr[1];
    if (st && et) {
      // 补齐
      const _st = dayjs(st).format("YYYY-MM-DD HH:mm:ss");
      const _et = dayjs(et).format("YYYY-MM-DD HH:mm:ss");
      if (field === "leaveTime") { // 请假
        const lColumn = this.findDetailSameAreaField("leaveType");
        const lt = lColumn.showValue;
        const ltId = Array.isArray(lt) ? lt[0]?.id : lt?.id;
        if (lt && ltId) {
          const pa = {
            startTime: _st,
            endTime: _et,
            type: 1,
            userId: Store.getters.userInfo.userId,
            templateId: lColumn.templateId,
            version: lColumn.version,
            leaveType: ltId
          };
          await this.calculateTime(pa, ["leaveLongTime", "leaveDays"]);
        }
      } else if (field === "sellTime") { // 销假
        await this.calculateTime({
          startTime: _st,
          endTime: _et,
          type: 1,
          userId: Store.getters.userInfo.userId,
          templateId: this.templateId,
          version: this.version
        }, "sellLongTime");
      } else if (field === "overtimeTime") { // 加班
        const lColumn = this.findDetailSameAreaField("overtimeType");

        const lt = lColumn.showValue;
        console.log(lColumn);
        const ltId = Array.isArray(lt) ? lt[0]?.id : lt?.id;
        if (lt && ltId) {
          const pa = {
            startTime: _st,
            endTime: _et,
            type: 2,
            userId: Store.getters.userInfo.userId,
            templateId: lColumn.templateId,
            version: lColumn.version,
            overtimeType: ltId
          };
          await this.calculateTime(pa, ["overtimeLongTime", "overtimeDays"]);
        }
      }
    }
  }

  /**
   * @description 计算考勤(请假,销假,加班)时长
   * @param param 查询参数
   * @param key  被改变field
  */
  async calculateTime(param, keys) {
    const re = await attendanceModel.calculateTime(param);
    const { days, hours } = re;
    if (!Array.isArray(keys)) {
      keys = [keys];
    }
    keys.forEach((key) => {
      const tColumn = this.findDetailSameAreaField(key);
      const { reserverDecimal } = tColumn;
      let roundVal;
      if (["leaveLongTime", "sellLongTime", "overtimeLongTime"].includes(key)) {
        roundVal = hours;
      } else {
        roundVal = days;
      }
      const val = roundNumWithDeci(roundVal, reserverDecimal);
      tColumn.updateValueWithOptions(val, AssignmentOptions.change);
    });
  }

  /**
   * @description 时间区间 获取统计日期
  */
  get getStaticDate() {
    const { columnValue, timeFormat } = this;
    if (columnValue) {
      const carr = columnValue.split(",");
      if (carr[0] && carr[1]) {
        const _hour = new Date(this.formatDate(carr[1], timeFormat)) - new Date(this.formatDate(carr[0], timeFormat));
        return this.formatDuring(_hour, timeFormat);
      }
      return "";
    }
    return "";
  }

  formatDate(str, format) {
    // str 2017-08-09 10:55
    if (format === 4 || format === 5) {
      str = str.replace(/-/g, "/");
    } else if (format === 6 || format === 7) {
      str = str.replace(/-/g, " ");
    } else if (format === 1 || format === 2 || format === 3) {
      str = str.substring(0, 7);
    } else if (format === 12) {
      str = str.substring(0, 4);
    } else if (format === 10) {
      const ss = (`00${new Date().getSeconds()}`);
      str = `${str}:${ss.substr(ss.length - 2)}`;
    }
    return str;
  }

  formatDuring(mss, type) {
    const days = parseInt(mss / (1000 * 60 * 60 * 24), 0);
    const hours = parseInt((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60), 0);
    const minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60), 0);
    const seconds = (mss % (1000 * 60)) / 1000;
    if (type === 8) {
      return `共${days}天`;
    }
    let res = `${days}天${hours}时${minutes}分`;
    if (type === 10) {
      res = `${res + seconds}秒`;
    }
    return res;
  }
}
