/*
 * @Description: 输入校验: 包含且或校验(数值/金额/日期) 和 文本的校验
 * @Author: 梁平贤
 * @Date: 2020/8/6 15:15
 */

import {
  hasSpecial, isEmail, isIdCard, isInvoiceCode, isInvoiceNo, isLicense, isMobile, isUrl
} from "en-js";
import { enEqual } from "@/tools/compare";
// import dayjs from "dayjs";
import { BusinessFieldType, ConditionOperatorType, nameOfConditionOperatorType } from "../../../BusinessCommonHeader";

export default function inputLimitCheck(target) {
  // 校验总入喉
  target.prototype.inputLimitCheck = function inputLimitCheck() {
    switch (this.fieldType) {
      case BusinessFieldType.inputCommon:
        // 文本校验
        return this.textFormatCheck();
      case BusinessFieldType.inputAmount:
      case BusinessFieldType.inputNumber:
      // case BusinessFieldType.date: {
      {
        // 数值 金额 日期 校验且或
        const result = this.inputLimitCheckWithAndOr();
        if (!result) {
          // 张总: (2020-08-06. 注释为证)
          // 当字段不满足输入限制时
          // 如果配置了自定义提示,则读取自定义提示,否则统一提示'字段'不满足输入限制.
          const errorInfo = this.inputLimitHints();
          return new Error(errorInfo);
        }
        return true;
      }
      default:
        return true;
    }
  };

  // 文本校验
  target.prototype.textFormatCheck = function textFormatCheck() {
    /**
     1.身份证 正则校验
     2.电话号码 区号+7/8位数字或无区号+7/8位数字
     3.手机号码 正则校验
     4.邮箱 正则校验
     5.三证合一 营业执照、税号、社会信用代码
     6 限制特殊字符 限制特殊字符：[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]。
     7 发票号码
     8 发票代码
     9 url
     10 长度
     */
    // 长度校验方法
    const lengthSatisfy = function lengthSatisfy(val) {
      if (!val || val.length) {
        return true;
      }
      return val.length <= parseInt(this.limitNum, 10);
    };
    //
    const isNumber = (val) => typeof val === "number" && !isNaN(val);
    const isisTelephone = (v) => v.length <= 20 && isNumber(Number(v));
    const checkMethod = {
      1: isIdCard,
      2: isisTelephone,
      3: isMobile,
      4: isEmail,
      5: isLicense,
      6: hasSpecial,
      7: isInvoiceNo,
      8: isInvoiceCode,
      9: isUrl,
      10: lengthSatisfy
    };
    const formarCheck = parseInt(this.formatCheck, 10);
    if (!formarCheck) {
      return true;
    }
    const checkOk = checkMethod[formarCheck](this.columnValue || "");
    let errorInfo = "";
    if (formarCheck === 7) {
      errorInfo = this.inputHints || "请输入正确的发票号码格式";
    } else if (formarCheck === 8) {
      errorInfo = this.inputHints || "请输入正确的发票代码格式";
    } else {
      errorInfo = this.inputHints || `请输入正确的${this.name}`;
    }
    if (!checkOk) {
      return new Error(errorInfo);
    }
    return true;
  };
  //  数值 金额 时间的校验(且或)
  target.prototype.inputLimitCheckWithAndOr = function inputLimitCheckWithAndOr() {
    if (!this.inputConfig || this.inputConfig.length === 0) {
      return true;
    }
    let result = false;
    // 循环比较且或
    for (let i = 0; i < this.inputConfig.length; i++) {
      const orCondition = this.inputConfig[i];
      let orConditionResult = true;
      for (let j = 0; j < orCondition.items.length; j++) {
        const andCondition = orCondition.items[j];
        orConditionResult = orConditionResult && this.executeCondition(andCondition);
        // 且中有一个是错误的,直接判断另外的且
        if (!orConditionResult) {
          break;
        }
      }
      // 或中有一个正确的, 直接返回
      result = result || orConditionResult;
      if (result) {
        break;
      }
    }
    return result;
  };

  // 单个条件判断逻辑实现
  target.prototype.executeCondition = function executeCondition(condition) {
    const compare = (val1, val2) => {
      let firstValue = 0;
      let secondValue = 0;
      // 这里没考虑区间的情况  后台会校验输入限制
      // if (this.fieldType === BusinessFieldType.date) {
      //   firstValue = dayjs(val1)
      //     .unix();
      //   secondValue = dayjs(val2)
      //     .unix();
      // } else {
        firstValue = Number(val1);
        secondValue = Number(val2);
      // }
      let result = true;
      switch (parseInt(condition.perator, 10)) {
        case ConditionOperatorType.equal:
          result = (firstValue === secondValue);
          break;
        case ConditionOperatorType.notEqual:
          result = (firstValue !== secondValue);
          break;
        case ConditionOperatorType.greater:
          result = (firstValue > secondValue);
          break;
        case ConditionOperatorType.greaterOrEqual:
          result = (firstValue >= secondValue);
          break;
        case ConditionOperatorType.lessOrEqual:
          result = (firstValue <= secondValue);
          break;
        case ConditionOperatorType.less:
          result = (firstValue < secondValue);
          break;

        default:
          break;
      }
      // 表达式的结果
      return result;
    };

    if (enEqual(condition.selectType, 1)) {
      // 自定义
      return compare(this.columnValue, condition.value);
    }
    // 有的配置没有区域信息,所以只能都循环一次来获取字段
    const destColumn = this.findMainAreaField(condition.destField)
      || this.findDetailSameAreaField(condition.destField)
      || this.findSubsetSameAreaField(condition.destField);

    // 如果目标字段值为空, 则条件都返回false, 这是张总说的!!
    if (!destColumn || !destColumn.columnValue) {
      return false;
    }
    // 字段比较
    return compare(this.columnValue, destColumn.columnValue);
  };
  target.prototype.triggerInputLimitIfNeeded = function triggerInputLimit() {
    // flag标记能进才循环,初始化数据的时候设置了标记. 减少循环次数
    if (this.rootNode.shouldTriggerInputLimit) {
      // 可能会受影响的字段
      const shouldAffectsColumns = [...(this.mainAreaFields || []),
        ...(this.detailSameAreaFields || []),
        ...(this.subsetSameAreaFields || [])];
      shouldAffectsColumns.forEach((column) => {
        // 存在输入限制 并且字段有值
        if (column.field !== this.field && column.inputConfig && column.inputConfig.length > 0 && column.columnValue?.length) {
          // 新赋值, 表示它需要触发一下, 没什么其他意思
          column.triggerInputLimitWatch = Math.random();
        }
      });
    }
  };

  //  数值 金额 时间的校验的提示语拼接
  target.prototype.inputLimitHints = function inputLimitHints() {
    if (this.inputHints && this.inputHints.length) {
      return this.inputHints;
    }
    const orConditionErrorInfos = [];
    // 循环且或
    for (let i = 0; i < this.inputConfig.length; i++) {
      const orCondition = this.inputConfig[i];
      const andConditionErrorInfos = [];
      for (let j = 0; j < orCondition.items.length; j++) {
        const andCondition = orCondition.items[j];
        let value = "";
        if (enEqual(andCondition.selectType, 1)) {
          value = andCondition.value;
        } else {
          // 有的配置没有区域信息,所以只能都循环一次来获取字段
          const destColumn = this.findMainAreaField(andCondition.destField)
            || this.findDetailSameAreaField(andCondition.destField)
            || this.findSubsetSameAreaField(andCondition.destField);
          // 获取目标字段的名字
          value = destColumn?.name || "未知字段";
        }
        // 大于字段  大于10
        andConditionErrorInfos.push(`${nameOfConditionOperatorType(parseInt(andCondition.perator, 10))}${value}`);
      }
      orConditionErrorInfos.push(andConditionErrorInfos.join("且"));
    }
    return `${this.name}必须${orConditionErrorInfos.join("或")}`;
  };
}
